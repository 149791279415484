import {
  Agreement,
  Booking,
  BookingTabType,
  Channel,
  Corporation,
  Conversation,
  Expense,
  GroupBooking,
  Installment,
  InstallmentAllocation,
  Invoice,
  InvoiceLine,
  Property,
  Tag,
  BookingGuest,
  Guest,
  CheckInOutConfig,
  ConfirmationSetting,
  ConfirmationSettingCategory,
  CategorySetting,
  BookingRatePlan,
  SignableEnvelop,
  Addon,
  Discount,
  Refund,
  Payment,
  CheckInBackupType,
  OrderLineAddon,
  BookingTaxRate,
  TaxComponent,
  BedConfig,
  ProceedPayment,
  BookingPayment,
  AdditionalTax,
  CheckInReadyStatus,
  BookingAttachment,
  SecurityDepositPayment,
  CICOTimeAddon,
} from '../@types/booking';
import { InternalNote } from 'src/@types/common';
import differenceInDays from 'date-fns/differenceInDays';
import { PotentialAutomatedMessage } from '../@types/automated-message';

const CURRENCY_SYMBOLS = {
  RON: 'lei',
  COP: '$',
  PHP: '₱',
  AED: 'د.إ',
  AUD: 'A$',
  BRL: 'R$',
  CAD: 'C$',
  EGP: 'E£',
  EUR: '€',
  GBP: '£',
  IDR: 'Rp',
  MXN: '$',
  MYR: 'RM',
  PYG: '₲',
  SGD: 'S$',
  THB: '฿',
  USD: '$',
  VND: 'đ',
  ZAR: 'R',
};

const DIRECT_CHANNELS = {
  'Direct-Web': 'Direct (Web)',
  'Direct-Phone': 'Direct (Phone)',
  'Direct-Email': 'Direct (Email)',
  'Direct-Extension': 'Direct (Extension)',
  'Direct-Conversion': 'Direct (Conversion)',
  'Direct-Investor': 'Direct (Investor)',
  'Direct-Walk-in': 'Direct (Walk-in)',
  'Direct-Extension-Openrent': 'Direct (Extension-Openrent)',
  'Direct-Extension-Spareroom': 'Direct (Extension-Spareroom)',
};

export const BOOKING_STATUS_BOOKED = 'Booked';
export const BOOKING_STATUS_CANCELED = 'Cancelled';
export const BOOKING_STATUS_CHECKED_IN = 'Checked in';
export const BOOKING_STATUS_CHECKED_OUT = 'Checked out';
export const BOOKING_STATUS_NO_SHOW = 'No Show';
export const BOOKING_STATUS_SCHEDULED_FOR_CANCELLATION = 'Scheduled for Cancellation';

export const PAYMENT_METHOD_STRIPE = 'via_stripe';
export const PAYMENT_METHOD_MANUAL = 'manual_payment';

export enum XeroInvoiceLineAmountTypeEnum {
  EXCLUSIVE = 'Exclusive',
  INCLUSIVE = 'Inclusive',
  NO_TAX = 'NoTax',
}

export enum BillCreationModeEnum {
  MANUALLY = 'Manually',
  SYSTEMIC = 'Systemic',
}

export enum PaymentStatusEnum {
  PAID = 'Paid',
  OVERPAID = 'Over Paid',
  UNPAID = 'Unpaid',
  PARTIAL = 'Partial',
  CREDIT = 'Credit',
  VOID = 'Void',
  NOT_APPLICABLE = 'Not applicable',
}

export enum CheckInTypeEnum {
  MEET_AND_GREET = 'meet & greet',
  KEY_COLLECTION = 'key collection',
  KEYNEST = 'Keynest',
  CODE_ENTRY = 'code entry',
  KEYSAFE = 'key safe',
  CONCIERGE = 'concierge',
  MOBILE_APP = 'mobile app',
  SMART_LOCK = 'smart lock',
  KEY_PICKUP_FROM_OFFICE = 'key pickup (from office)',
}

export enum SignableEnvelopStatusEnum {
  CREATED = 'created',
  SENT = 'sent',
  CANCELED = 'canceled',
  SIGNED = 'signed',
  BOUNCED = 'bounced',
  REJECTED = 'rejected',
}

export enum DiscountTypeEnum {
  VOUCHER_CODE = 'Voucher Code',
  AUTO_PROMOTION = 'Auto Promotion',
  MANUAL_PROMOTION = 'Promotion',
  MANUAL_DISCOUNT = 'Discount',
}

export enum CostElementCategoryEnum {
  REFUND = 'Refund',
  SERVICE = 'Service',
  EXPENSE = 'Expenses',
  PAYMENT = 'Payment',
  ACCOMMODATION = 'Accommodation',
  MANAGEMENT_FEE = 'Management Fee',
  CAPTURE_DEPOSIT = 'Penalty',
}

export enum OrderLineReferenceEnum {
  Fee = 'Fee',
  Upsell = 'Upsell',
  Penalty = 'Penalty',
}

export enum BookingFinalizationStatusEnum {
  FINALIZED_STATUS = 'Finalised',
  NOT_FINALIZABLE_STATUS = 'Not Finalisable',
  AWAITING_FINALIZATION_STATUS = 'Awaiting Finalisation',
  MARKED_AS_FINALIZED_STATUS = 'Marked as Finalised',
}

export enum GuestInvoiceDocStatusEnum {
  POSTED_STATUS = 'Posted',
}

export enum PaymentCollectionTypeEnum {
  HOTEL_COLLECTION = 'Hotel Collect',
  CHANNEL_COLLECTION = 'Channel Collect',
}

export const BOOKING_STATUSES = [
  { type: 'booked', label: 'Booked', value: 0 },
  { type: 'cancelled', label: 'Cancelled', value: 1 },
  { type: 'scheduledForCancellation', label: 'Scheduled for Cancellation', value: 2 },
  { type: 'checkedin', label: 'Checked in', value: 3 },
  { type: 'checkedout', label: 'Checked out', value: 4 },
  { type: 'noShow', label: 'No Show', value: 5 },
];

export const BOOKING_PURPOSES = [
  { id: 1, label: 'Leisure' },
  { id: 2, label: 'Business' },
  { id: 3, label: 'Student' },
  { id: 4, label: 'Other' },
];

export const fCurrencySymbol = (currency: string) => {
  if (currency in CURRENCY_SYMBOLS) {
    return CURRENCY_SYMBOLS[currency];
  }
  return currency;
};

export const getChannelName = (channelName: string) => {
  if (channelName in DIRECT_CHANNELS) {
    return DIRECT_CHANNELS[channelName];
  }
  return channelName;
};

export const dateToIndex = (dateLeft: Date, dateRight: Date) =>
  differenceInDays(dateLeft, dateRight);

export const bookingStatusColor = (status) => {
  switch (status) {
    case BOOKING_STATUS_CHECKED_IN:
      return 'success';
    case BOOKING_STATUS_CANCELED:
      return 'warning';
    case BOOKING_STATUS_SCHEDULED_FOR_CANCELLATION:
      return 'error';
    case BOOKING_STATUS_CHECKED_OUT:
      return 'info';
    case BOOKING_STATUS_NO_SHOW:
      return 'default';
    case BOOKING_STATUS_BOOKED:
      return 'secondary';
    default:
      return 'default';
  }
};

export const getGuestLevelColor = (guestLevel: string) => {
  switch (guestLevel) {
    case 'gold':
      return '#FFB300';
    case 'silver':
      return '#bdbdbd';
    case 'bronze':
      return '#B86333';
    default:
      return '#212B36';
  }
};

export const getPaymentStatusColor = (status: string): 'success' | 'warning' | 'error' => {
  switch (status) {
    case 'PAID':
    case 'CREDIT':
    case 'NOT_APPLICABLE':
      return 'success';
    case 'PARTIAL':
    case 'OVER_PAID':
      return 'warning';
    case 'UNPAID':
      return 'error';
    default:
      return 'error';
  }
};

export const getSecurityDepositStatusColor = (status: string): 'success' | 'warning' | 'error' => {
  switch (status) {
    case 'AUTHORIZED':
    case 'M_AUTHORIZED':
    case 'CHARGED':
    case 'RELEASED':
    case 'PARTIALLY_CHARGED':
    case 'CHARGED_AND_RELEASED':
    case 'M_PARTIALLY_CHARGED':
    case 'NOT_APPLICABLE':
    case 'GUARANTEED_BY_CHANNEL':
    case 'PRE_AUTHORISED':
      return 'success';
    case 'PARTIALLY_AUTHORIZED':
      return 'warning';
    case 'AWAITING_AUTHORIZATION':
    case 'NOT_AUTHORIZED':
      return 'error';
    default:
      return 'error';
  }
};

export const getCheckInReadyConfig = (checkInReady: CheckInReadyStatus) => {
  if (checkInReady.name === 'GUEST_INFO') {
    return {
      name: checkInReady.name,
      status: checkInReady.status,
      icon: '/icons/ic_guest_info.svg',
      color: checkInReady.conditionIsMet ? 'success' : 'error',
    };
  }
  if (checkInReady.name === 'TERMS_AND_CONDITIONS') {
    return {
      name: checkInReady.name,
      status: checkInReady.status,
      icon: '/icons/ic_draw.svg',
      color: checkInReady.conditionIsMet ? 'success' : 'error',
    };
  }
  if (checkInReady.name === 'GUEST_VERIFICATION') {
    return {
      name: checkInReady.name,
      status: checkInReady.status,
      icon: '/icons/ic_guest_verification.svg',
      color: checkInReady.conditionIsMet ? 'success' : 'error',
    };
  }
  if (checkInReady.name === 'PAYMENT') {
    return {
      name: checkInReady.name,
      status: checkInReady.status,
      icon: '/icons/ic_payment.svg',
      color: getPaymentStatusColor(checkInReady.status),
    };
  }
  return {
    name: checkInReady.name,
    status: checkInReady.status,
    icon: '/icons/ic_security_deposit.svg',
    color: getSecurityDepositStatusColor(checkInReady.status),
  };
};

export const checkBookingTabsAcl = (user, tab: string, shouldShowOverviewTab: boolean = false) => {
  switch (tab) {
    case 'overview':
      return user.acls.PeymansBookingBundleCheckInReadyConfig.VIEW && shouldShowOverviewTab;
    case 'details':
      return user.acls.peyamns_booking_booking_details_view.EXECUTE;
    case 'guests':
      return user.acls.PeymansBookingBundleGuest.VIEW;
    case 'finance':
      return user.acls.peymans_finanace_fields_show.EXECUTE;
    case 'messages':
      return user.acls.PeymansAutomatedMessageBundleAutomatedMessage.VIEW;
    case 'operations':
      return user.acls.PeymansTaskBundleTask.VIEW;
  }
};

export const tabSelector = (tabs: BookingTabType[], filteredTabs: BookingTabType[]) =>
  tabs.filter((tab) => filteredTabs.includes(tab));

export const bookingNormalizer = (obj): Booking => ({
  id: obj.id,
  bookingOrderId: obj.booking_order.split('/').pop(),
  bookingNo: obj.booking_no,
  bookingCode: obj.booking_code,
  bookingSource: obj.creation_source,
  accessCode: obj.access_code,
  totalPaid: obj.price_breakdown.total_paid,
  totalPrice: obj.price_breakdown.total_price,
  totalSalePrice: obj.price_breakdown.total_sale,
  totalDuePrice: obj.price_breakdown.total_due,
  accommodationPrice: obj.price_breakdown.accommodation_price,
  channelAddonsPrice: obj.price_breakdown.channel_addons,
  channelTaxesPrice: obj.price_breakdown.channel_taxes,
  channelPromotionPrice: obj.price_breakdown.channel_promotions,
  channelTotalPrice: obj.price_breakdown.channel_total,
  zeevouAddonsPrice: obj.price_breakdown.zeevou_addons,
  zeevouDiscountsPrice: obj.price_breakdown.discount,
  zeevouTaxesPrice: obj.price_breakdown.zeevou_taxes,
  zeevouRefundsPrice: obj.price_breakdown.refunds,
  managementFee: obj.price_breakdown.managment_fee,
  totalPriceCurrency: obj.price_breakdown ? obj.price_breakdown.total_price_currency : null,
  totalPriceCurrencySymbol: obj.price_breakdown
    ? obj.price_breakdown.total_price_currency_symbol
    : null,
  cashPaymentMethod: obj.cash_payment_method,
  creditCardPaymentMethod: obj.credit_card_payment_method,
  bankTransferPaymentMethod: obj.bank_transfer_payment_method,
  bookingStatus: obj.booking_status,
  paymentStatus: obj.payment_status,
  paymentLink: obj.payment_link ?? '',
  canPayment: obj.can_payment,
  depositAmount: obj.deposit_amount ? obj.deposit_amount.value : 0,
  depositAmountCurrency: obj.deposit_amount ? obj.deposit_amount.currency : '',
  depositStatus: obj.deposit_status,
  depositLink: obj.deposit_link ?? '',
  canDeposit: obj.can_deposit,
  canFinalize: obj.can_finalize,
  canUndoFinalize: obj.can_undo_finalize,
  hasIntegrationWithXero: obj.has_integration_with_xero,
  finalizationStatus: obj.finalization_status,
  numberOfAdultGuests: obj.number_of_adult_guests,
  numberOfChildGuests: obj.number_of_child_guests,
  numberOfAllowedGuests: obj.number_of_allowed_guests,
  numberOfCheckedGuests: obj.number_of_checked_guests,
  arrivalDate: obj.arrival_date,
  departureDate: obj.departure_date,
  bookedDate: obj.created_at,
  checkInType: {
    id: obj.check_in_type.id,
    name: obj.check_in_type.name,
    type: obj.check_in_type.type,
    description: obj.check_in_type.description,
    visible: obj.check_in_type.visible,
    editable: obj.check_in_type.editable,
  },
  checkinDate: obj.checkin,
  checkoutDate: obj.checkout,
  checkinTime: obj.checkin_time,
  checkoutTime: obj.checkout_time,
  arrivalTime: obj.arrival_time,
  departureTime: obj.departure_time,
  checkInAddon: obj.check_in_addon ? bookingCICOTimeAddonNormalizer(obj.check_in_addon) : null,
  checkOutAddon: obj.check_out_addon ? bookingCICOTimeAddonNormalizer(obj.check_out_addon) : null,
  timezone: obj.timezone ?? '',
  arrivalTimeLink: obj.arriaval_time_link ?? '',
  bookedTime: obj.booked_time,
  numberOfNights: obj.number_of_nights,
  paymentCollectionType: obj.payment_collection_type,
  internalNotes: obj.notes ? obj.notes.map((item) => internalNoteNormalizer(item)) : null,
  addons: obj.addon ? obj.addon.map((item) => orderLineAddonNormalizer(item)) : [],
  discounts: obj.discount ? obj.discount.map((item) => discountNormalizer(item)) : [],
  refunds: obj.refund ? obj.refund.map((item) => refundNormalizer(item)) : [],
  bookingAttachments: obj.bookingAttachments
    ? bookingAttachmentNormalizer(obj.bookingAttachments)
    : [],
  propertyId: obj.property.split('/').pop(),
  propertyName: obj.Property_Name,
  propertyInternalName: obj.property_internal_name,
  ratePlanId: obj.rate_plan ? obj.rate_plan.id : null,
  taxRate: obj.rate_plan.tax_rate ? obj.rate_plan.tax_rate : null,
  unitTypeId: obj.unit_type ? obj.unit_type.split('/').pop() : '',
  unitTypeName: obj.UnitType_Name,
  unitId: obj.unit ? obj.unit.split('/').pop() : null,
  unitName: obj.Unit_Name ? obj.Unit_Name : null,
  guest: obj.guest
    ? {
        id: obj.guest.id,
        firstName: obj.guest.first_name,
        lastName: obj.guest.last_name,
        middleName: obj.guest.middle_name,
        email: obj.guest.email,
        gender: obj.guest.gender,
        dateOfBirth: obj.guest.date_of_birth ?? null,
        age: obj.guest.age ?? null,
        mobileNumber: obj.guest.mobile_number,
        idVerified: obj.guest.id_verified,
        guestLevel: obj.guest.guest_level,
        tags: obj.guest.tags ? obj.guest.tags.map((item) => tagNormalizer(item)) : null,
        address: {
          city: obj.guest.address ? obj.guest.address.city : '',
        },
        title: obj.guest.title ?? '',
        detailsConfirmed: obj.guest.details_confirmed,
      }
    : null,
  guestBalancePerBooking: obj.guest_balance_per_booking ? obj.guest_balance_per_booking : null,
  personBooking: obj.person_booking ? guestNormalizer(obj.person_booking) : null,
  corporation: obj.corporation
    ? {
        id: obj.corporation.id,
        name: obj.corporation.name,
        email: obj.corporation.email,
        tags: obj.corporation.tags ? obj.corporation.tags.map((item) => tagNormalizer(item)) : null,
        corporationLevel: obj.corporation.corporation_level,
      }
    : null,
  channel: {
    id: obj.channel_id,
    name: obj.channel,
    channelLogo: obj.channelLogo,
    channelDeal: {
      id: obj.channel_deal ? obj.channel_deal.id : null,
      perBookingPercentage: obj.channel_deal ? obj.channel_deal.per_booking_percentage : null,
    },
    channelLink: obj.channel_link ?? null,
    canConnectToChannel: obj.canConnectToChannel ?? null,
  },
  bookingReference: obj.booking_reference,
  isGuestVerified: obj.Guest_id_verified,
  isAgreementEnabled: obj.is_agreement_enabled,
  agreement: obj.agreement ? obj.agreement.split('/').pop() : null,
  agreementLink: obj.agreement_link ?? null,
  agreementPDFLink: obj.agreement_pdf_link,
  agreementTemplate: obj.agreement_template ? obj.agreement_template.split('/').pop() : null,
  extendedFrom: obj.extended_from ? bookingNormalizer(obj.extended_from) : null,
  extendedTo: obj.extended_to
    ? {
        id: obj.extended_to.id,
        bookingNo: obj.extended_to.booking_no,
      }
    : null,
  isExtendTo: obj.is_extend_to,
  canExtend: obj.can_extend,
  splitFrom: obj.split_from ? bookingNormalizer(obj.split_from) : null,
  splitTo: obj.split_to
    ? {
        id: obj.split_to.id,
        bookingNo: obj.split_to.booking_no,
      }
    : null,
  isSplitTo: obj.is_split_to,
  canSplit: obj.can_split,
  isLocked: obj.not_move,
  bookingPurpose: obj.booking_purpose,
  createdAt: obj.created_at,
  updatedAt: obj.updated_at,
  owner: obj.owner
    ? {
        id: obj.owner.owner_id,
        name: obj.owner.owner_name,
      }
    : null,
  tags: obj.tags ? obj.tags.map((item) => tagNormalizer(item)) : null,
  cancellationReason: obj.cancellation_reason,
  canceledBy: obj.canceled_by,
  brand: { id: obj.brand.id, name: obj.brand.name },
  isGuestLocalGuest: obj.is_same_city,
  isGuestBelowAllowed: obj.is_guest_age_below_allowed,
  minimumAge: obj.minimum_age,
  childAges: obj.child_ages ?? null,
  confirmationLink: obj.confirmation_link ?? '',
  riskFreeReservation: obj.b_d_c_risk_free,
  checkinInstruction: obj.checkin_instruction ?? '',
  checkoutInstruction: obj.checkout_instruction ?? '',
  hasSpecificPaymentLink: obj.has_specific_payment_link,
  entityInvoiced: obj.entity_invoiced,
  entityInvoicedEmail: obj.entityInvoicedEmail,
  referenceXmlData: obj['reference_xml_data '],
  ruXMLData: obj.reference_ru_xml_data,
  keynestGuestAccessCode: obj.key_nest_access_code_guest,
  keynestStaffAccessCode: obj.key_nest_access_code_staff,
  reviewLink: obj.review_link,
  includeSecurityDepositInInvoice: obj.include_s_d_in_invoice,
  extraGuestPrice: obj.extra_guest_price,
  unitMaxCapacity: obj.unit_maximum_capacity,
  paymentMethod: obj.payment_method,
  orderTotalDue: {
    value: obj.order_total_due?.value,
    currency: obj.order_total_due?.currency,
  },
  canManualPayment: obj.can_manual_payment,
  canPaymentPerBooking: obj.can_payment_per_booking,
  channelManager: obj.channel_manager,
});

export const bookingCICOTimeAddonNormalizer = (obj: any): CICOTimeAddon => ({
  time: obj.time,
  price: obj.price,
  status: obj.status,
  currencySymbol: obj.currency_symbol,
  addonId: obj.addon_id,
  addonName: obj.addon_name,
});

export const bookingAttachmentNormalizer = (attachments: any[]): BookingAttachment[] =>
  attachments.map((obj) => ({
    attachmentId: obj.attachment_id,
    fileId: obj.file_id,
    fileName: obj.file_name,
    originalName: obj.original_name,
    mimeType: obj.mime_type,
    extension: obj.extension,
    fileSize: obj.file_size,
    createdAt: obj.created_at,
    owner: obj.owner,
  }));

export const groupBookingsNormalizer = (obj, checkInOutConfig): GroupBooking => ({
  id: obj.id,
  bookingNo: obj.booking_no,
  bookingCode: obj.booking_code,
  bookingStatus: obj.booking_status,
  paymentStatus: obj.payment_status,
  arrivalDate: obj.arrival_date,
  departureDate: obj.departure_date,
  bookedDate: obj.created_at,
  arrivalTime: obj.arrival_time,
  departureTime: obj.departure_time,
  bookedTime: obj.booked_time,
  propertyId: obj.property.split('/').pop(),
  propertyName: obj.Property_Name,
  propertyInternalName: obj.property_internal_name,
  unitTypeId: obj.unit_type.split('/').pop(),
  unitTypeName: obj.UnitType_Name,
  unitId: obj.unit ? obj.unit.split('/').pop() : null,
  unitName: obj.Unit_Name ? obj.Unit_Name : null,
  guest: obj.guest
    ? {
        id: obj.guest.id,
        firstName: obj.guest.first_name,
        lastName: obj.guest.last_name,
        middleName: obj.guest.middle_name,
        email: obj.guest.email,
        gender: obj.guest.gender,
        dateOfBirth: obj.guest.date_of_birth ?? null,
        age: obj.guest.age ?? null,
        mobileNumber: obj.guest.mobile_number,
        idVerified: obj.guest.id_verified,
        guestLevel: obj.guest.guest_level,
        tags: obj.guest.tags ? obj.guest.tags.map((item) => tagNormalizer(item)) : null,
        address: {
          city: obj.guest.address ? obj.guest.address.city : '',
        },
        title: obj.guest.title ?? '',
        detailsConfirmed: obj.guest.details_confirmed,
      }
    : null,
  channel: {
    id: obj.channel_id,
    name: obj.channel,
    channelLogo: obj.channelLogo,
    channelDeal: {
      id: obj.channel_deal ? obj.channel_deal.id : null,
      perBookingPercentage: obj.channel_deal ? obj.channel_deal.per_booking_percentage : null,
    },
    channelLink: obj.channel_link ?? null,
    canConnectToChannel: obj.canConnectToChannel ?? null,
  },
  checkInOutConfig: checkInOutConfig,
  isExtendTo: obj.is_extend_to,
  canExtend: obj.can_extend,
  isSplitTo: obj.is_split_to,
  canSplit: obj.can_split,
  extraGuestPrice: obj.extra_guest_price,
  unitMaxCapacity: obj.unit_maximum_capacity,
});

export const agreementNormalizer = (obj): Agreement => ({
  id: obj.id,
  approved: obj.approved,
  ipAddress: obj.ip_address,
  email: obj.email,
  signature: {
    id: obj.signature.id,
    extension: obj.signature.extension,
    fileName: obj.signature.file_name,
    originalFileName: obj.signature.original_file_name,
  },
  guest: {
    id: obj.guest.id,
    firstName: obj.guest.first_name,
    lastName: obj.guest.last_name,
    middleName: obj.guest.middle_name,
    email: obj.guest.email,
  },
  createdAt: obj.created_at,
  updatedAt: obj.updated_at,
});

export const signableEnvelopNormalizer = (obj): SignableEnvelop => ({
  id: obj.id,
  guestId: obj.guest ? obj.guest.split('/').pop() : null,
  bookingId: obj.booking ? obj.booking.split('/').pop() : null,
  envelopeTitle: obj.envelope_title,
  href: obj.href,
  status: obj.status,
  accountOptions: {
    paymentPaid: obj.account_options.payment_paid,
    guestInformationConfirmed: obj.account_options.guest_information_confirmed,
    securityDepositAuthorized: obj.account_options.security_deposit_authorized,
    sendingBookingConfirmationEmail: obj.account_options.sending_booking_confirmation_email,
  },
  jsonInfo: obj.json_info
    ? {
        envelopeStatus: obj.json_info.envelope_status,
        envelopeRedirectUrl: obj.json_info.envelope_redirect_url,
        envelopeSent: obj.json_info.envelope_sent,
        envelopeCreated: obj.json_info.envelope_created,
        envelopeProcessed: obj.json_info.envelope_processed,
      }
    : null,
});

export const securityDepositPaymentNormalizer = (obj): SecurityDepositPayment => ({
  id: obj.id,
  date: obj.date,
  createdAt: obj.created_at,
  updatedAt: obj.updated_at,
  bookingOrder: {
    bookings: obj.booking_order.bookings?.map((item) => ({
      id: item.id,
      bookingNumber: item.booking_no,
    })),
    orderBy: {
      id: obj.booking_order.order_by.id,
      firstName: obj.booking_order.order_by.first_name,
      middleName: obj.booking_order.order_by.middle_name,
      lastName: obj.booking_order.order_by.last_name,
    },
  },
  threeDState: obj.three_d_state,
  origin: obj.origin,
  log: obj.log,
  amount: {
    value: obj.amount.value,
    currency: obj.amount.currency,
  },
  notes: obj.notes ? obj.notes.map((note) => depositNoteNormalizer(note)) : [],
  attachments: obj.attachments.length > 0 ? obj.attachments : [],
});

export const depositNoteNormalizer = (obj): InternalNote => ({
  id: obj.id,
  owner: obj.owner,
  message: obj.message,
  createdAt: obj.created_at,
  updatedAt: obj.updated_at,
});

export const bookingGuestNormalizer = (obj): BookingGuest => ({
  id: obj.id,
  guest: {
    id: obj.guest.id,
    firstName: obj.guest.first_name,
    lastName: obj.guest.last_name,
    middleName: obj.guest.middle_name,
    email: obj.guest.email,
    dateOfBirth: obj.guest.date_of_birth ?? null,
    age: obj.guest.age ?? null,
    idVerified: obj.guest.id_verified,
    guestLevel: obj.guest.guest_level,
    mobileNumber: obj.guest.mobile_number,
    isChild: obj.guest.child,
    tags: obj.guest.tags ? obj.guest.tags.map((item) => tagNormalizer(item)) : null,
    detailsConfirmed: obj.guest.details_confirmed,
  },
  child: obj.child,
});

export const invoiceNormalizer = (obj, imageObj): Invoice => ({
  id: obj.id,
  status: obj.doc_status,
  docNumber: obj.doc_number,
  invoiceLines: obj.invoice_lines.map((item) => invoiceLinesNormalizer(item)),
  from: obj.from_date,
  to: obj.to_date,
  xeroLink: obj.view_xero_invoice_u_r_l,
  guest: obj.contact
    ? {
        id: obj.contact.id,
        firstName: obj.contact.first_name,
        lastName: obj.contact.last_name,
        middleName: obj.contact.middle_name,
        email: obj.contact.email,
        mobileNumber: obj.contact.mobile_number,
      }
    : null,
  thumbnailLink: imageObj?.thumbnail_link ? imageObj.thumbnail_link : null,
  imageLink: imageObj?.image_link ? imageObj.image_link : null,
  showPaymentMethodOnInvoice: obj.payment_method_show_on_invoice,
});

export const invoiceLinesNormalizer = (obj): InvoiceLine => ({
  type: obj.type,
  price: {
    value: obj.price.value,
    currency: obj.price.currency,
    baseValue: obj.price.base_value,
  },
  totalAmount: {
    value: obj.total_amount.value,
    currency: obj.total_amount.currency,
    baseValue: obj.total_amount.base_value,
  },
  discount: obj.discount
    ? {
        value: obj.discount.value,
        currency: obj.discount.currency,
        baseValue: obj.discount.base_value,
      }
    : null,
});

export const calculateInvoiceTotalAmount = (invoice: Invoice): number => {
  let total = 0;
  invoice.invoiceLines.map((item) => {
    if (item.totalAmount) total += item.totalAmount.value;
  });
  return total;
};

export const expenseNormalizer = (obj): Expense => {
  return {
    id: obj.id,
    billId: obj.bill ? obj.bill.id : 0,
    docNumber: obj.bill ? obj.bill.doc_number : 0,
    creationMode: obj.bill ? obj.bill.creation_mode : null,
    type: obj.bill ? (obj.bill.addon ? obj.bill.addon.name : null) : null,
    amount: {
      value: obj.amount ? obj.amount.value : 0,
      currency: obj.amount ? obj.amount.currency : '',
      baseValue: obj.amount ? obj.amount.base_value : 0,
    },
    description: obj.description ? obj.description : obj.bill ? obj.bill.description : null,
    supplier: obj.bill ? (obj.bill.supplier ? obj.bill.supplier.name : null) : null,
  };
};

export const InstallmentNormalizer = (obj): Installment => ({
  id: obj.id,
  name: obj.name,
  installmentAmount: {
    value: obj.installment_amount.value,
    currency: obj.installment_amount.currency,
    baseValue: obj.installment_amount.base_value,
  },
  dueDate: obj.due_date,
  paymentLink: obj.payment_link,
  paymentStatus: obj.payment_status,
  paymentTerm: obj.payment_term
    ? {
        id: obj.payment_term.id,
        name: obj.payment_term.name,
      }
    : null,
  allocations: obj.installment_allocations.map((item) => InstalmentAllocationNormalizer(item)),
});

export const InstalmentAllocationNormalizer = (obj): InstallmentAllocation => ({
  amount: {
    value: obj.amount.value,
    currency: obj.amount.currency,
    baseValue: obj.amount.base_value,
  },
  payment: {
    date: obj.payment.datetime,
  },
});

export const propertyNormalizer = (obj): Property => ({
  id: obj.id,
  address: {
    firstLine: obj.address ? obj.address.first_line : '',
    secondLine: obj.address ? obj.address.second_line : '',
    city: obj.address ? obj.address.city : '',
    region: obj.address && obj.address.region ? obj.address.region.name : '',
    postalCode: obj.address ? obj.address.postal_code : '',
    country: obj.address ? obj.address.country.name : '',
  },
  area: obj.area
    ? {
        id: obj.area.id,
        name: obj.area.name,
        city: obj.area.city
          ? {
              id: obj.area.city.id,
              name: obj.area.city.name,
              timeZone: obj.area.city.time_zone,
            }
          : null,
      }
    : null,
  imageLogo: obj.property_image_logo ?? '',
  unitTypeCount: obj.unit_types.length,
  completeAddress: obj.address ? obj.address.complete_address : '',
});

export const conversationNormalizer = (obj): Conversation => ({
  id: obj.conversation_id,
  folder: obj.folder ?? '',
});

export const tagNormalizer = (obj): Tag => ({
  id: obj.id,
  name: obj.name,
  taggingId: obj.tagging_id,
});

export const internalNoteNormalizer = (obj): InternalNote => ({
  id: obj.id,
  message: obj.message,
  owner: obj.owner,
  createdAt: obj.updated_at,
  updatedAt: obj.created_at,
});

export const channelNormalizer = (obj): Channel => ({
  id: obj.channel_id,
  name: obj.channel,
  channelLogo: obj.channelLogo,
  channelDeal: {
    id: obj.channel_deal ? obj.channel_deal.id : null,
    perBookingPercentage: obj.channel_deal ? obj.channel_deal.per_booking_percentage : null,
  },
  channelLink: obj.channel_link ?? null,
  canConnectToChannel: obj.canConnectToChannel ?? null,
});

export const corporationNormalizer = (obj): Corporation => ({
  id: obj.corporation.id,
  name: obj.corporation.name,
  email: obj.corporation.email,
  tags: obj.corporation.tags ? obj.corporation.tags.map((item) => tagNormalizer(item)) : null,
  corporationLevel: obj.corporation.corporation_level,
});

export const refundNormalizer = (obj): Refund => ({
  id: obj.id,
  name: obj.name,
  description: obj.description,
  currency: obj.currency,
  quantity: obj.quantity,
  unitAmount: obj.unitAmount,
  totalAmount: obj.totalAmount,
  category: obj.category,
  approve: obj.approve,
});

export const checkInOutConfigNormalizer = (obj): CheckInOutConfig => ({
  checkIn: {
    earlyCheckInAddon: obj.early_check_in_addon,
    lateCheckInAddon: obj.late_check_in_addon,
    earliestPossibleCheckinTime: new Date(obj.earliest_possible_check_in_time),
    earliestFreeCheckinTime: new Date(obj.default_checkin_time),
    latestFreeCheckinTime: new Date(obj.latest_free_check_in_time),
    latestPossibleCheckinTime: new Date(obj.latest_possible_check_in_time),
  },
  checkOut: {
    earlyCheckOutAddon: obj.early_check_out_addon,
    lateCheckOutAddon: obj.late_check_out_addon,
    earliestPossibleCheckoutTime: new Date(obj.earliest_possible_check_out_time),
    earliestFreeCheckoutTime: new Date(obj.earliest_free_check_out_time),
    latestFreeCheckoutTime: new Date(obj.default_checkout_time),
    latestPossibleCheckoutTime: new Date(obj.latest_possible_check_out_time),
  },
});

export const confirmationSettingNormalizer = (obj): ConfirmationSetting => ({
  id: obj.id,
  title: obj.title,
  categories: obj.categories.map((category) => confirmationSettingCategoryNormalizer(category)),
});

export const confirmationSettingCategoryNormalizer = (obj): ConfirmationSettingCategory => ({
  id: obj.id,
  title: obj.title,
  isEnabled: obj.is_enabled ?? null,
  confirmationSettings: obj.confirmation_settings.map((setting) =>
    categorySettingNormalizer(setting)
  ),
});

export const categorySettingNormalizer = (obj): CategorySetting => ({
  id: obj.id,
  title: obj.title,
  isEnabled: obj.is_enabled ?? null,
});

export const guestNormalizer = (obj): Guest => ({
  id: obj.id,
  firstName: obj.first_name,
  lastName: obj.last_name,
  middleName: obj.middle_name,
  email: obj.email,
  gender: obj.gender,
  dateOfBirth: obj.date_of_birth ?? null,
  mobileNumber: obj.mobile_number,
  idVerified: obj.id_verified,
  guestLevel: obj.guest_level,
  isChild: obj.child,
  tags: obj.tags ? obj.tags.map((item) => tagNormalizer(item)) : null,
  address: {
    city: obj.address ? obj.address.city : '',
  },
  detailsConfirmed: obj.details_confirmed,
});

export const ratePlanNormalizer = (obj): BookingRatePlan => ({
  id: obj.id,
  name: obj.name,
  marketingName: obj.marketing_name,
  minGuests: obj.min_guests,
});

export const orderLineAddonNormalizer = (obj): OrderLineAddon => ({
  id: obj.id,
  orderLineId: obj.order_line_id,
  name: obj.name,
  description: obj.description,
  currency: obj.currency,
  quantity: obj.quantity,
  unitAmount: obj.unitAmount,
  totalAmount: obj.totalAmount,
  category: obj.category,
  approve: obj.approve,
  isFromChannel: obj.addon_from_channel,
  purchaseAmount: obj.purchaseAmount,
});

export const addonNormalizer = (obj): Addon => ({
  id: obj.id,
  name: obj.name ?? '',
  label: obj.label ?? '',
  type: obj.type ?? '',
  priceType: obj.price_type ?? '',
  typeCount: obj.type_count ?? null,
  salesPrice: {
    value: obj.sales_price ? obj.sales_price.value : 0,
    currency: obj.sales_price ? obj.sales_price.currency : '',
    baseValue: obj.sales_price ? obj.sales_price.base_value : 0,
  },
  purchasePrice: {
    value: obj.purchase_price ? obj.purchase_price.value : 0,
    currency: obj.purchase_price ? obj.purchase_price.currency : '',
    baseValue: obj.purchase_price ? obj.purchase_price.base_value : 0,
  },
});

export const discountNormalizer = (obj): Discount => ({
  id: obj.id,
  amount: obj.amount,
  currency: obj.currency,
  discountType: obj.discount_type,
  voucherId: obj.voucher_id ? obj.voucher_id : null,
  voucher: obj.voucher ? obj.voucher : null,
  voucherPromotionName: obj.voucher_promotion_name ? obj.voucher_promotion_name : null,
  promotionId: obj.promotion_id,
  promotionName: obj.promotion_name ? obj.promotion_name : null,
  isFromChannel: obj.from_channel,
  status: obj.status,
});

export const bookingPaymentNormalizer = (obj): BookingPayment => ({
  paymentId: obj.PaymentID,
  paymentDate: obj.PaymentDate,
  paymentAmount: obj.PaymentAmount,
  paymentAmountValue: obj.PaymentAmountValue,
  paymentAmountCurrency: obj.PaymentAmountCurrency,
  transactionId: obj.TransactionID,
  paymentState: obj.PaymentState,
  paymentType: obj.PaymentType,
  createdBy: obj.CreatedBy,
  actionsPermission: {
    void: obj.ActionsPermission.void,
    update: obj.ActionsPermission.update,
    confirm: obj.ActionsPermission.confirm,
    payBack: obj.ActionsPermission.payBack,
  },
  reference: obj.PaymentReference,
  description: obj.PaymentDescription,
  paymentGateway: obj.PaymentGateway,
  paymentOrigin: obj.PaymentOrigin,
  relatedPayment: obj.RelatedPayment.PaymentID ? paymentNormalizer(obj.RelatedPayment) : null,
});

export const paymentNormalizer = (obj): Payment => ({
  paymentId: obj.PaymentID,
  paymentDate: obj.PaymentDate,
  paymentAmount: obj.PaymentAmount,
  paymentAmountValue: obj.PaymentAmountValue,
  paymentAmountCurrency: obj.PaymentAmountCurrency,
  transactionId: obj.TransactionID,
  paymentState: obj.PaymentState,
  paymentType: obj.PaymentType,
  createdBy: obj.CreatedBy,
  actionsPermission: {
    void: obj.ActionsPermission.void,
    update: obj.ActionsPermission.update,
    confirm: obj.ActionsPermission.confirm,
    payBack: obj.ActionsPermission.payBack,
  },
  reference: obj.PaymentReference,
  description: obj.PaymentDescription,
  paymentGateway: obj.PaymentGateway,
  paymentOrigin: obj.PaymentOrigin,
});

export const checkInBackupTypeNormalizer = (obj): CheckInBackupType => ({
  checkInType: obj.checkin_type,
  propertyId: obj.property ? obj.property.split('/').pop() : null,
  unitId: obj.property_unit ? obj.property_unit.split('/').pop() : null,
  priority: obj.priority,
  displayToGuest: obj.display_to_guest,
});

export function extractUnitAvailabilities(data) {
  return data.reduce(function (
    accum: {
      id: number;
      name: string;
      isAvailable: boolean;
      type: 'divider' | 'item';
    }[],
    item
  ) {
    accum.push({
      id: item.id,
      name: item.name,
      isAvailable: item.isAvailable,
      type: 'item',
    });

    return accum;
  }, []);
}

export function extractUnits(data) {
  return data.reduce(function (
    accum: {
      id: number;
      name: string;
      unitType: { id: number; name: string };
      property: { id: number; name: string };
    }[],
    item
  ) {
    item.unitTypes.map((unitType) =>
      unitType.units.map((unit) =>
        accum.push({
          id: unit.id,
          name: unit.name,
          unitType: { id: unitType.id, name: unitType.name },
          property: { id: item.id, name: item.name },
        })
      )
    );

    return accum;
  }, []);
}

export const taxRateNormalizer = (obj): BookingTaxRate => ({
  taxRate: obj.booking_tax_rate.tax_rate
    ? {
        taxRateId: obj.booking_tax_rate.tax_rate.tax_rate_id,
        taxRateName: obj.booking_tax_rate.tax_rate.tax_rate_name,
        totalAmount: obj.booking_tax_rate.tax_rate.total_amount,
        currency: obj.booking_tax_rate.tax_rate.currency,
      }
    : null,
  taxComponents: obj.booking_tax_rate.tax_component
    ? obj.booking_tax_rate.tax_component.map((item) => taxComponentNormalizer(item))
    : [],
});

export const taxComponentNormalizer = (obj): TaxComponent => ({
  id: obj.tax_component_id,
  taxType: obj.tax_type.name,
  taxAuthority: obj.tax_authority.name,
  rateAmount: obj.rate_amount,
  rateType: obj.rate_type,
  incomeTax: obj.income_tax ?? 0,
  expenseTax: obj.expense_tax ?? 0,
  hostIncomeTax: obj.host_income_tax ?? 0,
  hostExpenseTax: obj.host_expense_tax ?? 0,
  investorIncomeTax: obj.investor_income_tax ?? 0,
  investorExpenseTax: obj.investor_expense_tax ?? 0,
  maximumCap: obj.maximum_cap,
  taxItems: obj.cost_element ? obj.cost_element.map((item) => item.name) : [],
  additionalTaxes: obj.additional_tax
    ? obj.additional_tax.map((item) => additionalTaxNormalizer(item))
    : [],
  isAdditional: obj.is_additional,
});

export const additionalTaxNormalizer = (obj): AdditionalTax => ({
  id: obj.tax_component_id,
  taxType: obj.tax_type.name,
  taxAuthority: obj.tax_authority.name,
  rateAmount: obj.rate_amount,
  rateType: obj.rate_type,
  isAdditional: obj.is_additional,
  incomeTax: obj.income_tax ?? 0,
  expenseTax: obj.expense_tax ?? 0,
  hostIncomeTax: obj.host_income_tax ?? 0,
  hostExpenseTax: obj.host_expense_tax ?? 0,
  investorIncomeTax: obj.investor_income_tax ?? 0,
  investorExpenseTax: obj.investor_expense_tax ?? 0,
});

export const bedConfigNormalizer = (obj): BedConfig => ({
  roomType: obj.roomType,
  roomID: obj.roomID,
  label: obj.label,
  beds: JSON.parse(obj.beds),
});

export const proceedPaymentNormalizer = (obj): ProceedPayment => ({
  paymentId: obj.paymentId,
  hasPayment: obj.hasPayment,
  amount: obj.amount ? obj.amount.value : null,
  reason: obj.reason,
});

export const enableDisableAutomatedMessageNormalizer = (obj): PotentialAutomatedMessage[] =>
  obj.potentials.map((message) => ({
    ...message,
    enabled: !obj.potentialDisableAutomatedMessages.includes(message.id),
  }));
