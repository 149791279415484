import { Box, FormHelperText, InputLabel, Stack } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import { useTheme } from '@mui/material/styles';
export default function CustomComponentTemplate(props) {
  const { translate } = useLocales();
  const theme = useTheme();
  return (
    <>
      <Box sx={props.sx}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          marginBottom={'1px'}
          alignItems={'center'}
        >
          <InputLabel
            sx={{
              color: props.disabled ? 'gray' : 'black',
              marginLeft: props.marginLeft ?? '10px',
            }}
          >
            {props.label}
          </InputLabel>
          {props.optional === true && (
            <FormHelperText sx={{ display: 'float', float: 'right' }}>
              {translate('Optional')}
            </FormHelperText>
          )}
        </Stack>
        {props.children}
      </Box>
    </>
  );
}
