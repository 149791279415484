import axios from 'src/utils/axios';

function accountAPI() {
  return {
    getOrganisationDetail: () => axios.get('/apis/organization/get_organization'),
    renameOrganisation: (organisationId: number, organisationNewName: string) => {
      const url = `/apis/organization/rename_organization_name/${organisationId}`;
      return axios.post(url, {
        organization_name: organisationNewName,
      });
    },
    updateOrganizationConfigurations: ({
      id,
      currency,
      registeredName,
      registeredNumber,
      dataProtectionNumber,
    }: {
      id: number;
      currency?: string;
      registeredName?: string;
      registeredNumber?: string | null;
      dataProtectionNumber?: string | null;
    }) =>
      axios.put(`/apis/organization_configurations/${id}`, {
        ...(currency !== undefined && { default_currency: currency }),
        ...(registeredName !== undefined && { registered_company_name: registeredName }),
        ...(registeredNumber !== undefined && { registered_number: registeredNumber }),
        ...(dataProtectionNumber !== undefined && { data_protection_number: dataProtectionNumber }),
      }),
    updateBrandName: (brandId: number, brandName: string) => {
      const url = `/apis/brand/rename_brand_name/${brandId}`;
      return axios.put(url, {
        name: brandName,
      });
    },
    createBrand: (name: string, subdomain: string) => {
      const url = '/apis/brand/create_brand';
      return axios.post(url, { name, subdomain });
    },
    cancelSubscription: (contractId: number) => {
      const url = `/apis/crm/cancel_subscription/${contractId}`;
      return axios.get(url);
    },
  };
}

export default accountAPI();
