import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import { utcToZonedTime } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Link, Stack, styled, Typography } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import {
  BOOKING_STATUS_CANCELED,
  BOOKING_STATUS_SCHEDULED_FOR_CANCELLATION,
} from 'src/utils/booking';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// @types
import { CheckInOutConfig } from 'src/@types/booking';
// components
import DialogBox from 'src/components/dialog-box/DialogBox';
import DrawerWithAction from 'src/components/DrawerWithAction';
// sections
import BookingSplitForm from './forms/BookingSplitForm';
import BookingExtendForm from './forms/BookingExtendForm';
import BookingCancelForm from './forms/BookingCancelForm';
import BookingCheckinForm from './forms/BookingCheckinForm';
import BookingCheckoutFrom from './forms/BookingCheckoutFrom';

const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.warning.dark,
  textDecoration: 'underline',
}));

type Props = {
  open: boolean;
  action: string;
  bookingId: number;
  bookingStatus: string;
  bookingSource: string;
  arrivalDate: Date | string | number;
  arrivalTime?: string;
  departureDate: Date | string | number;
  departureTime?: string;
  numOfAdultGuests: number;
  numOfChildGuests: number;
  channel: { id: number; name: string; canConnectToChannel: boolean };
  property: { id: number; name: string };
  unitType: { id: number; name: string };
  unit: { id: number; name: string } | null;
  bookingCheckInOutConfig: CheckInOutConfig;
  currency: string;
  currencySymbol: string;
  onClose: () => void;
  onAction: () => void;
};

export default function BookingActionDrawer({
  open,
  action,
  bookingId,
  bookingStatus,
  bookingSource,
  arrivalDate,
  arrivalTime,
  departureDate,
  departureTime,
  numOfAdultGuests,
  numOfChildGuests,
  channel,
  property,
  unitType,
  unit,
  currency,
  currencySymbol,
  bookingCheckInOutConfig,
  onClose,
  onAction,
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const childRef = React.useRef<any>(null);
  const { translate, currentLang } = useLocales(['common', 'calendar', 'booking']);

  const [loading, setLoading] = useState<boolean>(false);
  const [warningDialog, setWarningDialog] = useState<{
    open: boolean;
    title: string;
    body: ReactNode;
    actions: ReactNode;
  }>({
    open: false,
    title: '',
    body: undefined,
    actions: undefined,
  });

  const TITLES = {
    cancelled: translate('cancel.label'),
    scheduledForCancellation: translate('cancel.label'),
    checkedin: translate('checkedin.label'),
    checkedout: translate('checkedout.label'),
    split: translate('split.label'),
    extend: translate('extend.label'),
  };

  const ACTIONS = {
    cancelled: translate('cancel.action'),
    scheduledForCancellation: translate('cancel.action'),
    checkedin: translate('checkedin.action'),
    checkedout: translate('checkedout.action'),
    split: translate('split.label'),
    extend: translate('extend.label'),
  };

  const getDefaultAction = () => {
    switch (bookingStatus) {
      case BOOKING_STATUS_CANCELED:
        return 'Schedule';
      case BOOKING_STATUS_SCHEDULED_FOR_CANCELLATION:
        return 'Cancel';
      default:
        return 'Cancel';
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    childRef?.current?.submit();
  };

  function handleFinish(msg, data) {
    enqueueSnackbar(msg);
    setLoading(false);
    if ((action === 'extend' || action === 'split') && data.bookingId) {
      navigate(`${PATH_DASHBOARD.booking.booking}/${data.bookingId}/overview`);
      onAction();
    } else {
      onAction();
    }
  }

  function handleShowingError(data, action?, actionCallback?) {
    if (!!data) {
      if (data.errors.length > 0) {
        const error = data.errors[0];
        switch (error.enum) {
          case 'ALREADY_SPLIT':
            setWarningDialog({
              open: true,
              title: translate('booking_actions_warning.already_splited.title'),
              body: (
                <Box>
                  <Typography variant="body1" sx={{ px: 8, mt: 1.5 }}>
                    {translate('booking_actions_warning.already_splited.message')}
                  </Typography>
                </Box>
              ),
              actions: (
                <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                  <Button
                    sx={{ color: theme.palette.warning.dark, fontWeight: 700, padding: 1 }}
                    onClick={() =>
                      setWarningDialog({
                        open: false,
                        title: '',
                        body: undefined,
                        actions: undefined,
                      })
                    }
                  >
                    {translate('got_it')}
                  </Button>
                </Stack>
              ),
            });
            break;
          case 'UNIT_NOT_AVAILABLE':
            setWarningDialog({
              open: true,
              title: !!data.data.bookings
                ? translate('booking_actions_warning.unit_not_available.title_booking')
                : translate('booking_actions_warning.unit_not_available.title_blocked'),
              body: (
                <Box>
                  {data.data.bookings &&
                    data.data.bookings.map((booking) => (
                      <BookingClash key={booking.id} booking={booking} />
                    ))}

                  {data.data.blocked_dates &&
                    data.data.blocked_dates.map((blocked) => (
                      <BlockedClash key={blocked.id} blocked={blocked} />
                    ))}

                  <Typography variant="body1" sx={{ px: 8, mt: 1.5 }}>
                    {translate('booking_actions_warning.create_unallocated_message')}
                  </Typography>
                </Box>
              ),
              actions: <CreateUnallocatedAction onAction={actionCallback} />,
            });
            break;
        }
      }
    }
    setLoading(false);
  }

  const CreateUnallocatedAction = ({ onAction }) => (
    <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'flex-end'}>
      <Button
        sx={{ color: theme.palette.text.secondary, fontWeight: 600, padding: 1 }}
        onClick={() =>
          setWarningDialog({ open: false, title: '', body: undefined, actions: undefined })
        }
      >
        {translate('back')}
      </Button>
      <Button
        sx={{ color: theme.palette.warning.dark, fontWeight: 700, padding: 1 }}
        onClick={() => {
          setWarningDialog({ open: false, title: '', body: undefined, actions: undefined });
          setLoading(true);
          onAction();
        }}
      >
        {translate('create')}
      </Button>
    </Stack>
  );

  const BookingClash = ({ booking }) => (
    <Stack sx={{ mb: 1.5, px: 8, py: 2, bgcolor: 'warning.lighter' }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ mb: 1 }}
      >
        <Typography variant="body1">{translate('booking_number')}</Typography>
        <LinkStyled href={`${PATH_DASHBOARD.booking.booking}/${booking.id}/overview`}>
          <Typography noWrap variant="body2" color="warning.dark">
            {booking.booking_no}
          </Typography>
        </LinkStyled>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="body1">{translate('on')}</Typography>
        <Typography variant="body2" fontWeight={'fontWeightSemiBold'}>
          {format(new Date(booking.arrival_date), 'MMM do', { locale: currentLang.dateLocale })} -{' '}
          {format(new Date(booking.departure_date), 'MMM do', { locale: currentLang.dateLocale })}
        </Typography>
      </Stack>
    </Stack>
  );

  const BlockedClash = ({ blocked }) => (
    <Stack sx={{ mb: 1.5, px: 8, py: 2, bgcolor: 'warning.lighter' }}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="body1">{translate('blocked_date')}</Typography>
        <Typography variant="body2" fontWeight={'fontWeightSemiBold'}>
          {format(new Date(blocked.arrival_date), 'MMM do', { locale: currentLang.dateLocale })} -{' '}
          {format(new Date(blocked.departure_date), 'MMM do', { locale: currentLang.dateLocale })}
        </Typography>
      </Stack>
    </Stack>
  );

  return (
    <DrawerWithAction
      anchor="right"
      title={TITLES[action]}
      open={open}
      onClose={onClose}
      actions={
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Link
            underline="always"
            color="grey.600"
            onClick={onClose}
            sx={{ cursor: 'pointer', padding: theme.spacing(1) }}
          >
            {action === 'cancelled'
              ? translate('booking.general.keep_booking')
              : translate('cancel')}
          </Link>
          <LoadingButton onClick={handleSubmit} loading={loading} variant="contained">
            {ACTIONS[action]}
          </LoadingButton>
        </Stack>
      }
    >
      <Box sx={{ padding: theme.spacing(2, 3) }}>
        {action === 'checkedin' && (
          <BookingCheckinForm
            ref={childRef}
            bookingId={bookingId}
            defaultTime={
              arrivalTime
                ? utcToZonedTime(new Date(arrivalTime), 'UTC')
                : bookingCheckInOutConfig?.checkIn.earliestFreeCheckinTime
                ? utcToZonedTime(bookingCheckInOutConfig.checkIn.earliestFreeCheckinTime, 'UTC')
                : null
            }
            onError={handleShowingError}
            onFinish={handleFinish}
          />
        )}
        {action === 'checkedout' && (
          <BookingCheckoutFrom
            ref={childRef}
            bookingId={bookingId}
            defaultTime={
              departureTime
                ? utcToZonedTime(new Date(departureTime), 'UTC')
                : bookingCheckInOutConfig?.checkOut.latestFreeCheckoutTime
                ? utcToZonedTime(bookingCheckInOutConfig.checkOut.latestFreeCheckoutTime, 'UTC')
                : null
            }
            onError={handleShowingError}
            onFinish={handleFinish}
          />
        )}
        {(action === 'cancelled' || action === 'scheduledForCancellation') && (
          <BookingCancelForm
            ref={childRef}
            bookingId={bookingId}
            bookingStatus={bookingStatus}
            bookingSource={bookingSource}
            bookingChannel={channel}
            bookingCurrency={currency}
            bookingCurrencySymbol={currencySymbol}
            onError={handleShowingError}
            onFinish={handleFinish}
            action={action === 'cancelled' ? 'Cancel' : 'Schedule'}
          />
        )}
        {action === 'extend' && (
          <BookingExtendForm
            ref={childRef}
            bookingId={bookingId}
            arrivalDate={arrivalDate}
            departureDate={departureDate}
            numberOfAdultGuests={numOfAdultGuests}
            numberOfChildGuests={numOfChildGuests}
            bookingCurrency={currency}
            bookingCurrencySymbol={currencySymbol}
            property={property}
            unitType={unitType}
            unit={unit}
            onError={handleShowingError}
            onFinish={handleFinish}
          />
        )}
        {action === 'split' && (
          <BookingSplitForm
            ref={childRef}
            bookingId={bookingId}
            arrivalDate={arrivalDate}
            departureDate={departureDate}
            onError={handleShowingError}
            onFinish={handleFinish}
          />
        )}

        <DialogBox
          severity="warning"
          title={warningDialog.title}
          action={warningDialog.actions}
          openState={warningDialog.open}
          DialogProps={{
            maxWidth: 'sm',
            fullWidth: true,
          }}
          ContentProps={{ px: 0 }}
        >
          {warningDialog.body}
        </DialogBox>
      </Box>
    </DrawerWithAction>
  );
}
