import React, { FC, useState } from "react";
// @mui
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  Dialog,
  DialogContent, DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip
} from "@mui/material";
import { styled } from "@mui/material/styles";
// hooks
import useLocales from "src/hooks/useLocales";
// components
import Iconify from "src/components/Iconify";
import TextMaxLine from "src/components/TextMaxLine";
import Scrollbar from 'src/components/Scrollbar';


const ChipStyle = styled(Chip)(({ theme }) => ({
  maxWidth: 104,
  '& .MuiChip-label': {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    whiteSpace: 'normal',
    fontWeight: 600,
  },
  '& .MuiTypography-root': {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
     },
  },
  "& .MuiChip-deleteIcon": {
    display: "none"
  },
  "&:hover": {
    "& .MuiChip-deleteIcon": {
      display: "block"
    }
  }
}));

const ButtonStyle = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: 'transparent !important',
  fontWeight: 600,
  fontSize: 12,
  '&:hover': {
    color: theme.palette.text.primary,
  },
  '& .MuiButton-startIcon': {
    marginRight: 4,
  },
  marginLeft: 4,
}));

type Option = {
  id: number;
  name: string;
};

export interface TagProps extends ChipProps {
  options: Array<Option>;
  limitTags?: number;
  canEdit: boolean;
  canDelete: boolean;
  onAdd: (event?: any) => void;
  onDelete: (index: number) => void;
  moreDialogTitleLabel?: string;
  ButtonProps?: ButtonProps;
  BoxProps?: BoxProps;
  DialogProps?: Partial<DialogProps>;
}

const Tag: FC<TagProps> = ({
 options,
 limitTags,
 canEdit = true,
 canDelete = true,
 onAdd,
 onDelete,
 moreDialogTitleLabel,
 ButtonProps,
 BoxProps,
 DialogProps,
 ...other
}) => {
  const { translate } = useLocales('common');
  const [showMoreDialogOpen, setShowMoreDialogOpen] = useState(false);

  const onClickMore = (event: any) => {
    event.stopPropagation();
    setShowMoreDialogOpen(true);
  };

  const handleCloseMoreDialog = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    event?.stopPropagation?.();
    if (DialogProps?.onClose) DialogProps.onClose(event, reason);
    setShowMoreDialogOpen(false);
  };

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={1} {...BoxProps}>
      {(limitTags !== undefined && limitTags > 0 ? options.slice(0, limitTags) : options).map((option, index) => (
        <ChipStyle
          key={index}
          size="small"
          variant="filled"
          label={
            <Tooltip arrow title={option.name}>
              <TextMaxLine line={1}>{option.name}</TextMaxLine>
            </Tooltip>
          }
          onDelete={canDelete ? () => onDelete(index) : undefined}
          {...other}
        />
      ))}
      {(limitTags !== undefined && limitTags > 0 && (options.length - limitTags > 0)) &&
        <ChipStyle
          size="small"
          variant="filled"
          clickable
          label={`+${options.length - limitTags} ${translate('more')}`}
          onClick={onClickMore}
          {...other}
        />
      }
      {canEdit && (
        <Box color="text.secondary">
          <ButtonStyle
            disableRipple
            size="small"
            color="inherit"
            onClick={onAdd}
            startIcon={<Iconify width={16} height={16} icon='mdi:plus-circle-outline' sx={{ flexShrink: 0 }}/>}
            {...ButtonProps}>
            {translate('addTag')}
          </ButtonStyle>
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="xs"
        open={showMoreDialogOpen}
        {...DialogProps}
        onClose={handleCloseMoreDialog}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
          {moreDialogTitleLabel || translate('tags')}
          <IconButton
            size="small"
            edge="end"
            onClick={(event) => handleCloseMoreDialog(event, 'escapeKeyDown')}
          >
            <Iconify icon="mdi:close" width={24} height={24}/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Scrollbar>
            <Stack direction="row" flexWrap="wrap" alignItems="center" gap={1.5}>
              {options.map((option, index) => (
                <ChipStyle
                  key={index}
                  size="small"
                  variant="filled"
                  label={option.name}
                  onDelete={canDelete ? () => onDelete(index) : undefined}
                  sx={{
                    maxWidth: '100%',
                    '& .MuiChip-deleteIcon': { display: 'block !important' },
                  }}
                />
              ))}
              {canEdit && (
                <ButtonStyle
                  disableRipple
                  size="small"
                  color="inherit"
                  startIcon={<Iconify width={16} height={16} icon='mdi:plus-circle-outline' sx={{ flexShrink: 0 }}/>}
                  onClick={() => {
                    setShowMoreDialogOpen(false);
                    onAdd();
                  }}
                >
                  {translate('addTag')}
                </ButtonStyle>
              )}
            </Stack>
          </Scrollbar>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Tag;