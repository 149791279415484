import qs from 'qs';
import { format } from 'date-fns';
// @mui
import { GridSortItem } from '@mui/x-data-grid-pro';
// @types
import { FilterState, FilterValue } from 'src/@types/filter';
import { Filters, orderField, SortDirection } from 'src/@types/booking-list';
import { BedConfig, BookingRequestStatus } from 'src/@types/booking';
import { Filters as BlockedDateDefaultFilters } from 'src/@types/blocked-dates';
// utils
import axios from 'src/utils/axios';
// components
import { getDateFilterSearchParams } from 'src/components/Filter/FilterCustomAccordionDate';
// sections
import { BWGuest } from 'src/sections/wizard/booking/types/BookingWizardType';
import { getNumberAccordionFilterParams } from 'src/sections/@dashboard/booking-list/common';

function BookingApi() {
  return {
    fetchBooking: (id: number) => axios.get(`/apis/bookings/${id}`),
    fetchProperty: (id: number) => axios.get(`/apis/properties/${id}`),
    fetchGroupBookings: (id: number) => axios.get(`/apis/group_bookings/${id}`),
    fetchAgreementTemplate: (id: number) => axios.get(`/apis/agreement_templates/${id}`),
    fetchAgreement: (id: number) => axios.get(`/apis/agreements/${id}`),
    fetchSignableEnvelopByBooking: (id: number) => {
      const url = `/apis/signable_envelops`;
      return axios.get(url, {
        params: {
          booking: id,
        },
      });
    },
    sendSignableEnvelop: (id: number) => {
      const url = `/apis/send_envelop/${id}`;
      return axios.get(url);
    },
    fetchChannels: () =>
      axios.get(`/apis/channels`, {
        params: {
          page: 1,
          limit: 100,
        },
      }),
    fetchGuestAndCompanyLevels: () => axios.get(`/apis/get_company_and_guest_level`),
    fetchFinalizetionStatus: () => axios.get(`/apis/get_finalized_status`),
    fetchBookingPurposes: () => axios.get(`/apis/get_booking_purpose`),
    fetchRatePlanTypes: () => axios.get(`/apis/get_rate_plan_type`),
    fetchPaymentStatus: () => axios.get(`/apis/get_payment_status`),
    fetchGuestsSearch: (search: string, offset: number) =>
      axios.get('/apis/guest/search', {
        params: {
          search: search,
          offset: offset,
        },
      }),
    createGuest: (guest: BWGuest) =>
      axios.post('/apis/guests', {
        first_name: Boolean(guest.first_name) ? guest.first_name : null,
        last_name: Boolean(guest.last_name) ? guest.last_name : null,
        mobile_number: Boolean(guest.mobile_number) ? guest.mobile_number : null,
        email: Boolean(guest.email) ? guest.email : null,
      }),
    updateGuest: (guest: BWGuest) =>
      axios.put(`/apis/guests/${guest.id}`, {
        first_name: Boolean(guest.first_name) ? guest.first_name : null,
        last_name: Boolean(guest.last_name) ? guest.last_name : null,
        mobile_number: Boolean(guest.mobile_number) ? guest.mobile_number : null,
        email: Boolean(guest.email) ? guest.email : null,
      }),
    createPayment: (
      id: number,
      amount: number,
      currency: string,
      date: Date | string | number,
      time: string,
      type: string,
      reference: string,
      description: string,
      paymentId: number | null,
      file_ids: number[] | null,
      transactionCostSales: number,
      transactionCostPurchase: number,
      currencyConversionSales: number,
      currencyConversionPurchase: number
    ) =>
      axios.post(`/apis/booking/add_payment`, {
        booking_id: id,
        amount: amount,
        currency: currency,
        date: date,
        time: time,
        payment_type: type,
        reference: reference,
        description: description,
        payment_id: paymentId,
        file_ids: file_ids,
        transaction_cost_sales: transactionCostSales,
        transaction_cost_purchase: transactionCostPurchase,
        currency_conversion_sales: currencyConversionSales,
        currency_conversion_purchase: currencyConversionPurchase,
      }),
    createLogSecurityDeposit: (
      id: number,
      amount: number,
      currency: string,
      date: Date | string | number,
      type: string,
      reference: string,
      description: string
    ) =>
      axios.post(`/apis/bookings/log_security_deposit`, {
        booking_id: id,
        deposit_amount: amount,
        currency: currency,
        datetime: date,
        payment_type: type,
        reference: reference,
        description: description,
      }),
    searchUnitTypes: (
      arrivalDate: string,
      departureDate: string,
      numberOfAdultGuest: string,
      numberOfChildGuest: string,
      destinationType: string,
      destination: string,
      unitsCount: string,
      sortParams: { unitTotalPrice: string },
      filterParams: {
        price: { min: number; max: number; currency: string };
        maxCapacity: number | string;
        soldOut: boolean;
      },
      offset: number,
      limit: number
    ) =>
      axios.get('/apis/webapp/unit_types/availabilities', {
        params: {
          arrivalDate: arrivalDate,
          departureDate: departureDate,
          numberOfAdultGuest: numberOfAdultGuest,
          numberOfChildGuest: numberOfChildGuest,
          destinationType: destinationType,
          destination: destination,
          units: unitsCount,
          sortOptions: {
            unitTotalPrice: sortParams.unitTotalPrice,
          },
          filterOptions: {
            price: {
              minPrice: filterParams.price.min,
              maxPrice: filterParams.price.max,
              currency: filterParams.price.currency,
            },
            maxCapacity: filterParams.maxCapacity,
            soldOut: filterParams.soldOut,
          },
          offset: offset,
          limit: limit,
          isIncluded: true,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    searchLocation: (type: string, searchInput: string) =>
      axios.get('apis/property/search_location', {
        params: {
          types: [type],
          search: searchInput,
        },
      }),
    saveGroupBooking(data: { order_by: any; bookings: any }) {
      return axios.post('/apis/group_bookings', {
        order_by: data.order_by,
        bookings: data.bookings,
      });
    },
    loadGuestsItems() {
      return axios.get('/apis/bookings', {
        params: {
          booking_status: 'Checked in',
        },
      });
    },
    getTodayBookingsItemInStatusBar: () => axios.get('apis/booking/today_status_bar'),
    checkGuestExist: (email: string, mobile: string) =>
      axios.get('apis/guest/guest_duplication_check', {
        params: {
          email: email,
          mobile: mobile,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),

    fetchTotalBookingPerChannel: (from: string, to: string) => {
      const url = `/apis/total_booking_per_channel`;
      return axios.get(url, {
        params: {
          from: from,
          to: to,
        },
      });
    },
    fetchBookingRevenuePerChannel: (from: string, to: string) => {
      const url = `/apis/booking_revenue_per_channel`;
      return axios.get(url, {
        params: {
          from: from,
          to: to,
        },
      });
    },
    fetchPropertyByCity(city: string) {
      return axios.get('/apis/properties', {
        params: {
          'area.city.name': city,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
    },
    fetchPropertyByRegion(region: string) {
      return axios.get('/apis/properties', {
        params: {
          'address.region.combinedCode': region,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
    },
    fetchCity(country: string) {
      return axios.get('/apis/active_cities', {
        params: {
          country: country,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
    },
    fetchRatePlan(id: number) {
      return axios.get(`/apis/rate_plans/${id}`);
    },
    fetchPaymentTerms() {
      return axios.get(`/apis/payment_terms`);
    },
    unallocateBookings: (booking_ids: number[]) => {
      const url = '/apis/bookings/unnallocate';
      return axios.post(url, { booking_ids });
    },
    fetchBookingsStatusNumber: (startDate: string, endDate: string) =>
      axios.get('apis/bookings_numbers_arrival_date', {
        params: {
          from: startDate,
          to: endDate,
        },
      }),
    fetchLandingBooking: (
      startDate: string,
      endDate: string,
      currentTab: string,
      orderBy: string,
      order: string,
      searchQuery: string,
      offset: number,
      limit: number
    ) =>
      axios.get('apis/bookings_get_bookings_list_for_grid', {
        params: {
          start_date: startDate,
          end_date: endDate,
          section_title: currentTab,
          search_keyword: searchQuery,
          sort_column: orderBy,
          sort_order: order === 'asc' ? 'ASC' : 'DESC',
          offset: offset,
          limit: limit,
        },
      }),
    updateCustomerReview(id: number, isApproved: boolean) {
      return axios.put(`/apis/reviews/${id}`, {
        approved: isApproved,
      });
    },
    fetchPromotionApplications: (ratePlanId: number) => {
      const url = '/apis/booking/get_promotion_applications';
      return axios.get(url, {
        params: {
          rate_plan_id: ratePlanId,
        },
      });
    },
    getBookingPromotionApplication: (bookingId: number, promotionApplicationId: number) => {
      const url = '/apis/booking/booking_promotion';

      return axios.get(url, {
        params: {
          booking_id: bookingId,
          promotion_application_id: promotionApplicationId,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
    },
    getApplicablePromotion: (
      ratePlanId: number,
      arrivalDate: string,
      departureDate: string,
      numberOfGuest: number,
      subtotal: number
    ) => {
      const url = '/apis/promotion/check_applicable';
      return axios.post(url, {
        rate_plan: ratePlanId,
        arrival_date: arrivalDate,
        departure_date: departureDate,
        number_of_guests: numberOfGuest,
        subtotal: subtotal,
      });
    },
    updateUnitTypeRatesAndAvailabilities: (
      arrivalDate: string | Date,
      departureDate: string | Date,
      numberOfAdultGuest: number | null,
      numberOfChildGuest: number | null,
      unitTypeId: string | number,
      unitsCount: string | number
    ) =>
      axios.get('/apis/webapp/unit_types/availabilities', {
        params: {
          arrivalDate: arrivalDate,
          departureDate: departureDate,
          numberOfAdultGuest: numberOfAdultGuest,
          numberOfChildGuest: numberOfChildGuest,
          destinationType: 'unitType',
          destination: unitTypeId,
          units: unitsCount,
          filterOptions: {
            soldOut: true,
          },
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    fetchBookingGuests: (id: number) =>
      axios.get('/apis/booking_guests', {
        params: {
          booking: id,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    fetchBookingsGuestInvoice: (bookingNo: string) =>
      axios.get('apis/invoices', {
        params: {
          referenceCode: bookingNo,
        },
      }),
    fetchBookingsExpenses: (bookingId: number) =>
      axios.get('/apis/bill_cost_allocations', {
        params: {
          booking: bookingId,
        },
      }),

    fetchBookingsInvestor: (bookingId: number) =>
      axios.get('/apis/booking/investor_deal_info_by_booking', {
        params: {
          booking_id: bookingId,
        },
      }),

    fetchInstallments: (id: any, active = '') =>
      axios.get('/apis/installments', {
        params: {
          booking: id,
          active: active,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    fetchConversationUrl: (id: number) =>
      axios.get('/apis/inbox/get-conversation-with-booking', {
        params: {
          booking_id: id,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    fetchCorporationsSearch: (search: string, offset: number) =>
      axios.get('/apis/coporation/search', {
        params: {
          search: search,
          offset: offset,
        },
      }),
    updateChannel(bookingId: number, channelId: number) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        channel: channelId,
      });
    },
    updateBookingAccessCode(bookingId: number, accessCode: string) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        access_code: accessCode,
      });
    },
    updateBookingCheckInType(bookingId: number, checkInTypeId: number) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        check_in_type: checkInTypeId,
      });
    },
    updateBookingCheckInAgent(taskId: number, userId: number | null) {
      return axios.put('/apis/edit_booking_check_in_agents', {
        task_id: taskId,
        user_id: userId,
      });
    },
    updateBookingUnit(bookingId: number, unitId: number | null, notMove: boolean) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        unit: unitId ? unitId : null,
        not_move: notMove,
      });
    },
    updateCorporation(bookingId: number, corporationId: number) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        corporation: corporationId,
      });
    },
    updateChannelReference(bookingId: number, channelId: string) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        booking_reference: channelId,
      });
    },
    fetchAddon: (addonId: number) => axios.get(`/apis/add-ons/${addonId}`),
    fetchUpsellAddons: (propertyId: number) =>
      axios.get('/apis/booking/get_upsells', {
        params: {
          property_id: propertyId,
        },
      }),
    createUpsell: (
      bookingId: number,
      addonId: number,
      quantity: number,
      salesPriceValue: number,
      purchasePriceValue: number
    ) =>
      axios.post('/apis/booking/add_upsell', {
        booking_id: bookingId,
        addon_id: addonId,
        quantity: quantity,
        sales_price: salesPriceValue,
        purchase_price: purchasePriceValue,
      }),
    createInternalNote: (bookingId: number, text: string) => {
      const url = '/apis/booking/note';
      return axios.post(url, {
        booking_id: bookingId,
        note: text,
      });
    },
    updateInternalNote: (bookingId: number, noteId: number, text: string) => {
      const url = '/apis/note';
      return axios.put(url, {
        entity: 'booking',
        record_id: bookingId,
        note_id: noteId,
        text: text,
      });
    },
    deleteInternalNote: (bookingId: number, noteId: number) => {
      const url = '/apis/note';
      return axios.delete(url, {
        data: {
          entity: 'booking',
          record_id: bookingId,
          note_id: noteId,
        },
      });
    },
    createDiscount: (
      bookingOrderId: number,
      bookingId: number,
      type: string,
      amount: any | null,
      promotionApplication: number | null,
      voucher: number | null,
      isFromChannel: boolean
    ) => {
      const url = '/apis/discounts';
      return axios.post(url, {
        booking_order: bookingOrderId,
        booking: bookingId,
        type: type,
        status: 'OK',
        amount: amount,
        promotion_application: promotionApplication,
        voucher: voucher,
        from_channel: isFromChannel,
      });
    },
    fetchBookingInvoicePdf: (invoiceId: number) =>
      axios.get('/apis/invoice/generate_pdf_by_invoice', {
        params: {
          invoice_id: invoiceId,
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        },
      }),
    fetchBookingsDeposits: (bookingId: number) =>
      axios.get(`/apis/booking/security_deposit_details/${bookingId}`),
    fetchSecurityDepositPayment: (depositId: number) =>
      axios.get(`/apis/booking_deposits/${depositId}`),
    deleteBookingDepositNote: (depositId: number, noteId: number) =>
      axios.delete(`/apis/deposit/note/${depositId}/${noteId}`),
    editBookingDepositNote: (depositId: number, noteId: number, note: string) =>
      axios.put(`/apis/deposit/edit_note/{${depositId}/${noteId}`, {
        text: note,
      }),
    addBookingDepositAttachments: (entityClass: string, depositId: number, fileId: string) =>
      axios.post(`/apis/add_attachment_file_to`, {
        entity_class: entityClass,
        entity_id: depositId,
        file_id: fileId,
      }),
    addBookingDepositNote: (depositId: number, note: string) =>
      axios.post(`/apis/deposit/add_note/${depositId}`, {
        text: note,
      }),
    fetchVoucherPrice: (
      code: string,
      numberOfGuest: number,
      unitTypeId: number,
      planId: number,
      checkIn: string,
      checkOut: string,
      limit: number = 1
    ) => {
      const url = '/apis/voucher_price';
      return axios.get(url, {
        params: {
          VOUCHERS: code,
          GA: numberOfGuest,
          UNITTYPEID: unitTypeId,
          PLANID: planId,
          CHKIN: checkIn,
          CHKOUT: checkOut,
          limit: limit,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
    },
    postInvoiceToXero: (invoiceId: number) => {
      const url = '/apis/invoice/post_invoice_to_xero';
      return axios.post(url, {
        invoice_id: invoiceId,
      });
    },
    voidInvoice: (invoiceId: number) => {
      const url = '/apis/invoice/void_invoice_from_xero';
      return axios.post(url, {
        invoice_id: invoiceId,
      });
    },
    fetchConfirmationSettings: (brandId: number) => {
      const url = '/apis/confirmation_setting_category_types';
      return axios.get(url, {
        params: { brand: brandId },
      });
    },
    updateGuestBirthday(guestId: number, date: string | null) {
      return axios.put(`/apis/guests/${guestId}`, {
        date_of_birth: date,
      });
    },
    updateGuestCity(guestId: number, cityName: string | null) {
      return axios.put(`/apis/guests/${guestId}`, {
        address: {
          city: cityName,
        },
      });
    },
    updateBookingPurpose(bookingId: number, bookingPurpose: string) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        booking_purpose: bookingPurpose,
      });
    },
    updateGuestVerification(guestId: number, verified: boolean) {
      return axios.put(`/apis/guests/${guestId}`, {
        id_verified: verified,
      });
    },
    createNewBookingGuest: (
      bookingId: number,
      firstName: string,
      lastName: string,
      mobileNumber: string,
      email: string,
      isChild: boolean = false
    ) => {
      const url = '/apis/booking_guests';
      return axios.post(url, {
        booking: bookingId,
        child: isChild,
        guest: {
          first_name: firstName,
          last_name: lastName,
          mobile_number: mobileNumber,
          email: email,
        },
      });
    },
    createExistingBookingGuest: (bookingId: number, guestId: number, isChild: boolean) => {
      const url = '/apis/booking_guests';
      return axios.post(url, {
        booking: bookingId,
        guest: guestId,
        child: isChild,
      });
    },
    deleteBookingGuest: (bookingGuestId: number) => {
      const url = '/apis/booking/remove_guest';
      return axios.delete(url, { data: { booking_guest_id: bookingGuestId } });
    },
    updateBookingGuest: (bookingId: number, bookingGuestId: number, guestId: number) => {
      const url = `/apis/booking_guests/${bookingGuestId}`;
      return axios.put(url, {
        booking: bookingId,
        guest: guestId,
      });
    },
    deleteTag: (taggingId: number) => axios.delete(`/apis/tagging/delete?tagging_id=${taggingId}`),
    releaseSecurityDeposit: (bookingId: number) =>
      axios.post(`/apis/booking/release_security_deposit/${bookingId}`),
    fetchCurrencies: () => axios.get(`/apis/currencies`),
    fetchPaymentsByBooking: (bookingId: number) => {
      const url = `/apis/booking/get_paid_payments_by_booking`;
      return axios.get(url, {
        params: { booking_id: bookingId },
      });
    },
    fetchPenaltyTypes: () => axios.get(`apis/get_penalty_addons`),
    createSecurityDeposit: (
      bookingId: number,
      purchasePrice: number,
      currency: string,
      salePrice: number,
      reason: string,
      addon: number,
      action: string
    ) => {
      const url = `/apis/booking/capture_log_security_deposit/${bookingId}`;
      return axios.post(url, {
        purchasePrice: purchasePrice,
        purchaseCurrency: currency,
        salePrice: salePrice,
        saleCurrency: currency,
        reason: reason,
        addon: addon,
        action: action,
      });
    },
    fetchCities(name: string, page: number) {
      return axios.get('/apis/cities', {
        params: { limit: 20, page: page + 1, name },
      });
    },
    fetchBookingConfigs: (ids: string) => {
      const url = `/apis/booking_config`;
      return axios.get(url, {
        params: { ids: ids },
      });
    },
    updateExpense: (id: number, amount: number) => {
      const url = `/apis/expenses/edit_line_amount`;
      return axios.post(url, {
        expense_line_id: id,
        amount: amount,
      });
    },
    deleteExpense: (id: number) => {
      const url = `/apis/expenses/remove`;
      return axios.post(url, {
        expense_id: id,
      });
    },
    deleteExpenses: (id: number | string) => {
      const url = '/apis/expenses/bulk_remove';
      return axios.post(url, {
        expense_ids: id,
      });
    },
    fetchAvailabilities: (
      unitTypeId: number,
      propertyId: number,
      from: string,
      to: string,
      limit: number = 500
    ) =>
      axios.get('/apis/unit_type_availabilities', {
        params: {
          unit_type: unitTypeId,
          'unit_type.property': propertyId,
          'date[after]': from,
          'date[before]': to,
          limit: limit,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    fetchRateAndAvailability: (
      propertyId: number,
      from: string,
      to: string,
      numberOfGuests: number,
      checkAvailability: number = 0
    ) =>
      axios.get('/apis/rate_and_availability', {
        params: {
          property: propertyId,
          'date[after]': from,
          'date[before]': to,
          number_of_guests: numberOfGuests,
          check_availability: checkAvailability,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    updateNumberOfAdultGuests(bookingId: number, numberOfAdultGuests: number) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        number_of_adult_guests: numberOfAdultGuests,
      });
    },
    updateNumberOfChildGuests(bookingId: number, numberOfChildGuests: number) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        number_of_child_guests: numberOfChildGuests,
      });
    },
    fetchArrivalTimeOptions: (bookingId: number) => {
      const url = '/apis/booking/get_arrival_times_with_extra_prices';
      return axios.get(url, {
        params: { booking_id: bookingId}
      });
    },
    fetchDepartureTimeOptions: (bookingId: number) => {
      const url = '/apis/booking/get_departure_times_with_extra_prices';
      return axios.get(url, {
        params: { booking_id: bookingId}
      });
    },
    updateArrivalTime: (bookingId: number, arrivalTime: string | null, chargeExtra: number | null) => {
      const url = '/apis/booking/change_arrival_time';
      return axios.post(url, {
        booking_id: bookingId,
        arrival_time: arrivalTime ?? '',
        charge_extra: chargeExtra,
      });
    },
    updateDepartureTime: (bookingId: number, departureTime: string | null, chargeExtra: number | null) => {
      const url = '/apis/booking/change_departure_time';
      return axios.post(url, {
        booking_id: bookingId,
        departure_time: departureTime ?? '',
        charge_extra: chargeExtra,
      });
    },
    approveOrderLine(orderLineId: number) {
      return axios.post('/apis/booking/approve_order_line', {
        order_line_id: orderLineId,
      });
    },
    disapproveOrderLine(orderLineId: number) {
      return axios.post('/apis/booking/disapprove_order_line', {
        order_line_id: orderLineId,
        remove_related_expense: true,
      });
    },
    updateOrderLineAmount: (
      orderLineId: number,
      totalPurchaseAmount: number,
      purchaseCurrency: string,
      totalSalesAmount: number,
      salesCurrency: string,
      quantity: number,
      description?: string
    ) =>
      axios.post('/apis/booking/edit_order_line_amount', {
        order_line_id: orderLineId,
        purchase_amount: totalPurchaseAmount,
        purchase_amount_currency: purchaseCurrency,
        sales_amount: totalSalesAmount,
        sales_amount_currency: salesCurrency,
        quantity: quantity,
        description: description ? description : null,
      }),
    fetchCheckInTypeByPropertyOrUnit: (propertyId: number | null, unitId: number | null) => {
      const url = `/apis/check_in_backup_types`;
      const params = new URLSearchParams();
      if (propertyId) params.append('property', propertyId.toString());
      if (unitId) params.append('property_unit', unitId.toString());

      return axios.get(url, {
        params,
      });
    },
    fetchCheckInAgents: (propertyId: number) => {
      const url = '/apis/get_booking_check_in_agents';
      return axios.get(url, { params: { property_id: propertyId } });
    },
    fetchUnitTypeUnitAvailabilities: (
      unitTypeId: number | string,
      arrivalDate: string | Date,
      departureDate: string | Date
    ) =>
      axios.get('/apis/webapp/unit_types/availabilities', {
        params: {
          arrivalDate: arrivalDate,
          departureDate: departureDate,
          destination: unitTypeId,
          destinationType: 'unitType',
          numberOfAdultGuest: 1,
          numberOfChildGuest: 1,
          units: 1,
          filterOptions: {
            soldOut: true,
          },
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    updatePersonBooking(bookingId: number, personBookingId: number) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        person_booking: personBookingId,
      });
    },
    getBookingAddonImages: (bookingId: number) => {
      const url = `apis/get_booking_addon_images`;
      return axios.get(url, {
        params: {
          bookingId: bookingId,
        },
      });
    },
    getAttachmentFile: (id: number) => {
      const url = `/apis/attachments/${id}/file`;
      return axios.get(url, {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        },
      });
    },
    confirmBookingDeposit(bookingDepositId: number) {
      return axios.post(`/apis/booking/confirm_security_deposit/${bookingDepositId}`);
    },
    voidBookingDeposit(bookingDepositId: number) {
      return axios.post(`/apis/booking/void_security_deposit/${bookingDepositId}`);
    },
    updateCheckinInstruction(bookingId: number, checkinInstruction: string) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        check_in_instruction: checkinInstruction,
      });
    },
    updateCheckoutInstruction(bookingId: number, checkoutInstruction: string) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        check_out_instruction: checkoutInstruction,
      });
    },
    updateBooking: (bookingId: number, body: any) => axios.put(`/apis/bookings/${bookingId}`, body),
    fetchExpenseTypes: () => axios.get(`/apis/manual_expense_types`),
    fetchServiceProviders: () => axios.get(`/apis/suppliers`),
    fetchTaxSchemas: () => axios.get(`/apis/tax_schema/get_all`),
    createExpense: (
      bookingId: number,
      expenseDate: Date | string | number,
      expenseTypeId: number,
      totalAmountValue: number,
      xeroTaxLineType: string,
      taxSchemaId: number | null,
      serviceProviderId: number | null,
      description: string | null
    ) => {
      const url = `/apis/expenses/create_by_booking`;
      return axios.post(url, {
        booking_id: bookingId,
        expense_date: expenseDate,
        expense_type_id: expenseTypeId,
        total_amount_value: totalAmountValue,
        xero_tax_line_type: xeroTaxLineType,
        tax_schema_id: taxSchemaId,
        service_provider_id: serviceProviderId,
        description: description,
      });
    },
    updateBookingAccommodation(bookingId: number, amount: number, currency: string) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        accommodation_price: {
          currency: currency,
          value: amount,
        },
      });
    },
    voidDiscount: (discountId: number) =>
      axios.post(`/apis/booking/void_discount`, {
        discount_id: discountId,
      }),
    updateGuestAge(guestId: number, age: number) {
      return axios.put(`/apis/guests/${guestId}`, {
        age: age,
      });
    },
    updateBookingChildAges(bookingId: number, ages: string) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        child_ages: ages,
      });
    },
    setPaymentReminder: (bookingId: number, reminderDate: string, description: string) =>
      axios.post('/apis/booking/payment_reminder', {
        booking_id: bookingId,
        reminder_date: reminderDate,
        description: description,
      }),
    updateBookingAgreementEnabled: (bookingId: number, isAgreementEnabled: boolean) =>
      axios.put(`/apis/bookings/${bookingId}`, {
        is_agreement_enabled: isAgreementEnabled,
      }),
    updateBookingAgreement: (bookingId: number, agreementId: number | null) =>
      axios.put(`/apis/bookings/${bookingId}`, {
        agreement: agreementId,
      }),
    generatePaymentLink: (bookingId: number, payableAmount: number) =>
      axios.post(`/apis/booking/generate_partial_payment_link`, {
        booking_id: bookingId,
        payable_amount: payableAmount,
      }),
    sendPaymentLink: (bookingId: number) =>
      axios.post(`/apis/booking/send_partial_payment_link_to_guest`, {
        booking_id: bookingId,
      }),
    confirmPayment: (paymentId: number) =>
      axios.post('/apis/payment/confirm_payment', {
        payment_id: paymentId,
      }),
    voidPayment: (paymentId: number) =>
      axios.post('/apis/payment/void_payment', {
        payment_id: paymentId,
      }),
    separateGroupPaymentLink: (bookingId: number, separatePaymentLink: boolean) =>
      axios.post('/apis/booking/generate_booking_separate_group_payment_link', {
        booking_id: bookingId,
        separate_payment_link: separatePaymentLink,
      }),
    fetchTaxRate: (bookingNumber: number) =>
      axios.get('/apis/booking/get_booking_tax', {
        params: { booking_id: bookingNumber },
      }),
    setEmailInvoice: (
      bookingId: number,
      invoiceType: string,
      email: string | null,
      firstName: string | null,
      invoiceId: number
    ) =>
      axios.post(`/apis/booking/email_invoice`, {
        booking_id: bookingId,
        invoice_to: invoiceType,
        email_address: email,
        first_name: firstName,
        invoice_id: invoiceId,
      }),
    addBookingLeadGuest(bookingId: number, guestId: number, numberOfAdultGuests: number) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        guest: guestId,
        number_of_adult_guests: numberOfAdultGuests,
      });
    },
    getInvoiceImage: (invoiceId: number) => {
      const url = `/apis/invoice/generate_image_by_invoice`;
      return axios.get(url, {
        params: {
          invoice_id: invoiceId,
        },
      });
    },
    fetchBedConfig: (bookingNumber: number) =>
      axios.get('/apis/booking/booking_bed_config', {
        params: { booking_id: bookingNumber },
      }),
    overrideBookingConfig: (bookingId: number, bedConfigs: BedConfig[]) =>
      axios.post(`/apis/booking/override_bedding_config`, {
        id: bookingId,
        bed_configuration: bedConfigs,
      }),
    fetchBookingList: ({
      page,
      limit,
      order,
      defaultFilters,
      signal,
      filtersState,
      advanceFilterParams,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      order?: { field: string; sort: SortDirection };
      defaultFilters: Filters;
      signal?: AbortSignal;
      filtersState: {
        page?: number;
        limit?: number;
        guest?: FilterValue[];
        bookingStatus?: FilterValue[];
        channel?: FilterValue[];
        city?: FilterValue[];
        property?: FilterValue[];
        unit?: FilterValue[];
        unit_type?: FilterValue[];
        deposit_status?: FilterValue[];
        status?: BookingRequestStatus;
        order?: GridSortItem;
        unallocated?: boolean;
        unmapped?: boolean;
        cancelled?: boolean;
        arrival_date?: { from?: string | null; to?: string | null };
        departure_date?: { from?: string | null; to?: string | null };
        numberOfNights?: FilterValue;
        numberOfGuests?: FilterValue;
        numberOfAdultGuests?: FilterValue;
        numberOfChildGuests?: FilterValue;
        guest_level?: FilterValue;
        company_level?: FilterValue;
        attachment?: FilterValue;
        booking_purpose?: FilterValue;
        rate_plan_type?: FilterValue;
        term_conditions_signed?: FilterValue;
        booking_payment_status?: FilterValue[];
        group_booking_payment_status?: FilterValue[];
        tags?: FilterValue[];
        created_at?: FilterValue;
        finalization_status?: FilterValue[];
        creation_source?: FilterValue[];
        collection_type?: FilterValue;
        totalSale?: FilterValue;
        idVerified?: FilterValue;
      };
      advanceFilterParams?: { key: string; value: string };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) => {
      return axios.get('/apis/list/bookings', {
        signal,
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(advanceFilterParams && { [`${advanceFilterParams.key}`]: advanceFilterParams.value }),
          'arrival_date[after]': filtersState.arrival_date
            ? filtersState.arrival_date?.from
            : defaultFilters.arrival_date?.from,
          'arrival_date[strictly_before]': filtersState.arrival_date
            ? filtersState.arrival_date?.to
            : defaultFilters.arrival_date?.to,
          'departure_date[after]': filtersState.departure_date
            ? filtersState.departure_date?.from
            : defaultFilters.departure_date?.from,
          'departure_date[strictly_before]': filtersState.departure_date
            ? filtersState.departure_date?.to
            : defaultFilters.departure_date?.to,
          booking_status: [
            ...(defaultFilters.booking_status ? defaultFilters.booking_status : []),
            ...(Array.isArray(filtersState?.bookingStatus)
              ? filtersState.bookingStatus.map((status) => status.value)
              : []),
            ...(filtersState.cancelled ? ['Cancelled', 'Scheduled for Cancellation'] : []),
          ],
          ...(Array.isArray(filtersState?.deposit_status) && {
            depositStatus: filtersState.deposit_status.map((deposit) => deposit.value),
          }),
          ...(filtersState?.channel && {
            channelId: filtersState.channel?.map((channel) => channel.id),
          }),
          ...(filtersState?.guest_level && {
            'guest.guestLevel': filtersState.guest_level?.value,
          }),
          ...(filtersState?.company_level && {
            'corporation.corporationLevel': filtersState.company_level?.value,
          }),
          finalization_status: [
            ...(defaultFilters.finalization_status ? defaultFilters.finalization_status : []),
            ...(filtersState.finalization_status
              ? filtersState.finalization_status.map((status) => status.value)
              : []),
          ],
          ...(filtersState?.attachment && {
            hasAttachment: filtersState.attachment?.value,
          }),
          ...(filtersState?.booking_purpose && {
            bookingPurpose: filtersState.booking_purpose?.value,
          }),
          ...(filtersState?.rate_plan_type && {
            'staahRatePlan.type': filtersState.rate_plan_type?.value,
          }),
          ...(filtersState?.term_conditions_signed && {
            agreementSigned: filtersState.term_conditions_signed?.value,
          }),
          ...(Array.isArray(filtersState?.guest) && {
            guest: filtersState.guest.map((guest) => guest.id),
          }),
          ...(Array.isArray(filtersState?.unit_type) && {
            unitType: filtersState.unit_type.map((unit_type) => unit_type.id),
          }),
          ...(Array.isArray(filtersState?.booking_payment_status) && {
            [`bookingPaymentStatus`]: filtersState.booking_payment_status.map(
              (status) => status.value
            ),
          }),
          ...(Array.isArray(filtersState?.creation_source) && {
            [`creationSource`]: filtersState.creation_source.map((status) => status.value),
          }),
          ...(Array.isArray(filtersState?.group_booking_payment_status) && {
            [`paymentStatus`]: filtersState.group_booking_payment_status.map(
              (status) => status.value
            ),
          }),
          ...(Array.isArray(filtersState?.city) && {
            'property.area.city.id': filtersState.city.map((city) => city.id),
          }),
          ...(Array.isArray(filtersState?.property) && {
            property: filtersState.property.map((property) => property.id),
          }),
          ...(Array.isArray(filtersState?.unit) && {
            unit: filtersState.unit.map((unit) => unit.id),
          }),
          ...(filtersState?.numberOfNights && {
            ...getNumberAccordionFilterParams(
              'numberOfNights',
              (filtersState?.numberOfNights as FilterValue).value
            ),
          }),
          ...(filtersState?.numberOfGuests && {
            ...getNumberAccordionFilterParams(
              'numberOfGuests',
              (filtersState?.numberOfGuests as FilterValue).value
            ),
          }),
          ...(filtersState?.numberOfAdultGuests && {
            ...getNumberAccordionFilterParams(
              'numberOfAdultGuests',
              (filtersState?.numberOfAdultGuests as FilterValue).value
            ),
          }),
          ...(filtersState?.numberOfChildGuests && {
            ...getNumberAccordionFilterParams(
              'numberOfChildGuests',
              (filtersState?.numberOfChildGuests as FilterValue).value
            ),
          }),
          ...((filtersState.unallocated || defaultFilters.unallocated) && {
            [`exists[unit]`]: false,
          }),
          ...((filtersState.unmapped || defaultFilters.unmapped) && {
            [`exists[unitType]`]: false,
          }),
          ...(Array.isArray(filtersState?.tags) && {
            tags: filtersState?.tags.map((tag) => tag.label),
          }),
          ...(filtersState?.created_at && {
            ...getDateFilterSearchParams(
              'created_at',
              (filtersState?.created_at as FilterValue).value
            ),
          }),
          ...(filtersState?.collection_type && {
            collectionType: filtersState.collection_type.value,
          }),
          ...(filtersState?.totalSale && {
            ...getNumberAccordionFilterParams(
              'totalSale',
              (filtersState?.totalSale as FilterValue).value
            ),
          }),
          ...(filtersState?.idVerified && {
            idVerified: filtersState.idVerified.value,
          }),
          ...(order && { [`order[${orderField[order.field]}]`]: order.sort }),
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      });
    },
    fetchBookingRequests: (
      params?: {
        page?: number;
        limit?: number;
        guest?: number[];
        property?: number[];
        order?: GridSortItem;
        status?: BookingRequestStatus;
        departureDate?: { from?: string | null; to?: string | null };
      },
      signal?: AbortSignal
    ) =>
      axios.get('/apis/list/booking_requests', {
        signal,
        params: {
          page: params?.page,
          limit: params?.limit,
          lead_guest: params?.guest,
          property: params?.property,
          status: params?.status,
          ...(params?.order?.field && { [`order[${params.order.field}]`]: params.order.sort }),
          'departure_date[after]': params?.departureDate?.from || undefined,
          'departure_date[strictly_before]': params?.departureDate?.to || undefined,
        },
      }),
    declineBookingRequest: (id: number) => axios.post(`/apis/booking_requests/${id}/decline`),
    acceptBookingRequest: (id: number) => axios.post(`/apis/booking_requests/${id}/accept`),
    fetchAllBlockedDates: ({
      page,
      limit,
      order,
      signal,
      defaultFilters,
      filtersState,
      advanceFilterParams,
    }: {
      page: number;
      limit: number;
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      defaultFilters: BlockedDateDefaultFilters;
      filtersState: {
        from?: {
          from?: string;
          to?: string;
        };
        to?: {
          from?: string;
          to?: string;
        };
        createdAt?: {
          from?: string;
          to?: string;
        };
        block_type?: FilterValue;
        property?: FilterValue;
        unit?: FilterValue;
      };
      advanceFilterParams: { key: string; value: string } | undefined;
    }) => {
      const url = `/apis/list/block_dates`;
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          ...(advanceFilterParams && { [`${advanceFilterParams.key}`]: advanceFilterParams.value }),
          'from[after]': filtersState?.from ? filtersState?.from.from : undefined,
          'from[strictly_before]': filtersState?.from ? filtersState?.from.to : undefined,
          'to[after]': filtersState?.to ? filtersState?.to.from : undefined,
          'to[strictly_before]': filtersState?.to ? filtersState?.to.to : undefined,
          'createdAt[after]': filtersState?.createdAt ? filtersState?.createdAt.from : undefined,
          'createdAt[strictly_before]': filtersState?.createdAt
            ? filtersState?.createdAt.to
            : undefined,
          is_active: defaultFilters.is_active,
          ...(Array.isArray(filtersState?.block_type) && {
            block_type: filtersState.block_type.map((block_type) => block_type.id),
          }),
          ...(Array.isArray(filtersState?.property) && {
            'unit.unit_type.property.id': filtersState.property.map((property) => property.id),
          }),
          ...(Array.isArray(filtersState?.unit) && {
            'unit.id': filtersState.unit.map((unit) => unit.id),
          }),
          ...(order && { [`order[${order.field}]`]: order.sort }),
        },
      });
    },
    createInvoice: (bookingId: number, invoiceMethod: string, invoiceType: string) => {
      const url = `/apis/booking/create_invoices/${bookingId}`;
      return axios.post(url, {
        invoice_method: invoiceMethod,
        invoice_type: invoiceType,
      });
    },
    previewInvoice: (bookingId: number, invoiceMethod: string, invoiceType: string) => {
      const url = `/apis/booking/preview_splitted_invoices/${bookingId}`;
      return axios.get(url, {
        params: {
          invoice_method: invoiceMethod,
          invoice_type: invoiceType,
        },
      });
    },
    chargeVcc: (
      bookingId: number,
      vccNumber: string,
      expireMonth: number,
      expireYear: number,
      cvcNumber: number,
      firstName: string,
      lastName: string,
      addressOne: string,
      addressTwo: string,
      city: string,
      country: string,
      region: string,
      postalCode: string
    ) =>
      axios.post(`/apis/bookings/vcc_payment`, {
        booking_id: bookingId,
        vcc_number: vccNumber, // card number
        exp_month: expireMonth,
        exp_year: expireYear,
        cc_cvc: cvcNumber, // security code
        first_name: firstName,
        last_name: lastName,
        address1: addressOne,
        address2: addressTwo,
        city: city,
        country: country,
        region: region,
        postalCode: postalCode,
      }),
    stripeManualPayment: (bookingId: number, cardNumber: string, expiry: string, cvc: string) =>
      axios.post(`apis/payments/stripe_payment`, {
        booking_id: bookingId,
        card_number: cardNumber,
        expiry: expiry,
        cvc: cvc,
      }),
    worldPayManualPayment: (
      bookingId: number,
      cardNumber: string,
      expiry: string,
      cvc: string,
      cardName: string
    ) =>
      axios.post(`apis/payments/worldpay_payment`, {
        booking_id: bookingId,
        card_number: cardNumber,
        expiry: expiry,
        cvc: cvc,
        card_name: cardName,
      }),
    sagePayManualPayment: (
      bookingId: number,
      cardName: string,
      cardNumber: string,
      expiry: string,
      cvc: string,
      firstName: string,
      lastName: string,
      addressOne: string,
      addressTwo: string,
      city: string,
      country: string,
      region: string,
      postalCode: string
    ) =>
      axios.post(`apis/payments/sagepay_payment`, {
        booking_id: bookingId,
        card_number: cardNumber,
        card_name: cardName,
        expiry: expiry,
        cvc: cvc,
        first_name: firstName,
        last_name: lastName,
        address1: addressOne,
        address2: addressTwo,
        city: city,
        country: country,
        region: region,
        postalCode: postalCode,
      }),
    fetchBookingsRefunds: (bookingId: number) =>
      axios.get(`/apis/booking/get_refunds/${bookingId}`),
    fetchRefundTypeCategories: () => axios.get(`/apis/cost_element/get_refunds`),
    fetchRefundTypes: () => axios.get(`/apis/addon/get_refund_types`),
    fetchManualExpenseTypes: () => axios.get(`/apis/manual_expense_types`),
    createRefundType: (name: string, categoryId: number, relatedExpenseTypeId: number | null) =>
      axios.post('/apis/addon/create_refund_type', {
        name: name,
        categoryId: categoryId,
        relatedExpenseTypeId: relatedExpenseTypeId,
      }),
    addBookingOrderLineRefund: (
      bookingId: number,
      refundType: string,
      amount: number,
      reason: string
    ) =>
      axios.post('/apis/finance/add_booking_order_line_refund', {
        booking_id: bookingId,
        refund_type: refundType,
        amount: amount,
        reason: reason,
      }),
    fetchBookingCurrency: (bookingId: number) =>
      axios.get(`/apis/booking/get_booking_currency/${bookingId}`),
    editBookingOrderLineRefund: (refundOrderLineId: number, reason: string) =>
      axios.post(`/apis/booking/edit_refund/${refundOrderLineId}`, {
        reason: reason,
      }),
    fetchPaymentMethods: (paymentId: number) =>
      axios.get(`/apis/finance/get_payment_method`, {
        params: { payment_id: paymentId },
      }),
    fetchPaymentRefundType: () => axios.get(`/apis/finance/get_refund_payment_type`),
    addOverPaymentRefund: (
      paymentId: number,
      paymentMethod: string,
      amount: number,
      date: string | null,
      time: string | null,
      paymentType: string | null,
      reference: string | null,
      reason: string,
      hasPayment: boolean | null = null
    ) =>
      axios.post(`/apis/finance/over_payment_refund`, {
        payment_id: paymentId,
        payment_method: paymentMethod,
        amount: amount,
        date: date,
        time: time,
        payment_type: paymentType,
        reference: reference,
        reason: reason,
        has_payment: hasPayment,
      }),
    getProceedToPayment: (paymentMethod: string, orderLineId: number, bookingId: number) => {
      const url = '/apis/finance/get_proceed_to_payment';
      return axios.get(url, {
        params: {
          payment_method: paymentMethod,
          order_line_id: orderLineId,
          booking_id: bookingId,
        },
      });
    },
    getPaymentMethodsForOverPayments: (bookingId: number) => {
      const url = '/apis/finance/get_payment_method_by_booking_for_over_payment';
      return axios.get(url, {
        params: { booking_id: bookingId },
      });
    },
    removeAttachment: (attachmentId: number | Array<number>) => {
      const url = `/apis/delete_attachments_entity`;
      return axios.post(url, {
        attachment_id: attachmentId,
      });
    },
    archiveBlockedDate(blockedDateId: number) {
      return axios.put(`/apis/block_dates/${blockedDateId}`, {
        is_active: false,
      });
    },
    disableBlockedDates(blockedDateIds: number | string) {
      return axios.put('/apis/disable_block_dates', {
        ids: blockedDateIds,
      });
    },
    updateEntityInvoice: (
      bookingId: number,
      guestId: number | null,
      corporationId: number | null
    ) =>
      axios.post(`/apis/booking/save_entity_invoiced/${bookingId}`, {
        guest_id: guestId,
        corporation_id: corporationId,
      }),
    fetchCorporation: (corporationId: number) => axios.get(`/apis/corporations/${corporationId}`),
    fetchBlockTypes: () => axios.get('/apis/block_dates/get_blocked_types'),
    getTimeAvailability: (propertyId: number, unitId: number, from: string, to: string) => {
      const url = '/apis/block_dates/get_time_availability';
      return axios.get(url, {
        params: {
          property_id: propertyId,
          unit_id: unitId,
          from: from,
          to: to,
        },
      });
    },
    getDateAvailability: (propertyId: number, unitId: number, start: string, end: string) => {
      const url = '/apis/block_dates/get_date_availability_per_month';
      return axios.get(url, {
        params: {
          property_id: propertyId,
          unit_id: unitId,
          start: start,
          end: end,
        },
      });
    },
    createBlockedDate: (body: any) => {
      const url = '/apis/block_dates';
      return axios.post(url, body);
    },
    createBatchBlockedDates: (body) => {
      const url = '/apis/block_dates/batch';
      return axios.post(url, body);
    },
    updateBlockedDate: (blockedDateId: number, body: any) => {
      const url = `/apis/block_dates/${blockedDateId}`;
      return axios.put(url, body);
    },
    fetchBookings: (limit: number) =>
      axios.get(`/apis/bookings`, {
        params: {
          limit: limit,
        },
      }),
    getBookingAutomatedMessages: (body: { bookings: any }) =>
      axios.post('/apis/booking/get_booking_automated_messages', body),
    fetchCheckInReadyConditions: (brandId?: number) => {
      const url = '/apis/booking/get_check_in_ready_settings';
      const params = new URLSearchParams();
      if (brandId) params.append('brand_id', brandId.toString());
      return axios.get(url, {
        params,
      });
    },
    saveCheckInReadyConditions: (settings: any) =>
      axios.post('/apis/booking/add_check_in_ready_settings', {
        checkInReadySettings: settings,
      }),
    checkAgreementIsEnabled: (brandId: number) =>
      axios.get('apis/booking/agreement_is_enabled', {
        params: { brand_id: brandId },
      }),
    checkCheckInReadyConditionsAlert: (brandId: number) =>
      axios.get('/apis/booking/change_check_in_ready_settings_alert', {
        params: { brand_id: brandId },
      }),
    fetchInvoiceList: ({
      page,
      limit,
      filtersState,
      order,
      signal,
      advanceFilterParam,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      filtersState: {
        from?: {
          from?: string;
          to?: string;
        };
        to?: {
          from?: string;
          to?: string;
        };
        created_at?: {
          from?: string;
          to?: string;
        };
        corporation?: FilterValue;
        doc_status?: FilterValue;
        xero_status?: FilterValue;
      };
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      advanceFilterParam?: { key: string; value: string };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) => {
      const url = '/apis/list/invoices';
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          'from_date[after]': filtersState.from ? filtersState.from?.from : undefined,
          'from_date[strictly_before]': filtersState.from ? filtersState.from?.to : undefined,
          'to_date[after]': filtersState.to ? filtersState.to?.from : undefined,
          'to_date[strictly_before]': filtersState.to ? filtersState.to?.to : undefined,
          'created_at[after]': filtersState.created_at ? filtersState.created_at?.from : undefined,
          'created_at[strictly_before]': filtersState.created_at
            ? filtersState.created_at?.to
            : undefined,
          ...(Array.isArray(filtersState?.doc_status) && {
            doc_status: filtersState.doc_status.map((status) => status.value),
          }),
          ...(Array.isArray(filtersState?.xero_status) &&
            filtersState.xero_status.length === 1 && {
              [`xeroStatus`]: filtersState.xero_status[0].value,
            }),
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      });
    },
    deleteInvoice: (id: number) => {
      const url = `/apis/invoice/delete_customer_invoice`;
      return axios.post(url, {
        invoice_id: id,
      });
    },
    getBookingCheckInReadyStatus: (bookingId: number) =>
      axios.get('/apis/booking/check_conditions_in_check_in_ready_settings', {
        params: { booking_id: bookingId },
      }),
    enableDisableBookingAutomatedMessage: (bookingId: number, action: string) => {
      const url = `/apis/automated_message_notifications/enable_disable_all_pending_messages/${bookingId}/${action}`;
      return axios.post(url);
    },
    sendNowNotification: (notificationId: number) => {
      const url = `/apis/automated_message_notifications/send_now/${notificationId}`;
      return axios.put(url);
    },
    resendNotification: (notificationId: number) => {
      const url = `/apis/automated_message_notifications/resend/${notificationId}`;
      return axios.post(url);
    },
    getBookingConflicts: (
      arrival_date: string,
      departure_date: string,
      unit_id: number,
      arrival_time: string,
      departure_time: string,
      booking_id: number
    ) =>
      axios.post('/apis/bookings/check_conflicts', {
        arrival_date,
        departure_date,
        unit_id,
        arrival_time,
        departure_time,
        booking_id,
      }),
    fetchExpenseList: ({
      page,
      limit,
      filtersState,
      order,
      signal,
      advanceFilterParam,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      filtersState: {
        from?: {
          from?: string;
          to?: string;
        };
        to?: {
          from?: string;
          to?: string;
        };
        created_at?: {
          from?: string;
          to?: string;
        };
        doc_status?: FilterValue;
        expense_document_type?: FilterValue;
        expense_type?: FilterValue;
        allocation_based_on?: FilterValue;
      };
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      advanceFilterParam?: { key: string; value: string };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) => {
      const url = '/apis/list/expenses';
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          'from_date[after]': filtersState?.from ? filtersState?.from.from : undefined,
          'from_date[strictly_before]': filtersState?.from ? filtersState?.from.to : undefined,
          'to_date[after]': filtersState?.to ? filtersState?.to.from : undefined,
          'to_date[strictly_before]': filtersState?.to ? filtersState?.to.to : undefined,
          'created_at[after]': filtersState?.created_at ? filtersState?.created_at.from : undefined,
          'created_at[strictly_before]': filtersState?.created_at
            ? filtersState?.created_at.to
            : undefined,
          ...(Array.isArray(filtersState?.doc_status) && {
            doc_status: filtersState.doc_status.map((status) => status.value),
          }),
          ...(filtersState?.expense_document_type && {
            expense_document_type: filtersState.expense_document_type.value,
          }),
          ...(filtersState?.allocation_based_on && {
            allocation_based_on: filtersState.allocation_based_on.value,
          }),
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      });
    },
    fetchAddonsList: ({
      page,
      limit,
      filtersState,
      advanceFilterParam,
      category,
    }: {
      page: number;
      limit: number;
      filtersState: FilterState;
      advanceFilterParam?: { key: string; value: string };
      category?: string;
    }) => {
      const url = '/apis/list/addons';
      return axios.get(url, {
        params: {
          page,
          limit,
          undefined,
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(category && { category: category }),
        },
      });
    },
    fetchRefundList: ({
      page,
      limit,
      filtersState,
      order,
      signal,
      advanceFilterParam,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      filtersState: {
        date?: {
          from?: string;
          to?: string;
        };
        arrival_date?: {
          from?: string;
          to?: string;
        };
        departure_date?: {
          from?: string;
          to?: string;
        };
        created_at?: {
          from?: string;
          to?: string;
        };
      };
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      advanceFilterParam?: { key: string; value: string };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) => {
      const url = '/apis/list/refunds';
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          refund: true,
          'datetime[after]': filtersState.date ? filtersState.date?.from : undefined,
          'datetime[strictly_before]': filtersState.date ? filtersState.date?.to : undefined,
          'payment_allocations.booking_order_line.booking.arrival_date[after]':
            filtersState.arrival_date ? filtersState.arrival_date?.from : undefined,
          'payment_allocations.booking_order_line.booking.arrival_date[strictly_before]':
            filtersState.arrival_date ? filtersState.arrival_date?.to : undefined,
          'payment_allocations.booking_order_line.booking.departure_date[after]':
            filtersState.departure_date ? filtersState.departure_date?.from : undefined,
          'payment_allocations.booking_order_line.booking.departure_date[strictly_before]':
            filtersState.departure_date ? filtersState.departure_date?.to : undefined,
          'created_at[after]': filtersState.created_at ? filtersState.created_at?.from : undefined,
          'created_at[strictly_before]': filtersState.created_at
            ? filtersState.created_at?.to
            : undefined,
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      });
    },
    getExpenseView: (bill_id: string | undefined) => axios.get(`/apis/bills/${bill_id}`),
    editExpenseView: (
      bill_id: string | undefined,
      body: {
        xero_line_amount_type?: string;
        bill_date?: string;
        from_date?: string;
        to_date?: string;
        description?: string | null;
        addon?: string | number;
        tax_rate?: string | number;
        totalPrice?: { value: number; currency: string; base_value: number };
        supplier?: string | number | null;
        taxSchema?: string;
        property_units?: any;
      }
    ) => axios.put(`/apis/bills/${bill_id}`, body),
    fetchInvestorDealList: ({
      page,
      limit,
      filtersState,
      order,
      signal,
      advanceFilterParam,
    }: {
      page: number;
      limit: number;
      filtersState: {
        from?: {
          from?: string;
          to?: string;
        };
        to?: {
          from?: string;
          to?: string;
        };
        created_at?: {
          from?: string;
          to?: string;
        };
      };
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      advanceFilterParam?: { key: string; value: string };
    }) => {
      const url = '/apis/list/deals';
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          'from[after]': filtersState?.from ? filtersState?.from.from : undefined,
          'from[strictly_before]': filtersState?.from ? filtersState?.from.to : undefined,
          'to[after]': filtersState?.to ? filtersState?.to.from : undefined,
          'to[strictly_before]': filtersState?.to ? filtersState?.to.to : undefined,
          'created_at[after]': filtersState?.created_at ? filtersState?.created_at.from : undefined,
          'created_at[strictly_before]': filtersState?.created_at
            ? filtersState?.created_at.to
            : undefined,
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
        },
      });
    },
    updateSecurityDepositAmount: (bookingId: number, amount: number, currency: string) =>
      axios.put(`/apis/bookings/${bookingId}`, {
        deposit_amount: {
          value: amount,
          currency: currency,
        },
      }),
    createNewExpense: (body: {
      addon: number;
      from_date: Date;
      to_date: Date;
      bill_date: Date;
      total_price?: { value: number; currency: string };
      unit_price?: { value: number; currency: string };
      xero_line_amount_type: string;
      property_units?: number[];
      property?: number;
      unit_type?: number;
      property_unit?: number;
      description?: string;
      supplier?: number;
      tax_rate?: number;
      repeat_value?: number | null;
      repeat_period?: string | null;
      ending_method?: string | null;
      ends_on_date?: Date | null;
      ends_after_occurrences?: number | null;
      preview_schedule?: boolean | null;
      is_clone?: boolean;
      is_batch?: boolean;
    }) => axios.post(`/apis/bills`, body),
    fetchBookingStatuses: () => axios.get('/apis/get_booking_statuses'),
    getInvoicePDF: (invoiceId: number) => {
      const url = `/apis/invoice/generate_pdf_by_invoice`;
      return axios.get(url, {
        params: {
          invoice_id: invoiceId,
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        },
      });
    },
    updateInvoiceSettings: (
      invoice_id: number,
      bank_account_info: boolean,
      payment_info: boolean,
      include_security_deposit: boolean
    ) => {
      const url = `/apis/invoice/invoice_settings`;
      return axios.post(url, {
        invoice_id,
        bank_account_info,
        payment_info,
        include_security_deposit,
      });
    },
    fetchCurrenciesOfProperty: () => axios.get('/apis/get_used_currencies'),
    addExtraGuest: (body: any) => {
      const url = '/apis/booking/add_extra_guest';
      return axios.post(url, body);
    },
    includeSDInInvoice: (bookingId: number, includeSd: boolean) =>
      axios.put(`/apis/bookings/${bookingId}`, {
        include_s_d_in_invoice: includeSd,
      }),
    fetchUnavailableUnitDates: (bookingId: number, checkinDate: string, checkoutDate: string) =>
      axios.post('apis/booking/check_unit_availability', {
        bookingId,
        checkinDate,
        checkoutDate,
      }),
    fetchPriceRecalculation: (
      bookingId: number,
      checkinDate: string,
      checkoutDate: string,
      unitTypeID: number,
      accommodationPrice?: number,
      unitId?: number | null
    ) =>
      axios.post('/apis/booking/change_date_update_price', {
        bookingId,
        checkinDate,
        checkoutDate,
        unitTypeID,
        ...(accommodationPrice !== undefined && { accommodationPriceValue: accommodationPrice }),
        ...(unitId !== undefined && { unitId: unitId }),
      }),
    updateBookingDate: (
      bookingId: number,
      checkinDate: string,
      checkoutDate: string,
      unitId: number | null,
      unitTypeID: number,
      updatePrice: boolean,
      accommodationPriceValue?: number
    ) =>
      axios.post('/apis/booking/change_date', {
        bookingId,
        checkinDate,
        checkoutDate,
        unitId,
        unitTypeID,
        updatePrice,
        accommodationPriceValue,
      }),
    fetchListingAvailability: (
      arrival: Date | string | number,
      departure: Date | string | number,
      currency?: string,
      cities?: string
    ) => {
      const url = `/apis/listing/booking/properties`;
      return axios.get(url, {
        params: {
          arrivalDate: arrival,
          departureDate: departure,
          currency: currency,
          cities: cities,
        },
      });
    },
    fetchUnitTypeAvailability: (
      arrival: Date | string | number,
      departure: Date | string | number,
      propertyId?: number
    ) => {
      const url = `/apis/listing/booking/unit_types`;
      return axios.get(url, {
        params: {
          arrivalDate: arrival,
          departureDate: departure,
          propertyId: propertyId,
        },
      });
    },
    fetchUnitAvailability: (
      arrival: Date | string | number,
      departure: Date | string | number,
      propertyId?: number,
      unitTypeId?: number
    ) => {
      const url = `/apis/listing/booking/units`;
      return axios.get(url, {
        params: {
          arrivalDate: arrival,
          departureDate: departure,
          propertyId: propertyId,
          unitTypeId: unitTypeId,
        },
      });
    },
    updateBookingLocation: (
      bookingId: number,
      unitTypeID: number,
      unitId?: number,
      lockUnit?: boolean,
      unallocated?: boolean,
      updatePrice?: boolean,
      accommodationPriceValue?: number
    ) => {
      const url = `/apis/booking/change_location`;
      return axios.post(url, {
        bookingId: bookingId,
        unitTypeID: unitTypeID,
        unitId: unitId,
        lockUnit: lockUnit,
        unallocated: unallocated,
        updatePrice: updatePrice,
        accommodationPriceValue: accommodationPriceValue,
      });
    },
    fetchSuppliers: () => axios.get('/apis/company/get_supplier'),
    postToXeroExpense: (expenseId: number, supplierId: number | null) =>
      axios.post('/apis/expenses/post_to_xero', {
        expense_id: expenseId,
        supplier_id: supplierId,
      }),
    voidFromXeroExpense: (expenseId: number) =>
      axios.post('/apis/expenses/void_expense', {
        expense_id: expenseId,
      }),
    createNewSupplier: (
      supplierName: string,
      emailAddress: string | null,
      phoneNumber: string | null,
      website: string | null
    ) =>
      axios.post('/apis/companies', {
        name: supplierName,
        email: emailAddress,
        phone: phoneNumber,
        website: website,
      }),
    sendNowAutomatedMessage: (booking_id: string | number, automated_message_id: string | number) =>
      axios.post(`/apis/automated_message/send_now/${booking_id}/${automated_message_id}`),
    fetchClonedBookingDetails: (bookingId: number) => {
      const url = `/apis/get_clone_booking_details/${bookingId}`;
      return axios.get(url);
    },
    fetchPenaltyCategory: () =>
      axios.get('/apis/cost_elements', {
        params: {
          category: 'Penalty',
        },
      }),
    createPenaltyType: (body: any) =>
      axios.post('/apis/add-ons', {
        ...body,
        category: 'Penalty',
      }),
    createCostElement: (body: { name: string; category: string }) =>
      axios.post('/apis/cost_elements', body),
    changeToChannelCollect: (ids: any) =>
      axios.put('/apis/booking/change_to_channel_collect', { ids }),
    changePaymentCollectionType: (
      bookingId: number,
      paymentCollectionType: 'Hotel Collect' | 'Channel Collect'
    ) =>
      axios.put(`/apis/bookings/${bookingId}`, {
        payment_collection_type: paymentCollectionType,
      }),
    getBookingNumberInBatchExpenseDateRange: (
      startDate: number | Date,
      endDate: number | Date,
      currency: string
    ) =>
      axios.get('/apis/expenses/has_booking_in_date_range', {
        params: {
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
        },
      }),
    getAvailableListingsInBatchExpenseDateRange: (
      startDate: number | Date,
      endDate: number | Date,
      currency: string
    ) =>
      axios.get('/apis/expenses/booking_listings', {
        params: {
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
        },
      }),
    getAvailableUnitTypesInBatchExpenseDateRange: (
      startDate: number | Date,
      endDate: number | Date,
      currency: string,
      propertyId: number
    ) =>
      axios.get('apis/expenses/booking_unit_types', {
        params: {
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          property_id: propertyId,
        },
      }),
    getAvailableUnitsInBatchExpenseDateRange: (
      startDate: number | Date,
      endDate: number | Date,
      currency: string,
      unitTypeId: number
    ) =>
      axios.get('/apis/expenses/booking_units', {
        params: {
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          unit_type_id: unitTypeId,
        },
      }),
    getBatchExpenseBookingsInDateRange: ({
      startDate,
      endDate,
      propertyId,
      unitTypeId,
      unitId,
    }: {
      startDate: Date;
      endDate: Date;
      propertyId?: number;
      unitTypeId?: number;
      unitId?: number;
    }) =>
      axios.get('/apis/expenses/get_bookings_in_date_range', {
        params: {
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
          ...(propertyId && { property_id: propertyId }),
          ...(unitTypeId && { unit_type_id: unitTypeId }),
          ...(unitId && { unit_id: unitId }),
        },
      }),
    updateBillCostAllocation: (id: number, body: { description: string }) =>
      axios.put(`apis/bill_cost_allocations/${id}`, body),
    finalizeBooking: (bookingId: number) =>
      axios.post('/apis/booking/finalize_booking', { booking_id: bookingId }),
    finalizeBookings: (bookingIds: string) =>
      axios.post('/apis/booking/finalize_bookings', { booking_ids: bookingIds }),
    undoFinalizeBooking: (bookingId: number) =>
      axios.post('/apis/booking/undo_finalize_booking', { booking_id: bookingId }),
    markBookingAsFinalize: (bookingId: number) =>
      axios.post('/apis/booking/mark_as_finalized', { booking_id: bookingId }),
    markBookingsAsFinalize: (bookingIds: string) =>
      axios.post('/apis/booking/mark_bookings_as_finalized', { booking_ids: bookingIds }),
    unmarkBookingAsFinalize: (bookingId: string) =>
      axios.post('/apis/booking/unmark_as_finalized', { booking_id: bookingId }),
    unmarkBookingsAsFinalize: (bookingIds: string) =>
      axios.post('/apis/booking/unmark_bookings_as_finalized', { booking_ids: bookingIds }),
    deleteInvestorDeal: (dealId: number) => axios.delete(`apis/deals/${dealId}`),
    importBookings: (file_id: number | undefined) =>
      axios.post('/apis/booking_excel/import_bookings', { file_id }),
    getExcelRecords: (booking_excel_id: number) =>
      axios.get(`/apis/booking_excel_line/get_excel_records/${booking_excel_id}`),
    getBookingExcel: (booking_excel_id: number) =>
      axios.get(`/apis/booking_excels/${booking_excel_id}`),
    getListBookingExcel: () => axios.get(`/apis/list/booking_excels`),
    deleteImportBookings: (booking_excel_id: number) =>
      axios.delete(`/apis/booking_excel/delete_import_bookings/${booking_excel_id}`),
    transferToBooking: (booking_excel_id: number) =>
      axios.post(`/apis/booking_excel/transfer_to_booking/${booking_excel_id}`),
    deleteExcelRecords: (excel_record_id: number) =>
      axios.delete(`/apis/booking_excel_line/delete_excel_record/${excel_record_id}`),
    editBookingExcel: (
      excel_id: number,
      body: {
        name?: string;
        description?: string;
      }
    ) => axios.put(`/apis/booking_excels/${excel_id}`, body),
    getCheckInType: () => axios.get(`/apis/get_check_in_type`),
    editExcelRecords: (
      excel_record_id: number,
      body: {
        guest_first_name?: string;
        guest_last_name?: string;
        guest_email?: string;
        guest_phone?: string;
        channel?: string;
        property?: string;
        unit_type?: string;
        unit?: string;
        booking_date?: string;
        booking_status?: string;
        payment_collection_type?: string;
        arrival_date?: string;
        departure_date?: string;
        check_in_type?: string;
      }
    ) => axios.put(`/apis/booking_excel_lines/${excel_record_id}`, body),
    getDefaultBookingTemplate: () => axios.get(`/apis/get_default_booking_template`),
    editGuestBooking(bookingId: number, guestId: number) {
      return axios.put(`/apis/bookings/${bookingId}`, {
        guest: guestId,
      });
    },
    getBookingWarnings: (booking_id: number) =>
      axios.get(`/apis/booking/get_booking_warnings/${booking_id}`),
    dismissBookingWarning: (booking_warning_id: number) =>
      axios.put(`/apis/booking/dismiss_booking_warning/${booking_warning_id}`),
  };
}

export default BookingApi();
