import { useState, useRef, useEffect, useCallback } from 'react';
// @mui
import {
  Button,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Box,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from 'src/components/Iconify';
import { varFade } from 'src/components/animate';
import { m, AnimatePresence } from 'framer-motion';
import DrawerWithAction from 'src/components/DrawerWithAction';
// hook-form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { fData } from 'src/utils/formatNumber';
// api
import ticketAPI from 'src/api/ticket';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'src/redux/store';
// @types
import { TicketFormValueProps, TicketType } from 'src/@types/ticket';
// utils
import { isString } from 'lodash';
import { closeDialog as closeTicket, refreshHandAPI } from 'src/redux/slices/ticket';
import { toPng } from 'html-to-image';
import { dataURLtoBlob } from 'src/utils/imageUtils';

const URGENCY_LEVEL = [{ label: 'Low' }, { label: 'Medium' }, { label: 'High' }];

const MAX_FILE_SIZE = 10 * 1000 * 1000; // 10 Mb
const FILE_FORMATS = [
  'application/msword',
  'application/vnd.ms-excel',
  'application/pdf',
  'application/zip',
  'image/gif',
  'image/jpeg',
  'image/png',
  'application/vnd.ms-office',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const testFilesSize = (files?: [File]) => {
  let valid = true;
  if (files) {
    // eslint-disable-next-line array-callback-return
    files.map((file) => {
      if (file.size > MAX_FILE_SIZE) {
        valid = false;
      }
    });
  }
  return valid;
};

const testFileTypes = (files?: [File]) => {
  let valid = true;
  if (files) {
    // eslint-disable-next-line array-callback-return
    files.map((file) => {
      if (!FILE_FORMATS.includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
};

const FormSchema = Yup.object().shape({
  subject: Yup.string().required('subject'),
  handType: Yup.string().required('hand_type'),
  urgencyLevel: Yup.string().required('urgency_level'),
  description: Yup.string().optional(),
  attachments: Yup.mixed()
    .nullable()
    .test('fileFormat', 'attachment_format', testFileTypes)
    .test('fileSize', 'attachment_size', testFilesSize),
});

export function NewHandDrawer() {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales(['common','hands']);
  const dispatch = useDispatch();

  const open = useSelector((state) => state.ticket.open);
  const defaultValues = useSelector((state) => state.ticket.defaultValues);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const focusRef = useRef<any>(null);
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
  const [isHandTypeLoading, setIsHandTypeLoading] = useState<boolean>(false);

  const screenshotRef = useRef<File>();

  const {
    watch,
    reset,
    control,
    register,
    setValue,
    setFocus,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm<TicketFormValueProps>({
    mode: 'onTouched',
    defaultValues: defaultValues,
    resolver: yupResolver(FormSchema),
    shouldFocusError: true,
  });

  const watchAllFields = watch();

  const hasFile = watchAllFields.attachments.length > 0;

  const handleClickAttachment = () => {
    if (fileInputRef.current?.value) {
      fileInputRef.current.value = '';
    }
    fileInputRef.current?.click();
  };
  const onSubmit = async (data: any) => {
    await ticketAPI
      .createTicket({
        status: 1,
        type: data.handType,
        urgency_level: data.urgencyLevel,
        support_level: 'Level 1',
        ticket_lines: [{ description: data.description }],
        subject: data.subject,
        url_requested: `${window.location.href}`,
      })
      .then(async (response) => {
        if (hasFile) {
          const { id } = response.data.ticket_lines.data[0];
          const formData = new FormData();
          watchAllFields.attachments.forEach((file) => {
            formData.append(file.name + file.lastModified, file);
          });
          await ticketAPI
            .uploadAttachments(id, formData)
            .then(() => {
              handleClose();
              enqueueSnackbar(translate('raise_hand.success_message'));
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          handleClose();
          enqueueSnackbar(translate('raise_hand.success_message'));
        }

        dispatch(refreshHandAPI());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClose = () => {
    dispatch(closeTicket());
    reset();
  };

  const handleRemove = (file: File | string) => {
    const filteredItems = watchAllFields.attachments.filter((_file) => _file !== file);
    if (screenshotRef.current === file) {
      setValue('sendDiagnostics', false);
    }
    setValue('attachments', filteredItems);
    trigger('attachments', { shouldFocus: true }).then();
  };

  const getTicketTypes = useCallback(async () => {
    try {
      setIsHandTypeLoading(true);
      const response = await ticketAPI.fetchTicketTypes();
      setIsHandTypeLoading(false);
      if (isMountedRef.current) {
        response.data.sort((a, b) => a.order - b.order);
        setTicketTypes(response.data);
        setValue('handType', '5');
      }
    } catch (error) {
      console.error(error);
      setIsHandTypeLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef]);

  useEffect(() => {
    if (open) {
      if (focusRef.current) {
        setFocus('subject');
      }
      reset(defaultValues);

      const body = document.getElementById('root');
      if (body) {
        toPng(body, { cacheBust: true })
          .then((dataUrl) => {
            let screenshot = new File([dataURLtoBlob(dataUrl)], 'screenshot.png', {
              type: 'image/png',
              lastModified: new Date().getTime(),
            });

            screenshotRef.current = screenshot;
            setValue('attachments', [...defaultValues.attachments, screenshot]);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      getTicketTypes().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, focusRef.current, getTicketTypes, reset]);

  useEffect(() => {
    if (errors.subject) {
      setFocus('subject');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <DrawerWithAction
      open={open}
      onClose={handleClose}
      title={translate('raise_a_hand')}
      actions={
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Button variant="text" color="inherit" onClick={handleClose} disabled={isSubmitting}>
            <Typography variant="body2" sx={{ textDecoration: 'underline' }} color="text.secondary">
              {translate('cancel')}
            </Typography>
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {translate('save')}
          </LoadingButton>
        </Stack>
      }
    >
      <Box px={3}>
        <form>
          <Stack>
            <Controller
              name={'subject'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <>
                  <Typography
                    variant="body2"
                    fontWeight={'fontWeightSemiBold'}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {translate('raise_hand.subject')}
                  </Typography>
                  <TextField
                    ref={focusRef}
                    inputRef={ref}
                    onChange={onChange}
                    focused={!Boolean(error)}
                    value={value}
                    error={Boolean(error)}
                    helperText={Boolean(error) && translate('raise_hand.errors.' + error?.message)}
                    disabled={isSubmitting}
                    autoComplete="off"
                  />
                </>
              )}
            />

            <Controller
              name={'handType'}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography
                    variant="body2"
                    fontWeight={'fontWeightSemiBold'}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {translate('raise_hand.hand_type.label')}
                  </Typography>
                  <Select
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    disabled={isSubmitting}
                    endAdornment={
                      isHandTypeLoading && (
                        <Box width={24} height={24} mr={3}>
                          <CircularProgress size={24} />
                        </Box>
                      )
                    }
                  >
                    {ticketTypes.map((type) => (
                      <MenuItem key={type.name} value={type.id}>
                        {translate('raise_hand.hand_type.' + type.name)}
                      </MenuItem>
                    ))}
                  </Select>

                  {Boolean(errors.handType) && (
                    <FormHelperText sx={{ px: 2 }} error>
                      {translate('raise_hand.errors.' + errors.handType?.message)}
                    </FormHelperText>
                  )}
                </>
              )}
            />

            <Controller
              name={'urgencyLevel'}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography
                    variant="body2"
                    fontWeight={'fontWeightSemiBold'}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {translate('raise_hand.urgency_level.label')}
                  </Typography>
                  <Select
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    disabled={isSubmitting}
                  >
                    {URGENCY_LEVEL.map((type) => (
                      <MenuItem key={type.label} value={type.label}>
                        {translate('raise_hand.urgency_level.' + type.label)}
                      </MenuItem>
                    ))}
                  </Select>

                  {Boolean(errors.urgencyLevel) && (
                    <FormHelperText sx={{ px: 2 }} error>
                      {translate('raise_hand.errors.' + errors.urgencyLevel?.message)}
                    </FormHelperText>
                  )}
                </>
              )}
            />

            <Controller
              name={'description'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Typography
                      variant="body2"
                      fontWeight={'fontWeightSemiBold'}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {translate('raise_hand.description')}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                      {translate('optional')}
                    </Typography>
                  </Stack>
                  <TextField
                    onChange={onChange}
                    value={value}
                    multiline
                    rows={4}
                    disabled={isSubmitting}
                  />
                </>
              )}
            />

            <Controller
              name={'sendDiagnostics'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Tooltip arrow title={translate('raise_hand.send_diagnostics_tooltip') as string}>
                  <FormControlLabel
                    sx={{ mt: 2, ml: 1 }}
                    label={translate('raise_hand.send_diagnostics') as string}
                    disabled={isSubmitting}
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(event) => {
                          if (event.target.checked) {
                            if (screenshotRef.current) {
                              setValue('attachments', [
                                screenshotRef.current,
                                ...watchAllFields.attachments,
                              ]);
                            }
                          } else {
                            if (screenshotRef.current) {
                              handleRemove(screenshotRef.current);
                            }
                          }
                          onChange(event);
                        }}
                      />
                    }
                  />
                </Tooltip>
              )}
            />

            <Stack direction="column" alignItems="flex-start" spacing={2} sx={{ mt: 1, pb: 2 }}>
              <Box width="100%">
                {watchAllFields.attachments && (
                  <List disablePadding sx={{ overflowX: 'hidden' }}>
                    <AnimatePresence>
                      {watchAllFields.attachments.map((file) => (
                        <ListItem
                          key={file.name + file.lastModified}
                          component={m.div}
                          {...varFade().inRight}
                          sx={{
                            my: 1,
                            py: 0.75,
                            px: 2,
                            borderRadius: 1,
                            border: (theme) => `solid 1px ${theme.palette.divider}`,
                            bgcolor: 'background.paper',
                          }}
                        >
                          <ListItemIcon>
                            <Iconify icon={'eva:file-fill'} width={28} height={28} />
                          </ListItemIcon>
                          <ListItemText
                            primary={isString(file) ? file : file.name}
                            secondary={isString(file) ? '' : fData(file.size || 0)}
                            primaryTypographyProps={{
                              variant: 'subtitle2',
                              sx: { overflowWrap: 'break-word' },
                            }}
                            secondaryTypographyProps={{ variant: 'caption' }}
                            sx={{ pr: 4 }}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() => handleRemove(file)}
                              disabled={isSubmitting}
                            >
                              <Iconify icon={'eva:close-fill'} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </AnimatePresence>
                  </List>
                )}
              </Box>

              <input
                {...register('attachments')}
                ref={fileInputRef}
                type="file"
                multiple
                accept={
                  'application/msword,application/vnd.ms-excel,application/pdf,application/zip,image/gif,image/jpeg,image/png,application/vnd.ms-office,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                }
                onChange={(event) => {
                  const files = Array.from(event.target.files ?? []);
                  setValue('attachments', [...watchAllFields.attachments, ...files]);
                  trigger('attachments', { shouldFocus: true }).then();
                }}
                style={{ display: 'none' }}
              />

              {errors.attachments && (
                <FormHelperText sx={{ px: 2, display: 'block' }} error>
                  {Array.isArray(errors.attachments)
                    ? errors.attachments.map((error) =>
                        translate('raise_hand.errors.' + error.message)
                      )
                    : // @ts-ignore
                      translate('raise_hand.errors.' + errors.attachments.message)}
                </FormHelperText>
              )}

              <Button
                startIcon={<Iconify icon={'mdi:attachment'} sx={{ transform: 'rotate(270deg)' }} />}
                onClick={handleClickAttachment}
                disabled={isSubmitting}
              >
                {translate('raise_hand.add_attachments')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </DrawerWithAction>
  );
}
