import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import {
  AllPromotionCondition,
  PromotionApplication,
  ApplyType,
  Discount,
  LimitBookingSetting,
  Promotion,
  PromotionConditionKey,
  PromotionErrors,
  PromotionState,
  Voucher,
  VoucherSetting,
  DisplayOnWebsiteSetting,
  PromotionParams,
  PromotionApplicationBody,
  PromotionBody,
  AccommodationCondition,
  NumberOfGuestCondition,
  NumberOfNightsCondition,
  BookingDateRangeCondition,
  ArrivalDateCondition,
} from 'src/@types/promotion';
import { dispatch } from '../store';
import rateAPI from 'src/api/rate';
import { Node } from 'react-checkbox-tree';
import axios from 'axios';
import { generateOptions } from 'src/utils/rate';
import { BaseRatePlan, Condition, PriorityConditionOperator } from 'src/@types/common';
import promotionAPI from 'src/api/promotion';
import { convertPromotionBodyToForm } from 'src/utils/promotion';

// ----------------------------------------------------------------------

export const initialAccommodation: AccommodationCondition = {
  check: false,
  operator: Condition.MORE_THAN_OR_EQUAL,
  minValue: '',
  maxValue: '',
};
export const initialNumberOfGuest: NumberOfGuestCondition = {
  check: false,
  operator: Condition.MORE_THAN_OR_EQUAL,
  minValue: 1,
  maxValue: 1,
};
export const initialNumberOfNights: NumberOfNightsCondition = {
  check: false,
  operator: Condition.LESS_THAN_OR_EQUAL,
  minValue: 1,
  maxValue: 1,
};
export const initialBookingDateRange: BookingDateRangeCondition = {
  check: false,
  operator: Condition.BETWEEN,
  minValue: {
    date: null,
    time: null,
  },
  maxValue: {
    date: null,
    time: null,
  },
};
export const initialArrivalDate: ArrivalDateCondition = {
  check: false,
  operator: Condition.BETWEEN,
  minValue: null,
  maxValue: null,
};

const initialState: PromotionState = {
  id: 0,
  promotionId: 0,
  loading: false,
  isSubmitting: false,
  hasError: false,
  rowCount: 0,
  archived: false,
  isActive: true,
  promotions: [],
  promotionApplication: [],
  internalName: '',
  description: '',
  discount: {
    type: 'percentage',
    amount: '',
  },
  applyType: 'all',
  ratePlans: [],
  loadingRatePlans: false,
  loadingLimitBooking: false,
  loadingVouchers: false,
  loadingVoucher: false,
  loadingDisplayOnWebsite: false,
  loadingStacking: false,
  selectedRateIds: [],
  ratePlanNodes: [],
  conditions: {
    accommodation: initialAccommodation,
    numberOfGuest: initialNumberOfGuest,
    numberOfNights: initialNumberOfNights,
    bookingDateRange: initialBookingDateRange,
    arrivalDate: initialArrivalDate,
  },
  settings: {
    limitBooking: {
      check: false,
      limit: 1,
      current: 0,
    },
    applyVoucher: {
      check: false,
      vouchers: [
        {
          code: '',
          limit: 1,
        },
      ],
    },
    displayOnWebsite: {
      check: true,
    },
    stacking: {
      check: false,
      customizable: false,
      priority: Condition.FIRST,
    },
  },
  currencies: [],
  isSingleCurrency: false,
  errors: {},
};

const slice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    reset: () => initialState,
    setPromotion: (state, action: PayloadAction<Partial<PromotionState>>) => ({
      ...state,
      ...action.payload,
    }),
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setLoadingRatePlans(state, action: PayloadAction<boolean>) {
      state.loadingRatePlans = action.payload;
    },
    setLoadingLimitBooking(state, action: PayloadAction<boolean>) {
      state.loadingLimitBooking = action.payload;
    },
    setLoadingVouchers(state, action: PayloadAction<boolean>) {
      state.loadingVouchers = action.payload;
    },
    setLoadingVoucher(state, action: PayloadAction<boolean>) {
      state.loadingVoucher = action.payload;
    },
    setLoadingDisplayOnWebsite(state, action: PayloadAction<boolean>) {
      state.loadingDisplayOnWebsite = action.payload;
    },
    setLoadingStacking(state, action: PayloadAction<boolean>) {
      state.loadingStacking = action.payload;
    },
    setIsSubmitting(state, action: PayloadAction<boolean>) {
      state.isSubmitting = action.payload;
    },
    setHasError(state, action: PayloadAction<boolean>) {
      state.hasError = action.payload;
    },
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
    setArchived(state, action: PayloadAction<boolean>) {
      state.archived = action.payload;
    },
    setInternalName(state, action: PayloadAction<string>) {
      state.internalName = action.payload;
      state.errors.internalName = '';
    },
    setDescription(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    setDiscount(state, action: PayloadAction<Partial<Discount>>) {
      state.discount = { ...state.discount, ...action.payload };
      state.errors['discount.amount'] = '';
    },
    updateCondition(
      state,
      action: PayloadAction<{ key: PromotionConditionKey; value: Partial<AllPromotionCondition> }>
    ) {
      const { key, value } = action.payload;
      state.conditions = {
        ...state.conditions,
        [key]: {
          ...state.conditions[key],
          ...value,
        },
      };
    },
    resetCondition(state, action: PayloadAction<PromotionConditionKey>) {
      state.conditions = {
        ...state.conditions,
        [action.payload]: {
        ...initialState.conditions[action.payload],
        check: false,
      }
    }
    },
    updateBookingLimit(state, action: PayloadAction<LimitBookingSetting>) {
      state.settings.limitBooking = action.payload;
    },
    addVoucher(state, action: PayloadAction<Voucher>) {
      state.settings.applyVoucher.vouchers = [
        ...state.settings.applyVoucher.vouchers,
        action.payload,
      ];
    },
    removeVoucher(state, action: PayloadAction<number>) {
      const index = action.payload;
      state.settings.applyVoucher.vouchers.splice(index, 1);
    },
    removeVoucherById(state, action: PayloadAction<number>) {
      const id = action.payload;
      if (state.settings.applyVoucher.vouchers) {
        const { vouchers } = state.settings.applyVoucher;
        state.settings.applyVoucher.vouchers = vouchers.filter((voucher) => voucher.id !== id);
      }
    },
    updateVoucher(state, action: PayloadAction<{ index: number; voucher: Voucher }>) {
      const { index, voucher } = action.payload;
      state.settings.applyVoucher.vouchers[index] = voucher;
    },
    toggleVoucher(state, action: PayloadAction<boolean>) {
      state.settings.applyVoucher.check = action.payload;
    },
    updateToggleVoucher(state, action: PayloadAction<VoucherSetting>) {
      state.settings.applyVoucher = action.payload;
    },
    updateDisplayOnWebsite(state, action: PayloadAction<boolean>) {
      state.settings.displayOnWebsite.check = action.payload;
    },
    toggleStacking(state, action: PayloadAction<boolean>) {
      state.settings.stacking.check = action.payload;
    },
    toggleStackingCustomization(state, action: PayloadAction<boolean>) {
      state.settings.stacking.customizable = action.payload;
    },
    updateStackingPriority(state, action: PayloadAction<PriorityConditionOperator>) {
      state.settings.stacking.priority = action.payload;
    },
    setPromotions(state, action: PayloadAction<Promotion[]>) {
      state.promotions = action.payload;
    },
    setRowCount(state, action: PayloadAction<number>) {
      state.rowCount = action.payload;
    },
    setPromotionApplication(state, action: PayloadAction<PromotionApplication[]>) {
      state.promotionApplication = action.payload;
    },
    setRatePlans(state, action: PayloadAction<BaseRatePlan[]>) {
      state.ratePlans = action.payload;
    },
    setRatePlanNodes(state, action: PayloadAction<Node[]>) {
      state.ratePlanNodes = action.payload;
    },
    setCurrencies(state, action: PayloadAction<string[]>) {
      state.currencies = action.payload;
      state.isSingleCurrency = action.payload.length === 1;
    },
    setSelectedRatePlans(state, action: PayloadAction<number[]>) {
      state.selectedRateIds = action.payload;
    },
    setApplyType(state, action: PayloadAction<ApplyType>) {
      state.applyType = action.payload;
    },
    setError(state, action: PayloadAction<{ key: string; value: string }>) {
      const { key, value } = action.payload;
      state.errors = {
        ...state.errors,
        [key]: value,
      };
    },
    resetErrors(state) {
      state.errors = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  reset,
  setIsActive,
  setArchived,
  setSelectedRatePlans,
  setInternalName,
  setDescription,
  setDiscount,
  updateCondition,
  resetCondition,
  updateBookingLimit,
  addVoucher,
  removeVoucher,
  removeVoucherById,
  toggleVoucher,
  updateVoucher,
  updateDisplayOnWebsite,
  toggleStacking,
  toggleStackingCustomization,
  updateStackingPriority,
  setApplyType,
  setError,
  resetErrors,
  setIsSubmitting,
} = slice.actions;

// ----------------------------------------------------------------------

function extractRatePlans(data) {
  return data.reduce(function (
    accum: {
      id: number;
      name: string;
    }[],
    item
  ) {
    item.properties.map((property) =>
      property.unit_types.map((unit_type) =>
        unit_type.rate_plans.map((rate_plan) =>
          accum.push({
            id: rate_plan.id,
            name: rate_plan.name,
          })
        )
      )
    );

    return accum;
  }, []);
}

export function getPromotions(params: PromotionParams, signal?: AbortSignal) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await promotionAPI.fetchPromotions(params, signal);
      dispatch(slice.actions.setPromotionApplication(data.data));
      dispatch(slice.actions.setRowCount(data.meta.total_items));
      dispatch(slice.actions.setLoading(false));
    } catch (error) {
      dispatch(slice.actions.setHasError(false));
    }
  };
}

export function getPromotionById(id: number | string) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await promotionAPI.fetchPromotion(id);
      dispatch(slice.actions.setPromotion(convertPromotionBodyToForm(data)));
    } catch (error) {
      dispatch(slice.actions.setHasError(true));
    }
    dispatch(slice.actions.setLoading(false));
  };
}

export function getRatePlans() {
  return async () => {
    dispatch(slice.actions.setLoadingRatePlans(true));
    try {
      await axios.all([rateAPI.getRatePlans()]).then(
        axios.spread((ratePlansResponse) => {
          const { convertedTree, currencies } = generateOptions(ratePlansResponse.data);
          dispatch(slice.actions.setRatePlans(extractRatePlans(ratePlansResponse.data)));
          dispatch(slice.actions.setRatePlanNodes(convertedTree));
          dispatch(slice.actions.setCurrencies(currencies));
        })
      );
    } catch (error) {
      dispatch(slice.actions.setHasError(true));
    }
    dispatch(slice.actions.setLoadingRatePlans(false));
  };
}

export function addSingleVoucher(
  promotionAppId: number | string,
  code: string,
  limit: number | null,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    dispatch(slice.actions.setLoadingVouchers(true));
    try {
      const { data } = await promotionAPI.createVoucher(code, limit, promotionAppId);
      dispatch(
        slice.actions.addVoucher({
          id: data.id,
          code: data.code,
          limit: data.limit,
          used: data.used,
        })
      );
      callback?.(data, true);
    } catch (error) {
      callback?.(error, false);
    }
    dispatch(slice.actions.setLoadingVouchers(false));
  };
}

export function updateSingleVoucher(
  id: any,
  index: number,
  voucher: Voucher,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    dispatch(slice.actions.setLoadingVouchers(true));
    try {
      const { data } = await promotionAPI.updateVoucher(voucher);
      dispatch(slice.actions.updateVoucher({ index, voucher }));
      callback?.(data, true);
    } catch (error) {
      callback?.(error, false);
    }
    dispatch(slice.actions.setLoadingVouchers(false));
  };
}

export function updateToggleVoucher(
  promotionAppId: number | string,
  checked: boolean,
  vouchers: Voucher[]
) {
  return async () => {
    dispatch(slice.actions.setLoadingVouchers(true));
    try {
      const {data } = await promotionAPI.updatePromotionApplications(promotionAppId, { vouchers });
      dispatch(slice.actions.updateToggleVoucher({ check: checked, vouchers: data.vouchers }));
    } catch (error) {}
    dispatch(slice.actions.setLoadingVouchers(false));
  };
}

export function removeSingleVoucher(
  id: number,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    dispatch(slice.actions.setLoadingVoucher(true));

    try {
      const { data } = await promotionAPI.removeVoucher(id);
      dispatch(slice.actions.removeVoucherById(id));
      callback?.(data, true);
    } catch (error) {
      callback?.(error, false);
    }
    dispatch(slice.actions.setLoadingVoucher(false));
  };
}

export function updateBookingsLimit(
  promotionAppId: number | string,
  checked: boolean,
  requestBody: Partial<PromotionApplicationBody>,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    dispatch(slice.actions.setLoadingLimitBooking(true));
    if (promotionAppId) {
      try {
        const { data } = await promotionAPI.updatePromotionApplications(
          promotionAppId,
          requestBody
        );
        dispatch(slice.actions.updateBookingLimit({ check: checked, limit: data.limit }));
        callback?.(data, true);
      } catch (error) {
        callback?.(error, false);
      }
      dispatch(slice.actions.setLoadingLimitBooking(false));
    }
  };
}

export function onDisplayOnWebsiteChange(
  promotionAppId: number | string,
  requestBody: Partial<PromotionApplicationBody>
) {
  return async () => {
    dispatch(slice.actions.setLoadingDisplayOnWebsite(true));
    try {
      const { data } = await promotionAPI.updatePromotionApplications(promotionAppId, requestBody);
      dispatch(slice.actions.updateDisplayOnWebsite(data.explicit));
    } catch (error) {}
    dispatch(slice.actions.setLoadingDisplayOnWebsite(false));
  };
}

export function onPromotionStackingChange(
  promotionId: number | string,
  apply_other_discounts: Partial<PromotionBody>
) {
  return async () => {
    dispatch(slice.actions.setLoadingStacking(true));
    try {
      const { data } = await promotionAPI.updatePromotion(promotionId, apply_other_discounts);
      dispatch(slice.actions.toggleStacking(data.promotion.apply_other_discounts));
    } catch (error) {}
    dispatch(slice.actions.setLoadingStacking(false));
  };
}

export function updatePromotion(
  promotionId: number | string,
  requestBody: Partial<Promotion>,
  setLoading?: (state: boolean) => void,
  callback?: (response: any, succeed: boolean) => void,
) {
  return async () => {
    setLoading?.(true);
    if (promotionId) {
      try {
      const { data } = await promotionAPI.updatePromotion(promotionId, requestBody);
        callback?.(data, true);
      } catch (error) {
        callback?.(error, false);
      }
      setLoading?.(false);
    }
  };
}
