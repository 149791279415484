// @mui
import CardHeader, { cardHeaderClasses } from '@mui/material/CardHeader';
import { styled } from "@mui/material/styles";

export default styled(CardHeader)(({ theme }) => ({
  [`&.${cardHeaderClasses.root}`]: {
    height: theme.spacing(7),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [`& .${cardHeaderClasses.content}`]: {
      display: 'flex',
      flexDirection: 'row',
      [`& .${cardHeaderClasses.title}`]: {
        ...theme.typography.subtitle1,
      },
      [`& .${cardHeaderClasses.subheader}`]: {
        marginLeft: theme.spacing(1),
      }
    },
    [`& .${cardHeaderClasses.action}`]: {
      alignSelf: 'auto',
    },
  },
}));
