import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompletePropsSizeOverrides,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib';
import { OverridableStringUnion } from '@mui/types';
import Iconify from './Iconify';
import useLocales from 'src/hooks/useLocales'
import boldPartOfText from 'src/utils/boldPartOfText';
import { generateUniqSerial } from 'src/utils/generateUniqSerial';

const ChipStyle = styled(Chip)(({ theme, color }) => ({
  margin: '0px !important',
  ...(color !== 'default' && { borderColor: 'initial' }),
  '& .MuiChip-label': {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
const ListItemStyle = styled(ListItem)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "normal !important",
  '&:hover': {
    backgroundColor: theme.palette.grey[500_8],
    '&[aria-selected="true"]': {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
  },
}));

interface CommonProps extends Partial<AutocompleteProps<any, boolean, boolean, boolean>> {
  withCheckBox?: boolean;
  getOptionsRequest: (queryStrings: { [key: string]: any }) => Promise<any>;
  overrideOptions?: (options: any[], response: any, page: number) => any[];
  onChange?: (event?: SyntheticEvent<Element, Event>, value?: any, reason?: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, newValue?: any,) => void;
  getOptionTitle?: (option: any) => string;
  getOptionSubtitle?: (option: any) => string;
  getOptionCaption?: (option: any) => string;
  optionIsSelected?: (option: any) => boolean;
  removeSelectedOption?: (option: any) => any[];
  getOptionsRequestCallback?: (options: any[]) => void;
  getChipColor?: (chip) => "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
  getChipLabel?: (chip) => string;
  clearOptionsDipendency?: any;
  inputSize?: OverridableStringUnion<'small' | 'medium', AutocompletePropsSizeOverrides>;
  defaultInputValue?: string;
  scrollThreshold?: number;
  other?: any,
}
interface PropsWithoutRenderInput extends CommonProps {
  renderInput?: undefined;
};
interface PropsWithRenderInput extends CommonProps {
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
  inputValue: string;
};

export type PaginatedAutocompleteProps = PropsWithRenderInput | PropsWithoutRenderInput;

const PaginatedAutocomplete: FC<PaginatedAutocompleteProps> = ({
  value: autocompleteValue,
  multiple,
  inputValue,
  withCheckBox,
  getOptionsRequest,
  overrideOptions,
  onChange,
  getOptionTitle,
  getOptionSubtitle,
  getOptionCaption,
  optionIsSelected,
  removeSelectedOption,
  getOptionsRequestCallback,
  getChipColor,
  getChipLabel,
  inputSize = "medium",
  clearOptionsDipendency,
  defaultInputValue = "",
  scrollThreshold = 0,
  ...other
}) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const [isLoading, setIsLoading] = useState(false);
  const [thereIsMoreOptions, setThereIsMoreOptions] = useState(true);
  const [textFieldValue, setTextFieldValue] = useState(defaultInputValue);
  const [options, setOptions] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const key = inputValue || textFieldValue;

  const defaultIsSelected = (option) => multiple ? autocompleteValue.some((v) => v === option) : autocompleteValue === option

  const updateOptionsList = useDebouncedCallback((page: number) => {
    setIsLoading(true);
    getOptionsRequest({ key, page })
      .then((response) => {
        if (!response.data || response.data.length === 0) {
          setThereIsMoreOptions(false);
          if(response.data.length === 0 && page === 0) {
            setOptions([]);  
          }
        } else {
          const overriddenOptions = overrideOptions ? overrideOptions(options, response, page) : null;
          const _options = overriddenOptions || (page === 0 ? response.data : [...options, ...response.data]);
          getOptionsRequestCallback && getOptionsRequestCallback(_options);
          setOptions(_options);
          setPage(page + 1);
        }
      }).finally(() => setIsLoading(false));
    },
    300,
  );

  useEffect(() => {
    setPage(0);
    setOptions([]);
    setIsLoading(true);
    setThereIsMoreOptions(true);
  }, [key, clearOptionsDipendency]);
  useEffect(() => {
    if (thereIsMoreOptions && options.length === 0) {
      updateOptionsList(page);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, inputValue]);

  const createValue = (value: string) => ({
    type: 'custom',
    content: value,
  });
  const onAutocompleteChange = (
    event: SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<any> | undefined
  ) => {
    let newValue = undefined;
    if (!multiple || reason !== 'selectOption') {
      setTextFieldValue('');
    }
    if (multiple) {
      if (reason === 'selectOption') {
        const lastValue = value[value.length - 1];
        const isSelected = optionIsSelected ? optionIsSelected(lastValue) : defaultIsSelected(lastValue);
        if (isSelected) {
          value = removeSelectedOption && removeSelectedOption(lastValue);
        }
        newValue = lastValue;
      }
      if (reason === 'createOption' || (reason === 'blur' && key)) {
        const poppedValue = value.pop();
        const newValue = createValue(reason === 'blur' ? key : poppedValue);
        value.push(newValue);
      }
      onChange && onChange(event, value, reason, details, newValue);
      return value;
    }
    onChange && onChange(event, value, reason, details);
  };

  const onInputChange = (e) => setTextFieldValue(e.target.value);

  return (
    <Autocomplete
      multiple={multiple}
      
      options={options}
      loading={isLoading}
      value={autocompleteValue}
      onChange={onAutocompleteChange}
      getLimitTagsText={(amount) => <Button variant="outlined" color="primary" size="small">{`${amount} ${translate('components.more')}`}</Button>}
      getOptionLabel={(option) => multiple ? '' : option}
      filterOptions={(options) => options}
      popupIcon={<Iconify icon="ic:round-expand-more" />}
      ListboxProps={{
        role: "list-box",
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const isEndOfList = listboxNode.scrollTop + listboxNode.clientHeight <= (listboxNode.scrollHeight - scrollThreshold);
          if (thereIsMoreOptions && isEndOfList && !isLoading) {
            updateOptionsList(page);
          }
        }
      }}
      renderOption={(props, option, { selected }) => {
        const title = boldPartOfText(getOptionTitle ? getOptionTitle(option) : option, key);
        const subtitle = boldPartOfText(getOptionSubtitle ? getOptionSubtitle(option) : '', key);
        const caption = getOptionCaption ? getOptionCaption(option) : '';
        const isSelected = optionIsSelected ? optionIsSelected(option) : defaultIsSelected(option);
        return (
          <ListItemStyle
            {...props}
            key={generateUniqSerial()}
            aria-selected={isSelected}
          >
            <Stack direction="row" spacing={1.5} alignItems="center">
              {withCheckBox && <Checkbox checked={selected || isSelected} />}
              <Stack>
                <Box display="flex" justifyContent="space-between">
                  <Box
                    component={Typography}
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  {caption && (
                    <Typography
                      variant="caption"
                      color={theme.palette.text.secondary}
                    >
                      {caption}
                    </Typography>
                  )}
                </Box>
                {subtitle && (
                  <Box
                    component={Typography}
                    variant="caption"
                    color={theme.palette.text.secondary}
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                  />
                )}
              </Stack>
            </Stack>
          </ListItemStyle>
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((chip, index) => (
          <ChipStyle
            {...getTagProps({ index })}
            key={getTagProps({ index }).key}
            variant={chip.type === 'custom' ? 'outlined' : 'filled'}
            color={getChipColor ? getChipColor(chip) : 'primary'}
            label={getChipLabel ? getChipLabel(chip) : (chip.type ? chip.content : chip)}
            size="small"
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size={inputSize}
          value={textFieldValue}
          onChange={onInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && (<CircularProgress color="inherit" size={20} />)}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onClose={(event, reason) => {
        if (textFieldValue) setTextFieldValue('');
        other.onClose?.(event, reason);
      }}
      {...other}
    />
  );
};

export default PaginatedAutocomplete