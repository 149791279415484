import React from 'react';
// @mui
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import {
  BOOKING_STATUS_CANCELED,
  BOOKING_STATUS_SCHEDULED_FOR_CANCELLATION,
} from 'src/utils/booking';
// api
import PropertyApi from 'src/api/property';
// components
import Iconify from 'src/components/Iconify';
// sections
import CustomComponentTemplate from 'src/sections/wizard/listing/components/CustomComponentTemplate';

const BookingCancelForm = React.forwardRef(
  (
    props: {
      action?: string;
      bookingId: number;
      bookingStatus: string;
      bookingSource: string;
      bookingChannel: { id: number; name: string; canConnectToChannel: boolean };
      bookingCurrency: string;
      bookingCurrencySymbol: string;
      onError;
      onFinish;
    },
    ref
  ) => {
    const { translate } = useLocales(['calendar', 'booking']);
    const [radioValue, setRadioValue] = React.useState<string>(props.action ?? 'Cancel');
    const [cancellationReason, setCancellationReason] = React.useState<string>('');
    const [channelCommission, setChannelCommission] = React.useState<any>('');
    const [channelCommissionError, setChannelCommissionError] = React.useState<boolean>(false);

    const shouldCancelledDisable = props.bookingStatus === BOOKING_STATUS_CANCELED;
    const shouldScheduleDisable = props.bookingStatus === BOOKING_STATUS_SCHEDULED_FOR_CANCELLATION;

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRadioValue((event.target as HTMLInputElement).value);
    };

    React.useImperativeHandle(ref, () => ({
      submit() {
        const CCError = parseInt(channelCommission) < 0;
        setChannelCommissionError(CCError);

        if (CCError) {
          props.onError(null);
          return;
        }

        PropertyApi.changeBookingStatus({
          booking_id: props.bookingId,
          booking_status: radioValue === 'Cancel' ? 'Cancelled' : 'Scheduled for Cancellation',
          cancellation_reason: cancellationReason,
          cancellation_fee_value:
            channelCommission && channelCommission !== '' && !isNaN(channelCommission)
              ? channelCommission
              : '0.00',
        })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              props.onFinish(
                radioValue === 'Cancel'
                  ? translate('cancel.success_cancel')
                  : translate('cancel.success_scheduled')
              );
            }
          })
          .catch((err) => {
            props.onError(err.data);
          });
      },
    }));

    return (
      <Box>
        {props.bookingSource === 'Channel Manager' || props.bookingChannel.canConnectToChannel ? (
          <>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="Cancel"
                  control={<Radio />}
                  label={
                    <Typography
                      variant={'body1'}
                      fontWeight={'fontWeightSemiBold'}
                      color={shouldCancelledDisable ? 'text.disabled' : 'text.primary'}
                    >
                      {translate('cancel.cancel_booking')}
                    </Typography>
                  }
                  disabled={shouldCancelledDisable}
                />
                <FormControlLabel
                  value="Schedule"
                  control={<Radio />}
                  label={
                    <Typography
                      variant={'body1'}
                      fontWeight={'fontWeightSemiBold'}
                      color={shouldScheduleDisable ? 'text.disabled' : 'text.primary'}
                    >
                      {translate('cancel.schedule_for_cancellation')}
                    </Typography>
                  }
                  disabled={shouldScheduleDisable}
                />
              </RadioGroup>
            </FormControl>
            <Divider sx={{ py: 0.5 }} />
            {radioValue === 'Cancel' ? (
              <FormHelperText sx={{ mt: 2 }}>
                {translate('cancel.note_cancel', { channelName: props.bookingChannel.name })}
              </FormHelperText>
            ) : (
              <>
                <FormHelperText sx={{ mt: 2 }}>
                  {translate('cancel.note_schedule', { channelName: props.bookingChannel.name })}
                  <Tooltip
                    arrow
                    title={
                      translate('cancel.schedule_tooltip', {
                        channelName: props.bookingChannel.name,
                      }) as string
                    }
                    placement="top"
                  >
                    <span>
                      <Iconify
                        icon={'mdi:information-outline'}
                        color={'grey.600'}
                        width={16}
                        height={18}
                        sx={{
                          ml: 0.75,
                          mb: -0.75,
                        }}
                      />
                    </span>
                  </Tooltip>
                </FormHelperText>
              </>
            )}
          </>
        ) : (
          <FormHelperText>{translate('booking.general.cancel_note_direct')}</FormHelperText>
        )}
        <CustomComponentTemplate
          label={translate('cancel.cancellation_reason') as string}
          sx={{ marginTop: 2 }}
          optional
        >
          <TextField
            variant="outlined"
            multiline
            value={cancellationReason}
            fullWidth={true}
            minRows={4}
            onChange={(e) => setCancellationReason(e.target.value)}
          />
        </CustomComponentTemplate>

        <CustomComponentTemplate
          label={translate('cancel.channel_commission')}
          sx={{ marginTop: 2 }}
          optional
        >
          <FormControl fullWidth>
            <OutlinedInput
              type={'number'}
              value={channelCommission}
              onChange={(e) => {
                setChannelCommission(e.target.value.replace(/^[-+.]/, ''));
                setChannelCommissionError(false);
              }}
              onBlur={() => setChannelCommission(parseFloat(channelCommission).toFixed(2))}
              error={channelCommissionError}
              startAdornment={
                <InputAdornment position="start">{props.bookingCurrencySymbol}</InputAdornment>
              }
            />
          </FormControl>
        </CustomComponentTemplate>
      </Box>
    );
  }
);

export default BookingCancelForm;
