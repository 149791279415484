import { isArray } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
// axios
import { AxiosResponse } from 'axios';
// api
import commonAPI from 'src/api/api';
import filterAPI from 'src/api/filter';
import BookingApi from 'src/api/booking';
import automatedMessageAPI from 'src/api/automated-message';
// redux
import { dispatch } from 'src/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import { VariableApiType } from 'src/@types/common';
import {
  Price,
  Invoice,
  GroupBooking,
  BookingPayment,
  ProceedPayment,
  OverPaymentMethod,
} from 'src/@types/booking';
// utils
import { provideVariables } from 'src/utils/automatedMessage';
import {
  PAYMENT_METHOD_STRIPE,
  agreementNormalizer,
  invoiceNormalizer,
  bookingNormalizer,
  groupBookingsNormalizer,
  bookingGuestNormalizer,
  expenseNormalizer,
  InstallmentNormalizer,
  propertyNormalizer,
  channelNormalizer,
  corporationNormalizer,
  checkInOutConfigNormalizer,
  conversationNormalizer,
  confirmationSettingNormalizer,
  ratePlanNormalizer,
  signableEnvelopNormalizer,
  bookingPaymentNormalizer,
  guestNormalizer,
  taxRateNormalizer,
  bedConfigNormalizer,
  proceedPaymentNormalizer,
  enableDisableAutomatedMessageNormalizer,
  tagNormalizer,
  internalNoteNormalizer,
} from 'src/utils/booking';
// sections
import { BWGuest } from 'src/sections/wizard/booking/types/BookingWizardType';

const initialState: any = {
  tabs: ['overview', 'details', 'guests', 'finance', 'messages', 'operations'],
  error: null,
  isLoading: false,
  currentTab: null,
  drawers: {
    manualPayment: {
      stripeDrawerOpen: false,
      sagePayDrawerOpen: false,
      worldPayDrawerOpen: false,
      paypalDrawerOpen: false,
    },
    invoice: {
      activeInvoiceId: null,
      invoiceEmailDrawerOpen: false,
      recurringInvoiceDialogBox: false,
    },
    price: {
      activeDiscountId: null,
      activeDiscountType: null,
      voidDiscountDialogOpen: false,
      priceBreakdownExpand: false,
      editAccommodationDrawerOpen: false,
    },
    payment: {
      paymentReminderOpen: false,
      generatePaymentLinkOpen: false,
      paymentLinkSettingsOpen: false,
      chargeVccDrawerOpen: false,
    },
    expenses: {
      activeExpenseId: null,
      createExpenseDrawerOpen: false,
      editExpenseDrawerOpen: false,
      editExpenseDefaultValue: null,
      deleteExpenseDialogOpen: false,
    },
    deposit: {
      activeDepositId: null,
      securityDepositType: null,
      voidDepositDialogOpen: false,
      confirmDepositDialogOpen: false,
      logCaptureSecurityDepositOpen: false,
      depositPaymentDrawerOpen: false,
    },
    refund: {
      deleteRefundDialogOpen: false,
      addCompensationDrawerOpen: false,
      editCompensationDrawerOpen: false,
      payBack: {
        payBackOpen: false,
        payBackDefaultValue: {
          paymentId: null,
          hasPayment: false,
          paymentMethod: '',
          refundAmount: null,
          refundReason: '',
        },
      },
      activeRefundOrderLineId: null,
    },
    orderLine: {
      activeOrderLineId: null,
      activeOrderLineType: null,
      addEditOrderLineDrawerOpen: false,
      disapproveOrderLineDialogOpen: false,
    },
    penaltyOrderLine: {
      activeOrderLineId: null,
      editPenaltyDrawerOpen: false,
      deletePenaltyDialogOpen: false,
    },
    logPayment: {
      logPaymentOpen: false,
      logPaymentValue: {
        bookingId: null,
        paymentId: null,
        amount: 0,
        currency: '',
        date: new Date(),
        time: new Date(),
        paymentType: 'Bank Transfer',
        reference: '',
        description: '',
        transactionCostPurchasePriceValue: 0,
        transactionCostPurchasePriceCurrency: '',
        transactionCostSalesPriceValue: 0,
        currencyConversionFeePurchasePriceValue: 0,
        currencyConversionFeePurchasePriceCurrency: '',
        currencyConversionFeeSalesPriceCurrency: 0,
      },
    },
    logSecurityDeposit: {
      logSecurityDepositOpen: false,
      logSecurityDepositValue: {
        bookingId: null,
        date: new Date(),
        time: new Date(),
        paymentType: 'Bank Transfer',
        reference: '',
        description: '',
        currency: '',
        amount: 0,
        transactionCostPurchasePriceCurrency: '',
        transactionCostPurchasePriceValue: 0,
        transactionCostSalesPriceValue: 0,
        currencyConversionFeePurchasePriceCurrency: '',
        currencyConversionFeePurchasePriceValue: 0,
        currencyConversionFeeSalesPriceValue: 0,
      },
    },
    attachment: {
      activeAttachmentFileId: null,
      deleteAttachmentFileDialogOpen: false,
    },
    changeDates: {
      changeDatesDrawerOpen: false,
    },
    changeLocation: {
      changeLocationDrawerOpen: false,
      changeLocationDefaultValue: null,
    },
    addGuest: {
      addGuestDrawerOpen: false,
      addGuestDefaultValue: null,
    },
    changeLeadGuestDrawer: {
      open: false,
    },
  },
  dialogs: {
    refund: {
      proceedPayment: {
        proceedPaymentOpen: false,
        proceedPaymentDefaultValue: {
          orderLineId: null,
        },
      },
    },
    messages: {
      notificationActiveId: null,
      automatedMessageActiveId: null,
      enableAllAutomatedMessagesDialogOpen: false,
      disableAllAutomatedMessagesDialogOpen: false,
      enableAutomatedMessageDialogOpen: false,
      disableAutomatedMessageDialogOpen: false,
      apiLearnMoreDialogOpen: false,
      sendResendNotificationDialog: {
        open: false,
        status: null,
      },
      noEmailAddressDialogOpen: false,
      previewAutomatedMessageDialogOpen: false,
    },
    detailsUpdated: {
      detailsUpdatedOpen: false,
      bookingId: null,
    },
    channelBookingUpdated: {
      channelBookingUpdatedDialogOpen: false,
      channelBookingUpdatedDialogDefaultValues: null,
    },
    finaliseBooking: {
      open: false,
      loading: false,
    },
    undoFinaliseBooking: {
      open: false,
      loading: false,
    },
    markFinaliseBookingOpen: false,
    unmarkFinaliseBookingOpen: false,
    changesWontApplyToChannel: {
      open: false,
      channelName: '',
      onProceed: () => {},
    },
  },
  booking: null,
  property: null,
  relatedBookings: [],
  agreementTemplate: null,
  agreement: null,
  signableAgreement: null,
  bookingGuests: [],
  bookingGuestInvoices: [],
  expenses: [],
  investor: null,
  refund: null,
  installments: [],
  unifiedInboxConversation: null,
  directChannels: [],
  bookingAction: null,
  deposit: null,
  confirmationSettings: [],
  bookingRatePlan: null,
  checkInOutConfig: null,
  bookingPayments: [],
  messageVariables: {
    headerVariables: [],
    bodyVariables: [],
    footerVariables: [],
  },
  automatedMessageNotifications: [],
  bookingTaxRate: null,
  bedConfigs: null,
  paymentDetailsExpand: false,
  shouldScrollToPaymentDetails: false,
  bookingOrderBy: null,
  bookingOrderByCorporation: null,
  automatedMessage: {
    disabledAutomatedMessages: [],
    scheduledAutomatedMessages: [],
    potentialAutomatedMessages: [],
  },
  bookingCheckInReadyStatus: [],
};

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // CURRENT TAB
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },

    // GET BOOKING
    getBookingSuccess(state, action) {
      state.isLoading = false;
      state.booking = action.payload;
    },

    // GET PROPERTY
    getPropertySuccess(state, action) {
      state.isLoading = false;
      state.property = action.payload;
    },

    // GET RELATED BOOKINGS
    getRelatedBookingsSuccess(state, action) {
      state.isLoading = false;
      state.relatedBookings = action.payload;
    },

    getBookingTotalCreditSuccess(state, action) {
      state.isLoading = false;
      state.booking.totalCredit = action.payload;
    },

    getAgreementTemplateSuccess(state, action) {
      state.isLoading = false;
      state.agreementTemplate = action.payload;
    },

    getAgreementSuccess(state, action) {
      state.isLoading = false;
      state.agreement = action.payload;
    },

    getSignableAgreementSuccess(state, action) {
      state.isLoading = false;
      state.signableAgreement = action.payload;
    },

    getBookingGuestsSuccess(state, action) {
      state.isLoading = false;
      state.bookingGuests = action.payload.map((guest) => bookingGuestNormalizer(guest));
    },

    getBookingGuestInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.bookingGuestInvoices = action.payload;
    },

    getBookingExpensesSuccess(state, action) {
      state.isLoading = false;
      state.expenses = action.payload;
    },

    getBookingInvestorSuccess(state, action) {
      state.isLoading = false;
      state.investor = action.payload;
    },

    getBookingRefundSuccess(state, action) {
      state.isLoading = false;
      state.refund = action.payload;
    },

    getInstallmentsSuccess(state, action) {
      state.isLoading = false;
      state.installments = action.payload.map((installment) => InstallmentNormalizer(installment));
    },

    setUnifiedInboxConversationSuccess(state, action) {
      state.isLoading = false;
      state.unifiedInboxConversation = action.payload;
    },

    setDirectChannelsSuccess(state, action) {
      state.isLoading = false;
      state.directChannels = action.payload;
    },

    setBookingTagsSuccess(state, action) {
      state.isLoading = false;
      state.booking.tags = action.payload;
    },

    setGuestTagsSuccess(state, action) {
      state.isLoading = false;
      state.booking.guest.tags = action.payload;
    },

    setPersonBookingTagsSuccess(state, action) {
      state.isLoading = false;
      state.booking.personBooking.tags = action.payload;
    },

    setCorporationTagsSuccess(state, action) {
      state.isLoading = false;
      state.booking.corporation.tags = action.payload;
    },

    setBookingGuestTagsSuccess(state, action) {
      state.isLoading = false;
      const index = state.bookingGuests.findIndex(
        (bookingGuest) => bookingGuest.guest.id === action.payload.id
      );
      if (index >= 0) {
        state.bookingGuests[index].guest.tags = action.payload.data;
      }
    },

    setChannelSuccess(state, action) {
      state.isLoading = false;
      state.booking.channel = action.payload;
    },

    setUnitSuccess(state, action) {
      state.isLoading = false;
      state.booking.unitId = action.payload.id;
      state.booking.unitName = action.payload.name;
    },

    setLockStatusSuccess(state, action) {
      state.isLoading = false;
      state.booking.isLocked = action.payload;
    },

    setAccessCodeSuccess(state, action) {
      state.isLoading = false;
      state.booking.accessCode = action.payload;
    },

    setCheckInTypeSuccess(state, action) {
      state.isLoading = false;
      state.booking.checkInType = action.payload;
    },

    setCorporationSuccess(state, action) {
      state.isLoading = false;
      state.booking.corporation = action.payload;
    },

    setChannelReferenceSuccess(state, action) {
      state.booking.bookingReference = action.payload;
    },

    setBookingAction(state, action) {
      state.bookingAction = action.payload;
    },

    setInternalNoteSuccess(state, action) {
      state.isLoading = false;
      state.booking.internalNotes = Array.isArray(state.booking.internalNotes)
        ? [action.payload, ...state.booking.internalNotes]
        : [action.payload];
    },

    editInternalNoteSuccess(state, action) {
      state.isLoading = false;
      const index = state.booking.internalNotes.findIndex((note) => note.id === action.payload.id);
      state.booking.internalNotes.splice(index, 1, action.payload);
    },

    deleteInternalNoteSuccess(state, action) {
      state.isLoading = false;
      state.booking.internalNotes = state.booking.internalNotes.filter(
        (internalNote) => internalNote.id !== action.payload
      );
    },

    getBookingDepositsSuccess(state, action) {
      state.isLoading = false;
      state.deposit = action.payload;
    },

    getCheckInOutConfigSuccess(state, action) {
      state.isLoading = false;
      state.checkInOutConfig = action.payload;
    },

    setConfirmationSettingsSuccess(state, action) {
      state.isLoading = false;
      state.confirmationSettings = action.payload.map((obj) => confirmationSettingNormalizer(obj));
    },

    setGuestBirthdaySuccess(state, action) {
      state.isLoading = false;
      state.booking.guest.dateOfBirth = action.payload;
    },

    setGuestCitySuccess(state, action) {
      state.isLoading = false;
      state.booking.guest.address.city = action.payload;
    },

    setBookingPurposeSuccess(state, action) {
      state.isLoading = false;
      state.booking.bookingPurpose = action.payload;
    },

    setGuestVerificationSuccess(state, action) {
      state.isLoading = false;
      state.booking.guest.idVerified = action.payload;
      state.booking.isGuestVerified = action.payload;
    },

    setBookingGuestVerificationSuccess(state, action) {
      state.isLoading = false;
      const index = state.bookingGuests.findIndex(
        (bookingGuest) => bookingGuest.guest.id === action.payload.id
      );
      if (index >= 0) {
        state.bookingGuests[index].guest.idVerified = action.payload.value;
      }
    },

    editBookingGuest(state, action) {
      state.isLoading = false;
      const index = state.bookingGuests.findIndex(
        (bookingGuest) => bookingGuest.guest.id === +action.payload.id
      );
      if (index >= 0) {
        state.bookingGuests[index].guest = action.payload;
      }
    },

    addBookingGuest(state, action) {
      state.isLoading = false;
      state.bookingGuests = action.payload;
    },

    setGuest(state, action) {
      state.isLoading = false;
      state.booking.guest = action.payload;
    },

    deleteBookingGuestSuccess(state, action) {
      state.isLoading = false;
      state.bookingGuests = state.bookingGuests.filter(
        (bookingGuest) => bookingGuest.id !== action.payload
      );
    },

    deleteBookingTag(state, action) {
      state.isLoading = false;
      state.booking.tags = state.booking.tags.filter((tag) => tag.id !== action.payload);
    },

    deleteGuestTag(state, action) {
      state.isLoading = false;
      state.booking.guest.tags = state.booking.guest.tags.filter(
        (tag) => tag.id !== action.payload
      );
    },

    deletePersonBookingTag(state, action) {
      state.isLoading = false;
      state.booking.personBooking.tags = state.booking.personBooking.tags.filter(
        (tag) => tag.id !== action.payload
      );
    },

    deleteCorporationTag(state, action) {
      state.isLoading = false;
      state.booking.corporation.tags = state.booking.corporation.tags.filter(
        (tag) => tag.id !== action.payload
      );
    },

    deleteBookingGuestTag(state, action) {
      state.isLoading = false;
      const index = state.bookingGuests.findIndex(
        (bookingGuest) => bookingGuest.guest.id === action.payload.guestId
      );
      if (index >= 0) {
        state.bookingGuests[index].guest.tags = state.bookingGuests[index].guest.tags.filter(
          (tag) => tag.id !== action.payload.tagId
        );
      }
    },

    setBookingRatePlanSuccess(state, action) {
      state.isLoading = false;
      state.bookingRatePlan = action.payload;
    },

    setCheckInOutConfigSuccess(state, action) {
      state.isLoading = false;
      state.checkInOutConfig = action.payload;
    },

    getBookingPaymentsSuccess(state, action) {
      state.isLoading = false;
      state.bookingPayments = action.payload;
    },

    setBookingSecurityDepositType(state, action) {
      state.drawers.deposit.securityDepositType = action.payload;
    },

    setIncludeDepositInInvoice(state, action) {
      state.booking.includeSecurityDepositInInvoice = action.payload;
    },

    setShowPaymentMethodOnInvoice(state, action) {
      state.bookingGuestInvoices[0].showPaymentMethodOnInvoice = action.payload;
    },

    setLogCaptureSecurityDepositOpen(state, action) {
      state.drawers.deposit.logCaptureSecurityDepositOpen = action.payload;
    },

    setActiveExpense(state, action) {
      state.drawers.expenses.activeExpenseId = action.payload;
    },

    setExpenseCreateDrawerOpen(state, action) {
      state.drawers.expenses.createExpenseDrawerOpen = action.payload;
    },

    setExpenseEditDrawerOpen(state, action) {
      state.drawers.expenses.editExpenseDrawerOpen = action.payload;
    },

    setInvoiceEmailDrawerOpen(state, action) {
      state.drawers.invoice.invoiceEmailDrawerOpen = action.payload;
    },

    setRecurringInvoiceDialogBox(state, action) {
      state.drawers.invoice.recurringInvoiceDialogBox = action.payload;
    },

    setChargeVccDrawerOpen(state, action) {
      state.drawers.payment.chargeVccDrawerOpen = action.payload;
    },

    setExpenseEditDrawerDefaultValue(state, action) {
      state.drawers.expenses.editExpenseDefaultValue = action.payload;
    },

    updateExpense(state, action) {
      state.isLoading = false;
      const foundIndex = state.expenses.findIndex(
        (item) => item.id === state.drawers.expenses.activeExpenseId
      );
      state.expenses[foundIndex] = {
        ...state.expenses[foundIndex],
        amount: {
          ...state.expenses[foundIndex].amount,
          value: action.payload,
          baseValue: action.payload,
        },
      };
    },

    updateSecurityDepositSuccess(state, action: PayloadAction<{ amount: Price; status: string }>) {
      state.isLoading = false;
      state.deposit = {
        ...state.deposit,
        depositAmount: action.payload.amount,
        depositStatus: action.payload.status,
      };
    },

    setExpenseDeleteDialogOpen(state, action) {
      state.drawers.expenses.deleteExpenseDialogOpen = action.payload;
    },

    deleteExpense(state, action) {
      state.isLoading = false;
      state.expenses = state.expenses.filter((item) => item.id !== action.payload);
    },

    setNumberOfAdultGuestsSuccess(state, action) {
      state.isLoading = false;
      state.booking.numberOfAdultGuests = action.payload;
    },

    setNumberOfChildGuestsSuccess(state, action) {
      state.isLoading = false;
      state.booking.numberOfChildGuests = action.payload;
    },

    setArrivalTimeSuccess(state, action) {
      state.isLoading = false;
      state.booking.arrivalTime = action.payload;
    },

    setDepartureTimeSuccess(state, action) {
      state.isLoading = false;
      state.booking.departureTime = action.payload;
    },

    approveOrderLine(state, action) {
      state.isLoading = false;
      const index = state.booking.addons.findIndex((addon) => addon.orderLineId === action.payload);
      if (index >= 0) {
        state.booking.addons[index].approve = true;
      }
    },

    disapproveOrderLine(state, action) {
      state.isLoading = false;
      const index = state.booking.addons.findIndex((addon) => addon.orderLineId === action.payload);
      if (index >= 0) {
        state.booking.addons[index].approve = false;
      }
    },

    voidDiscount(state, action) {
      state.isLoading = false;
      const index = state.booking.discounts.findIndex((item) => item.id === action.payload);
      if (index >= 0) {
        state.booking.discounts[index].status = 'void';
      }
    },

    setPersonBooking(state, action) {
      state.isLoading = false;
      state.booking.personBooking = action.payload;
    },

    setGeneratePaymentLinkOpen(state, action) {
      state.drawers.payment.generatePaymentLinkOpen = action.payload;
    },

    setLogPaymentOpen(state, action) {
      state.drawers.logPayment.logPaymentOpen = action.payload.open;
      state.drawers.logPayment.logPaymentValue = action.payload.defaultValue;
    },

    setLogPaymentClose(state) {
      state.drawers.logPayment.logPaymentOpen = false;
    },

    setPayBackDrawerOpen(state, action) {
      state.drawers.refund.payBack.payBackOpen = action.payload.open;
      state.drawers.refund.payBack.payBackDefaultValue = action.payload.defaultValue;
    },

    setPayBackDrawerClose(state) {
      state.drawers.refund.payBack.payBackOpen = false;
    },

    setProceedPaymentDialogOpen(state, action) {
      state.dialogs.refund.proceedPayment.proceedPaymentOpen = action.payload.open;
      state.dialogs.refund.proceedPayment.proceedPaymentDefaultValue = action.payload.defaultValue;
    },

    setProceedPaymentDialogClose(state) {
      state.dialogs.refund.proceedPayment.proceedPaymentOpen = false;
    },

    setLogSecurityDepositOpen(state, action) {
      state.drawers.logSecurityDeposit.logSecurityDepositOpen = action.payload.open;
      state.drawers.logSecurityDeposit.logSecurityDepositValue = action.payload.defaultValue;
    },
    setManualPaymentStripeDrawerOpen(state, action) {
      state.drawers.manualPayment.stripeDrawerOpen = action.payload;
    },
    setManualPaymentSagePayDrawerOpen(state, action) {
      state.drawers.manualPayment.sagePayDrawerOpen = action.payload;
    },
    setManualPaymentWorldPayDrawerOpen(state, action) {
      state.drawers.manualPayment.worldPayDrawerOpen = action.payload;
    },
    setManualPaymentPaypalDrawerOpen(state, action) {
      state.drawers.manualPayment.paypalDrawerOpen = action.payload;
    },

    setLogSecurityDepositClose(state) {
      state.drawers.logSecurityDeposit.logSecurityDepositOpen = false;
    },

    setVoidDepositDialogOpen(state, action) {
      state.drawers.deposit.voidDepositDialogOpen = action.payload;
    },

    setDepositPaymentDrawerOpen(state, action) {
      state.drawers.deposit.depositPaymentDrawerOpen = action.payload;
    },

    setBookingAddonImages(state, action) {
      let { addons } = state.booking;
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        // eslint-disable-next-line array-callback-return
        addons.map((addon, index) => {
          if (+addon.id === +item.id)
            addons[index] = {
              ...addons[index],
              thumbnailLink: item.thumbnail_link,
              imageLink: item.image_link,
            };
        });
      });
      state.booking.addons = addons;
    },

    setActiveOrderLineId(state, action) {
      state.drawers.orderLine.activeOrderLineId = action.payload;
    },

    setActiveRefundOrderLineId(state, action) {
      state.drawers.refund.activeRefundOrderLineId = action.payload;
    },

    setActiveInvoiceId(state, action) {
      state.drawers.invoice.activeInvoiceId = action.payload;
    },

    setActiveOrderLineType(state, action) {
      state.drawers.orderLine.activeOrderLineType = action.payload;
    },

    setAddEditOrderLineDrawerOpen(state, action) {
      state.drawers.orderLine.addEditOrderLineDrawerOpen = action.payload;
    },

    setOrderLineDisapproveDialogOpen(state, action) {
      state.drawers.orderLine.disapproveOrderLineDialogOpen = action.payload;
    },

    setActiveDiscountId(state, action) {
      state.drawers.price.activeDiscountId = action.payload;
    },

    setActiveDiscountType(state, action) {
      state.drawers.price.activeDiscountType = action.payload;
    },

    setDiscountVoidDialogOpen(state, action) {
      state.drawers.price.voidDiscountDialogOpen = action.payload;
    },

    setAccommodationEditDrawerOpen(state, action) {
      state.drawers.price.editAccommodationDrawerOpen = action.payload;
    },

    setDeleteDepositDialogOpen(state, action) {
      state.drawers.deposit.deleteDepositDialogOpen = action.payload;
    },

    setEditPenaltyDrawerOpen(state, action) {
      state.drawers.penaltyOrderLine.editPenaltyDrawerOpen = action.payload;
    },

    setDeletePenaltyDialogOpen(state, action) {
      state.drawers.penaltyOrderLine.deletePenaltyDialogOpen = action.payload;
    },

    setActivePenaltyOrderLine(state, action) {
      state.drawers.penaltyOrderLine.activeOrderLineId = action.payload;
    },

    setActiveDeposit(state, action) {
      state.drawers.deposit.activeDepositId = action.payload;
    },

    setConfirmDepositDialogOpen(state, action) {
      state.drawers.deposit.confirmDepositDialogOpen = action.payload;
    },

    setDeleteRefundDialogOpen(state, action) {
      state.drawers.refund.deleteRefundDialogOpen = action.payload;
    },

    setAddCompensationDrawerOpen(state, action) {
      state.drawers.refund.addCompensationDrawerOpen = action.payload;
    },

    setEditCompensationDrawerOpen(state, action) {
      state.drawers.refund.editCompensationDrawerOpen = action.payload;
    },

    setCheckinInstruction(state, action) {
      state.booking.checkinInstruction = action.payload;
    },

    setCheckoutInstruction(state, action) {
      state.booking.checkoutInstruction = action.payload;
    },

    setGuestAge(state, action) {
      state.isLoading = false;
      const index = state.bookingGuests.findIndex(
        (bookingGuest) => bookingGuest.guest.id === action.payload.id
      );
      if (index >= 0) {
        state.bookingGuests[index].guest.age = action.payload.value;
      }
    },

    setChildAges(state, action) {
      state.isLoading = false;
      state.booking.childAges = action.payload;
    },

    setPaymentReminderOpen(state, action) {
      state.drawers.payment.paymentReminderOpen = action.payload;
    },

    setPaymentLinkSettingsOpen(state, action) {
      state.drawers.payment.paymentLinkSettingsOpen = action.payload;
    },

    deleteAgreement(state, action) {
      state.booking.agreement = null;
      state.agreement = null;
    },

    setMessageVariables(state, action) {
      state.messageVariables = action.payload;
    },

    setAutomatedMessageNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.automatedMessageNotifications = action.payload;
    },

    setDisabledAutomatedMessagesSuccess(state, action) {
      state.isLoading = false;
      state.automatedMessage.disabledAutomatedMessages = action.payload;
    },

    setScheduledAutomatedMessagesSuccess(state, action) {
      state.isLoading = false;
      state.automatedMessage.scheduledAutomatedMessages = action.payload;
    },

    setPotentialAutomatedMessagesSuccess(state, action) {
      state.isLoading = false;
      state.automatedMessage.potentialAutomatedMessages = action.payload;
    },

    setNotificationStatusSuccess(state, action) {
      state.isLoading = false;
      const index = state.automatedMessageNotifications.findIndex(
        (notification) => notification.notification_id === action.payload.id
      );
      if (index >= 0) {
        state.automatedMessageNotifications[index].status = action.payload.status;
      }
    },

    addAttachment(state, action) {
      state.isLoading = false;
      let { bookingAttachments } = state.booking;
      state.booking.bookingAttachments = [...bookingAttachments, ...action.payload];
    },

    deleteAttachment(state, action) {
      state.isLoading = false;
      state.booking.bookingAttachments = state.booking.bookingAttachments.filter(
        (item) => item.attachmentId !== action.payload
      );
    },

    deleteAllAttachment(state) {
      state.isLoading = false;
      state.booking.bookingAttachments = [];
    },

    setPriceBreakdownExpand(state, action) {
      state.drawers.price.priceBreakdownExpand = action.payload;
    },

    setBookingTaxRateSuccess(state, action) {
      state.isLoading = false;
      state.bookingTaxRate = action.payload;
    },

    setBookingBedConfigSuccess(state, action) {
      state.isLoading = false;
      state.bedConfigs = action.payload.map((item) => bedConfigNormalizer(item));
    },

    setBedPrepared(state, action) {
      state.isLoading = false;
      const index = state.bedConfigs.findIndex(
        (bedConfig) => bedConfig.roomID === action.payload.roomId
      );
      if (index >= 0) {
        state.bedConfigs[index].beds[action.payload.bedIndex].prepared = action.payload.prepared;
      }
    },

    setBedZipAndLink(state, action) {
      state.isLoading = false;
      const index = state.bedConfigs.findIndex(
        (bedConfig) => bedConfig.roomID === action.payload.roomId
      );
      if (index >= 0) {
        state.bedConfigs[index].beds[action.payload.bedIndex].zipAndLink = true;
        state.bedConfigs[index].beds[action.payload.bedIndex].zipAndLinkDefaultConfig =
          action.payload.zipAndLinkConfig;
      }
    },

    setPaymentDetailsExpand(state, action) {
      state.isLoading = false;
      state.paymentDetailsExpand = action.payload;
    },

    setShouldScrollToPaymentDetails(state, action) {
      state.isLoading = false;
      state.shouldScrollToPaymentDetails = action.payload;
    },

    setBookingTaxRateAmount(state, action) {
      state.isLoading = false;
      if (action.payload.isAdditional) {
        const componentIndex = state.bookingTaxRate.taxComponents.findIndex((item) =>
          item.additionalTaxes.find((item) => item.id === action.payload.id)
        );
        const additionalIndex = state.bookingTaxRate.taxComponents[
          componentIndex
        ].additionalTaxes.findIndex((item) => item.id === action.payload.id);
        if (componentIndex >= 0 && additionalIndex >= 0) {
          state.bookingTaxRate.taxComponents[componentIndex].additionalTaxes[
            additionalIndex
          ].rateAmount = action.payload.amount;
        }
      } else {
        const componentIndex = state.bookingTaxRate.taxComponents.findIndex(
          (item) => item.id === action.payload.id
        );
        if (componentIndex >= 0) {
          state.bookingTaxRate.taxComponents[componentIndex].rateAmount = action.payload.amount;
        }
      }
    },

    deleteBookingTaxComponent(state, action) {
      state.isLoading = false;
      if (action.payload.isAdditional) {
        const componentIndex = state.bookingTaxRate.taxComponents.findIndex((item) =>
          item.additionalTaxes.find((item) => item.id === action.payload.id)
        );
        if (componentIndex >= 0) {
          state.bookingTaxRate.taxComponents[componentIndex].additionalTaxes =
            state.bookingTaxRate.taxComponents[componentIndex].additionalTaxes.filter(
              (item) => item.id !== action.payload.id
            );
        }
      } else {
        state.bookingTaxRate.taxComponents = state.bookingTaxRate.taxComponents.filter(
          (item) => item.id !== action.payload.id
        );
      }
    },
    setBookingOrderBySuccess(state, action) {
      state.isLoading = false;
      state.bookingOrderBy = action.payload;
    },

    setBookingOrderByCorporationSuccess(state, action) {
      state.isLoading = false;
      state.bookingOrderByCorporation = action.payload;
    },

    setPotentialAutomatedMessageEnable(state, action) {
      const { id, enabled } = action.payload;
      state.isLoading = false;
      let foundMessage = state.automatedMessage.potentialAutomatedMessages.find(
        (message) => message.id === id
      );
      if (!!foundMessage) {
        foundMessage.enabled = enabled;
      }
    },

    setCheckInReadySettingsSuccess(state, action) {
      state.isLoading = false;
      state.bookingCheckInReadyStatus = action.payload;
    },

    setAutomatedMessageActiveId(state, action) {
      state.dialogs.messages.automatedMessageActiveId = action.payload;
    },

    setNotificationActiveId(state, action) {
      state.dialogs.messages.notificationActiveId = action.payload;
    },

    setAPILearnMoreDialogOpen(state, action) {
      state.dialogs.messages.apiLearnMoreDialogOpen = action.payload;
    },

    setEnableAllAutomatedMessagesDialogOpen(state, action) {
      state.dialogs.messages.enableAllAutomatedMessagesDialogOpen = action.payload;
    },

    setDisableAllAutomatedMessagesDialogOpen(state, action) {
      state.dialogs.messages.disableAllAutomatedMessagesDialogOpen = action.payload;
    },

    setEnableAutomatedMessageDialogOpen(state, action) {
      state.dialogs.messages.enableAutomatedMessageDialogOpen = action.payload;
    },

    setDisableAutomatedMessageDialogOpen(state, action) {
      state.dialogs.messages.disableAutomatedMessageDialogOpen = action.payload;
    },

    setSendResendNotificationDialog(state, action) {
      state.dialogs.messages.sendResendNotificationDialog = action.payload;
    },

    setNoEmailAddressDialogOpen(state, action) {
      state.dialogs.messages.noEmailAddressDialogOpen = action.payload;
    },

    setPreviewAutomatedMessageDialogOpen(state, action) {
      state.dialogs.messages.previewAutomatedMessageDialogOpen = action.payload;
    },
    setDetailsUpdatedDialogOpen(state, action) {
      state.dialogs.detailsUpdated.detailsUpdatedOpen = action.payload.open;
      state.dialogs.detailsUpdated.bookingId = action.payload.bookingId;
    },
    setChannelBookingUpdatedDialogOpen(state, action) {
      state.dialogs.channelBookingUpdated.channelBookingUpdatedDialogOpen = action.payload.open;
      state.dialogs.channelBookingUpdated.channelBookingUpdatedDialogDefaultValues =
        action.payload.values;
    },
    setChangeDatesDrawerOpen(state, action) {
      state.drawers.changeDates.changeDatesDrawerOpen = action.payload;
    },
    setChangeLocationDrawerOpen(state, action) {
      state.drawers.changeLocation.changeLocationDrawerOpen = action.payload.open;
      state.drawers.changeLocation.changeLocationDefaultValue = action.payload.defaultValue;
    },
    setAddGuestDrawerOpen(state, action) {
      state.drawers.addGuest.addGuestDrawerOpen = action.payload.open;
      state.drawers.addGuest.addGuestDefaultValue = action.payload.defaultValue;
    },

    setFinaliseBookingDialogOpen(state, action) {
      state.dialogs.finaliseBooking.open = action.payload;
    },

    setUndoFinaliseBookingDialogOpen(state, action) {
      state.dialogs.undoFinaliseBooking.open = action.payload;
    },

    setFinaliseBookingDialogLoading(state, action) {
      state.dialogs.finaliseBooking.loading = action.payload;
    },

    setUndoFinaliseBookingDialogLoading(state, action) {
      state.dialogs.undoFinaliseBooking.loading = action.payload;
    },

    setMarkFinaliseBookingDialogOpen(state, action) {
      state.dialogs.markFinaliseBookingOpen = action.payload;
    },

    setUnmarkFinaliseBookingDialogOpen(state, action) {
      state.dialogs.unmarkFinaliseBookingOpen = action.payload;
    },

    setOpenChangeLeadGuestDrawer(state, action) {
      state.drawers.changeLeadGuestDrawer.open = action.payload;
    },

    setChangesWontApplyToChannelDialog(
      state,
      action: PayloadAction<{ open: boolean; channelName: string; onProceed: VoidFunction }>
    ) {
      const { open, channelName, onProceed } = action.payload;
      state.dialogs.changesWontApplyToChannel.open = open;
      state.dialogs.changesWontApplyToChannel.channelName = channelName;
      state.dialogs.changesWontApplyToChannel.onProceed = onProceed;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCurrentTab,
  setPriceBreakdownExpand,
  setUnitSuccess,
  setLockStatusSuccess,
  setCheckInTypeSuccess,
  setAccessCodeSuccess,
  updateExpense,
  setActiveExpense,
  setExpenseCreateDrawerOpen,
  setExpenseEditDrawerOpen,
  setExpenseEditDrawerDefaultValue,
  setExpenseDeleteDialogOpen,
  deleteExpense,
  setBookingAction,
  setGuest,
  editBookingGuest,
  addBookingGuest,
  deleteBookingTag,
  deleteGuestTag,
  deletePersonBookingTag,
  deleteCorporationTag,
  deleteBookingGuestTag,
  setBookingSecurityDepositType,
  setLogCaptureSecurityDepositOpen,
  approveOrderLine,
  disapproveOrderLine,
  voidDiscount,
  setLogPaymentOpen,
  setLogPaymentClose,
  setLogSecurityDepositOpen,
  setManualPaymentStripeDrawerOpen,
  setManualPaymentSagePayDrawerOpen,
  setManualPaymentWorldPayDrawerOpen,
  setManualPaymentPaypalDrawerOpen,
  setLogSecurityDepositClose,
  setActiveOrderLineId,
  setActiveRefundOrderLineId,
  setActiveInvoiceId,
  setActiveOrderLineType,
  setAddEditOrderLineDrawerOpen,
  setOrderLineDisapproveDialogOpen,
  setActiveDiscountId,
  setActiveDiscountType,
  setDiscountVoidDialogOpen,
  setAccommodationEditDrawerOpen,
  setDeleteDepositDialogOpen,
  setVoidDepositDialogOpen,
  setDepositPaymentDrawerOpen,
  setEditPenaltyDrawerOpen,
  setDeletePenaltyDialogOpen,
  setActivePenaltyOrderLine,
  setActiveDeposit,
  setConfirmDepositDialogOpen,
  setDeleteRefundDialogOpen,
  setAddCompensationDrawerOpen,
  setEditCompensationDrawerOpen,
  setPayBackDrawerOpen,
  setPayBackDrawerClose,
  setProceedPaymentDialogOpen,
  setProceedPaymentDialogClose,
  setCheckinInstruction,
  setCheckoutInstruction,
  setPaymentReminderOpen,
  deleteAgreement,
  setNotificationStatusSuccess,
  setGeneratePaymentLinkOpen,
  addAttachment,
  deleteAttachment,
  deleteAllAttachment,
  setPaymentLinkSettingsOpen,
  setInvoiceEmailDrawerOpen,
  setChargeVccDrawerOpen,
  setBedPrepared,
  setBedZipAndLink,
  setRecurringInvoiceDialogBox,
  setPaymentDetailsExpand,
  setShouldScrollToPaymentDetails,
  setBookingTaxRateAmount,
  deleteBookingTaxComponent,
  setBookingOrderBySuccess,
  setBookingOrderByCorporationSuccess,
  setPotentialAutomatedMessageEnable,
  setNotificationActiveId,
  setAutomatedMessageActiveId,
  setEnableAllAutomatedMessagesDialogOpen,
  setDisableAllAutomatedMessagesDialogOpen,
  setEnableAutomatedMessageDialogOpen,
  setDisableAutomatedMessageDialogOpen,
  setAPILearnMoreDialogOpen,
  setSendResendNotificationDialog,
  setNoEmailAddressDialogOpen,
  setPreviewAutomatedMessageDialogOpen,
  setIncludeDepositInInvoice,
  setShowPaymentMethodOnInvoice,
  setDetailsUpdatedDialogOpen,
  setChannelBookingUpdatedDialogOpen,
  setChangeDatesDrawerOpen,
  setChangeLocationDrawerOpen,
  setAddGuestDrawerOpen,
  setFinaliseBookingDialogOpen,
  setUndoFinaliseBookingDialogOpen,
  setFinaliseBookingDialogLoading,
  setUndoFinaliseBookingDialogLoading,
  setMarkFinaliseBookingDialogOpen,
  setUnmarkFinaliseBookingDialogOpen,
  setOpenChangeLeadGuestDrawer,
  setChangesWontApplyToChannelDialog,
} = slice.actions;

export function getBooking(id: number, user: any) {
  const hasBookingViewAccess = user?.acls.PeymansBookingBundleBooking.VIEW;
  const hasDetailsViewAccess = user?.acls.peyamns_booking_booking_details_view.EXECUTE;
  const hasGuestsViewAccess = user?.acls.PeymansBookingBundleGuest.VIEW;
  const hasFinanceViewAccess = user?.acls.peymans_finanace_fields_show.EXECUTE;
  const hasPaymentViewAccess = user?.acls.PeymansBookingBundlePayment.VIEW;
  const hasMessagesViewAccess = user?.acls.PeymansAutomatedMessageBundleAutomatedMessage.VIEW;
  const hasOperationsViewAccess = user?.acls.PeymansTaskBundleTask.VIEW;
  const hasUnifiedInboxViewAccess = user?.acls.PeymansUnifiedInboxBundleMessage.VIEW;
  const hasPropertyViewAccess = user?.acls.PeymansPropertyBundleProperty.VIEW;

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchBooking(id);
      const booking = bookingNormalizer(response.data);
      dispatch(slice.actions.getBookingSuccess(booking));

      if (hasDetailsViewAccess) {
        const bookingAddonImagesResponse: { data } = await BookingApi.getBookingAddonImages(
          +booking.id
        );
        dispatch(slice.actions.setBookingAddonImages(bookingAddonImagesResponse.data));
        dispatch(getSignableEnvelop(+booking.id));
        if (booking.agreement) dispatch(getAgreement(+booking.agreement));
        if (booking.agreementTemplate) dispatch(getAgreementTemplate(+booking.agreementTemplate));
      }
      if (hasDetailsViewAccess || hasFinanceViewAccess) {
        dispatch(getRelatedBookings(+booking.bookingOrderId, +booking.id));
      }
      if (hasGuestsViewAccess) {
        dispatch(getBookingGuests(+booking.id));
        dispatch(getConfirmationSettings(+booking.brand.id));
      }
      if (hasPaymentViewAccess) {
        dispatch(getBookingPayments(+booking.id));
      }
      if (hasFinanceViewAccess) {
        dispatch(getBookingDeposit(+booking.id));
        dispatch(getBookingGuestInvoices(booking.bookingNo));
        dispatch(getBookingExpenses(+booking.id));
        dispatch(getBookingInvestor(+booking.id));
        dispatch(getBookingRefund(+booking.id));
        dispatch(getInstallments(+booking.id));
        dispatch(getBookingTaxRate(+booking.id));
        if (booking.ratePlanId) dispatch(getBookingRatePlan(+booking.ratePlanId));
      }
      if (hasMessagesViewAccess) {
        //
        dispatch(getAutomatedMessageNotifications(+booking.id));
        dispatch(getPotentialAutomatedMessage(+booking.id));
      }
      if (hasOperationsViewAccess) {
        dispatch(getBookingBedConfig(+booking.id));
      }
      if (hasUnifiedInboxViewAccess) {
        dispatch(getUnifiedInboxConversation(+booking.id));
      }
      if (hasPropertyViewAccess) {
        dispatch(getProperty(+booking.propertyId));
      }
      if (hasBookingViewAccess || hasDetailsViewAccess) {
        if (booking.unitTypeId) dispatch(getBookingCheckInOutConfig(booking.id));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProperty(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchProperty(id);
      dispatch(slice.actions.getPropertySuccess(propertyNormalizer(response.data)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedBookings(id: number, bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const normalizedData: Array<GroupBooking> = [];
      const response: { data } = await BookingApi.fetchGroupBookings(id);
      const filteredBooking = response.data.bookings.filter(
        (item) => bookingId && item.id !== bookingId
      );
      const filteredBookingConfigResponse: { data } = await BookingApi.fetchBookingConfigs(
        filteredBooking.map((item) => item.id).join(',')
      );

      dispatch(slice.actions.setBookingOrderBySuccess(response.data.order_by ?? null));
      if (response.data.order_by_corporation) {
        const corporationResponse = await BookingApi.fetchCorporation(
          +response.data.order_by_corporation.split('/').pop()
        );
        dispatch(
          slice.actions.setBookingOrderByCorporationSuccess(corporationResponse.data ?? null)
        );
      } else {
        dispatch(slice.actions.setBookingOrderByCorporationSuccess(null));
      }

      dispatch(slice.actions.getBookingTotalCreditSuccess(response.data.total_credit ?? undefined));
      for (const booking of filteredBooking) {
        const checkInOutConfig = checkInOutConfigNormalizer(
          filteredBookingConfigResponse.data[booking.id]
        );
        normalizedData.push(groupBookingsNormalizer(booking, checkInOutConfig));
      }
      dispatch(slice.actions.getRelatedBookingsSuccess(normalizedData));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgreementTemplate(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchAgreementTemplate(id);
      dispatch(slice.actions.getAgreementTemplateSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgreement(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchAgreement(id);
      dispatch(slice.actions.getAgreementSuccess(agreementNormalizer(response.data)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSignableEnvelop(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchSignableEnvelopByBooking(id);
      dispatch(
        slice.actions.getSignableAgreementSuccess(
          response.data.length > 0 ? signableEnvelopNormalizer(response.data[0]) : null
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingGuests(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchBookingGuests(id);
      dispatch(slice.actions.getBookingGuestsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingGuestInvoices(bookingNo: string) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const normalizedData: Array<Invoice> = [];
      const invoiceResponse: { data } = await BookingApi.fetchBookingsGuestInvoice(bookingNo);
      if (isArray(invoiceResponse.data) && invoiceResponse.data.length === 1) {
        try {
          const invoiceImage = await BookingApi.getInvoiceImage(invoiceResponse.data[0].id);
          normalizedData.push(invoiceNormalizer(invoiceResponse.data[0], invoiceImage.data));
        } catch (error) {
          normalizedData.push(invoiceNormalizer(invoiceResponse.data[0], null));
          dispatch(slice.actions.hasError(error));
          console.error(error);
        }
      } else if (isArray(invoiceResponse.data)) {
        invoiceResponse.data.map((invoice) =>
          normalizedData.push(invoiceNormalizer(invoice, null))
        );
      }
      dispatch(slice.actions.getBookingGuestInvoiceSuccess(normalizedData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error(error);
    }
  };
}

export function addBookingTags(newTags: any[]) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const normalizedTags = newTags.length ? newTags.map((item) => tagNormalizer(item)) : [];
    dispatch(slice.actions.setBookingTagsSuccess(normalizedTags));
  };
}

export function getBookingExpenses(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchBookingsExpenses(bookingId);
      dispatch(
        slice.actions.getBookingExpensesSuccess(
          response.data.map((expense) => expenseNormalizer(expense))
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingInvestor(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchBookingsInvestor(bookingId);
      dispatch(slice.actions.getBookingInvestorSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInstallments(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchInstallments(id, 'true');
      dispatch(slice.actions.getInstallmentsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUnifiedInboxConversation(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchConversationUrl(id);
      dispatch(
        slice.actions.setUnifiedInboxConversationSuccess(conversationNormalizer(response.data))
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDirectChannels() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await filterAPI.fetchChannels(250, 'true', 'false');
      dispatch(slice.actions.setDirectChannelsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addGuestTag(
  newTags: string[],
  guestId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await commonAPI.createAndUpdateTags(guestId, 'guest', newTags);
      const normalizedTags = response.data ? response.data.map((item) => tagNormalizer(item)) : [];
      dispatch(slice.actions.setGuestTagsSuccess(normalizedTags));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function addPersonBookingTag(
  newTags: string[],
  guestId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await commonAPI.createAndUpdateTags(guestId, 'guest', newTags);
      const normalizedTags = response.data ? response.data.map((item) => tagNormalizer(item)) : [];
      dispatch(slice.actions.setPersonBookingTagsSuccess(normalizedTags));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function addCorporationTag(
  newTags: string[],
  corporationId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await commonAPI.createAndUpdateTags(corporationId, 'corporation', newTags);
      const normalizedTags = response.data ? response.data.map((item) => tagNormalizer(item)) : [];
      dispatch(slice.actions.setCorporationTagsSuccess(normalizedTags));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function addBookingGuestTag(
  newTags: string[],
  guestId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await commonAPI.createAndUpdateTags(guestId, 'guest', newTags);
      const normalizedTags = response.data ? response.data.map((item) => tagNormalizer(item)) : [];
      dispatch(slice.actions.setBookingGuestTagsSuccess({ id: guestId, data: normalizedTags }));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateChannel(bookingId: number, channelId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.updateChannel(bookingId, channelId);
      dispatch(slice.actions.setChannelSuccess(channelNormalizer(response.data)));
      dispatch(updateBooking(bookingId));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCorporation(bookingId: number, corporationId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.updateCorporation(bookingId, corporationId);
      dispatch(slice.actions.setCorporationSuccess(corporationNormalizer(response.data)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateChannelId(bookingId: number, channelId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.updateChannelReference(bookingId, channelId);
      const bookingReference = response.data.booking_reference;
      dispatch(slice.actions.setChannelReferenceSuccess(bookingReference));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addInternalNote(bookingId: number, text: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await BookingApi.createInternalNote(bookingId, text);
      dispatch(
        slice.actions.setInternalNoteSuccess(internalNoteNormalizer(response.data.success[0]))
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateInternalNote(bookingId: number, noteId: number, text: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await BookingApi.updateInternalNote(bookingId, noteId, text);
      dispatch(
        slice.actions.editInternalNoteSuccess({
          id: noteId,
          message: text,
          createdAt: response.data.createdAt,
          owner: response.data.updatedBy,
          updatedAt: response.data.updatedAt,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteInternalNote(bookingId: number, noteId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await BookingApi.deleteInternalNote(bookingId, noteId);
      dispatch(slice.actions.deleteInternalNoteSuccess(noteId));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingDeposit(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchBookingsDeposits(bookingId);
      dispatch(slice.actions.getBookingDepositsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getConfirmationSettings(brandId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchConfirmationSettings(brandId);
      dispatch(slice.actions.setConfirmationSettingsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGuestBirthday(bookingId: number, guestId: number, date: string | null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const guestResponse: { data } = await BookingApi.updateGuestBirthday(guestId, date);
      const dateOfBirth = guestResponse.data.date_of_birth;
      dispatch(slice.actions.setGuestBirthdaySuccess(dateOfBirth));
      const bookingResponse: { data } = await BookingApi.fetchBooking(bookingId);
      dispatch(slice.actions.getBookingSuccess(bookingNormalizer(bookingResponse.data)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGuestCity(bookingId: number, guestId: number, cityName: string | null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const guestResponse: { data } = await BookingApi.updateGuestCity(guestId, cityName);
      const { city } = guestResponse.data.address;
      dispatch(slice.actions.setGuestCitySuccess(city));
      const bookingResponse: { data } = await BookingApi.fetchBooking(bookingId);
      dispatch(slice.actions.getBookingSuccess(bookingNormalizer(bookingResponse.data)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBookingPurpose(bookingId: number, purpose: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.updateBookingPurpose(bookingId, purpose);
      const bookingPurpose = response.data.booking_purpose;
      dispatch(slice.actions.setBookingPurposeSuccess(bookingPurpose));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGuestVerification(
  guestId: number,
  verified: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (response: AxiosResponse<any, any>) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response: { data } = await BookingApi.updateGuestVerification(guestId, verified);
      const idVerified = response.data.id_verified;
      dispatch(slice.actions.setGuestVerificationSuccess(idVerified));
      callback(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
}

export function updateBookingGuestVerification(
  guestId: number,
  verified: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (response: AxiosResponse<any, any>) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response: { data } = await BookingApi.updateGuestVerification(guestId, verified);
      const idVerified = response.data.id_verified;
      dispatch(
        slice.actions.setBookingGuestVerificationSuccess({ id: guestId, value: idVerified })
      );
      callback(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
}

export function getBookingRatePlan(ratePlanId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchRatePlan(ratePlanId);
      dispatch(slice.actions.setBookingRatePlanSuccess(ratePlanNormalizer(response.data)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSubmittedBookingGuest(
  bookingGuestId: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (succeed: boolean) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.deleteBookingGuest(bookingGuestId);
      dispatch(slice.actions.deleteBookingGuestSuccess(bookingGuestId));
      dispatch(slice.actions.setNumberOfChildGuestsSuccess(response.data.number_of_child_guests));
      dispatch(slice.actions.setNumberOfAdultGuestsSuccess(response.data.number_of_adult_guests));
      callback(true);
    } catch (error) {
      console.error(error);
      callback(false);
    } finally {
      setLoading(false);
    }
  };
}

export function removeFromXeroAction(
  invoiceId: number,
  bookingNo: string,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await BookingApi.voidInvoice(invoiceId).then((result) => {
        dispatch(getBookingGuestInvoices(bookingNo));
        callback(true, null);
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      callback(false, error.data && error.data.XeroMessage ? error.data.XeroMessage : null);
    } finally {
      setLoading(false);
    }
  };
}

export function postToXeroAction(
  invoiceId: number,
  bookingNo: string,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await BookingApi.postInvoiceToXero(invoiceId);
      dispatch(getBookingGuestInvoices(bookingNo));
      callback(true, null);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      callback(false, error.data && error.data.XeroMessage ? error.data.XeroMessage : null);
    } finally {
      setLoading(false);
    }
  };
}

export function releaseSecurityDepositAction(bookingId: number) {
  return async () => {
    try {
      await BookingApi.releaseSecurityDeposit(bookingId);
      dispatch(getBookingDeposit(bookingId));
      dispatch(updateBooking(bookingId));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingCheckInOutConfig(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchBookingConfigs(bookingId.toString());
      dispatch(
        slice.actions.getCheckInOutConfigSuccess(
          checkInOutConfigNormalizer(response.data[bookingId.toString()])
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingPayments(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const normalizedData: Array<BookingPayment> = [];
      const response: { data } = await BookingApi.fetchPaymentsByBooking(bookingId);
      response.data.map((item) => normalizedData.push(bookingPaymentNormalizer(item)));
      dispatch(slice.actions.getBookingPaymentsSuccess(normalizedData));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNumberOfAdultGuests(
  bookingId: number,
  adults: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    setLoading?.(true);
    try {
      const response: { data } = await BookingApi.updateNumberOfAdultGuests(bookingId, adults);
      const numberOfAdultGuests = response.data.number_of_adult_guests;
      dispatch(slice.actions.setNumberOfAdultGuestsSuccess(numberOfAdultGuests));
      callback?.(true);
    } catch (error) {
      callback?.(false);
      console.error(error);
      dispatch(slice.actions.hasError(error));
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateNumberOfChildGuests(
  bookingId: number,
  children: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    setLoading?.(true);
    try {
      const response: { data } = await BookingApi.updateNumberOfChildGuests(bookingId, children);
      const numberOfChildGuests = response.data.number_of_child_guests;
      dispatch(slice.actions.setNumberOfChildGuestsSuccess(numberOfChildGuests));
      callback?.(true);
    } catch (error) {
      callback?.(false);
      console.error(error);
      dispatch(slice.actions.hasError(error));
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateBooking(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const bookingResponse: { data } = await BookingApi.fetchBooking(bookingId);
      const bookingAddonImagesResponse: { data } = await BookingApi.getBookingAddonImages(
        bookingId
      );
      dispatch(slice.actions.getBookingSuccess(bookingNormalizer(bookingResponse.data)));
      dispatch(slice.actions.setBookingAddonImages(bookingAddonImagesResponse.data));
      dispatch(getBookingCheckInReadyStatus(bookingId));
      dispatch(getInstallments(bookingId));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePersonBooking(bookingId: number, personBookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.updatePersonBooking(bookingId, personBookingId);
      const personBookingObj = response.data.person_booking;
      dispatch(slice.actions.setPersonBooking(guestNormalizer(personBookingObj)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateArrivalTime(
  bookingId: number,
  arrivalTime: string | null,
  chargeExtra: number | null,
  callback: (succeed: boolean, response: any) => void,
  setLoading: (succeed: boolean) => void
) {
  return async () => {
    setLoading(true);
    dispatch(slice.actions.startLoading());
    try {
      const response = await BookingApi.updateArrivalTime(bookingId, arrivalTime, chargeExtra);
      dispatch(slice.actions.setArrivalTimeSuccess(arrivalTime));
      dispatch(updateBooking(bookingId));
      callback(true, response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      callback(false, null);
    } finally {
      setLoading(false);
    }
  };
}

export function updateDepartureTime(
  bookingId: number,
  departureTime: string | null,
  chargeExtra: number | null,
  callback: (succeed: boolean, response: any) => void,
  setLoading: (succeed: boolean) => void
) {
  return async () => {
    setLoading(true);
    dispatch(slice.actions.startLoading());
    try {
      const response = await BookingApi.updateDepartureTime(bookingId, departureTime, chargeExtra);
      dispatch(slice.actions.setDepartureTimeSuccess(departureTime));
      dispatch(updateBooking(bookingId));
      callback(true, response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      callback(false, null);
    } finally {
      setLoading(false);
    }
  };
}

export function updateGuestAge(
  guestId: number,
  age: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: () => void
) {
  return async () => {
    setLoading(true);
    try {
      await BookingApi.updateGuestAge(guestId, age);
      dispatch(slice.actions.setGuestAge({ id: guestId, value: age }));
      callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    } finally {
      setLoading(false);
    }
  };
}

export function updateBookingChildAges(
  bookingId: number,
  ages: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: () => void
) {
  return async () => {
    setLoading(true);
    try {
      await BookingApi.updateBookingChildAges(bookingId, ages);
      dispatch(slice.actions.setChildAges(ages));
      callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    } finally {
      setLoading(false);
    }
  };
}

export function getMessageVariables(type: VariableApiType, bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await automatedMessageAPI.fetchVariables(type, bookingId);
      const variables = provideVariables(response.data);
      if (type === 'automated_message') {
        dispatch(slice.actions.setMessageVariables(variables));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAutomatedMessageNotifications(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await automatedMessageAPI.fetchAutomatedMessageNotifications(
        bookingId
      );
      dispatch(slice.actions.setAutomatedMessageNotificationsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingTaxRate(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchTaxRate(bookingId);
      dispatch(slice.actions.setBookingTaxRateSuccess(taxRateNormalizer(response.data)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addBookingLeadGuest(
  bookingId: number,
  guestId: number,
  numberOfAdultGuests: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (response: AxiosResponse<any, any>) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response: { data } = await BookingApi.addBookingLeadGuest(
        bookingId,
        guestId,
        numberOfAdultGuests
      );
      dispatch(slice.actions.getBookingSuccess(bookingNormalizer(response.data)));
      callback(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
}

export function getBookingBedConfig(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchBedConfig(bookingId);
      dispatch(slice.actions.setBookingBedConfigSuccess(response.data.beddingConfig));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingRefund(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchBookingsRefunds(bookingId);
      dispatch(slice.actions.getBookingRefundSuccess(response.data.refunds));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBookingTotalCreditAction(bookingOrderId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchGroupBookings(bookingOrderId);
      dispatch(slice.actions.getBookingTotalCreditSuccess(response.data.total_credit ?? undefined));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function proceedPayment(
  paymentMethod: string,
  orderLineId: number,
  bookingId: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (success: boolean, response?: ProceedPayment) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response: { data } = await BookingApi.getProceedToPayment(
        paymentMethod,
        orderLineId,
        bookingId
      );
      if (paymentMethod === PAYMENT_METHOD_STRIPE) {
        callback(true);
      } else {
        const proceedPaymentResponse: ProceedPayment = proceedPaymentNormalizer(
          response.data.result
        );
        callback(true, proceedPaymentResponse);
      }
    } catch (error) {
      callback(false);
      console.error(error);
      dispatch(slice.actions.hasError(error));
    } finally {
      setLoading(false);
    }
  };
}

export function getPaymentMethod(
  bookingId: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (success: boolean, response?: OverPaymentMethod) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response: { data } = await BookingApi.getPaymentMethodsForOverPayments(bookingId);
      const paymentMethodResponse: OverPaymentMethod = response.data;
      callback(true, paymentMethodResponse);
    } catch (error) {
      callback(false);
      console.error(error);
      dispatch(slice.actions.hasError(error));
    } finally {
      setLoading(false);
    }
  };
}

export function UpdateBookingOrder(bookingOrderId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.fetchGroupBookings(bookingOrderId);
      dispatch(slice.actions.setBookingOrderBySuccess(response.data.order_by ?? null));
      if (response.data.order_by_corporation) {
        const corporationResponse = await BookingApi.fetchCorporation(
          +response.data.order_by_corporation.split('/').pop()
        );
        dispatch(
          slice.actions.setBookingOrderByCorporationSuccess(corporationResponse.data ?? null)
        );
      } else {
        dispatch(slice.actions.setBookingOrderByCorporationSuccess(null));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPotentialAutomatedMessage(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await automatedMessageAPI.getBookingAllAutomatedMessages(
        bookingId
      );
      dispatch(
        slice.actions.setDisabledAutomatedMessagesSuccess(
          response.data.potentialDisableAutomatedMessages
        )
      );
      dispatch(slice.actions.setScheduledAutomatedMessagesSuccess(response.data.schedules));
      dispatch(
        slice.actions.setPotentialAutomatedMessagesSuccess(
          enableDisableAutomatedMessageNormalizer(response.data)
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingCheckInReadyStatus(bookingId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data } = await BookingApi.getBookingCheckInReadyStatus(bookingId);
      dispatch(slice.actions.setCheckInReadySettingsSuccess(response.data.checkInReadyStatus));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSecurityDepositAmountAction(
  bookingId: number,
  amount: number,
  currency: string,
  callback: (response: any, succeed: boolean) => void
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await BookingApi.updateSecurityDepositAmount(bookingId, amount, currency);
      if (response.data) {
        dispatch(
          slice.actions.updateSecurityDepositSuccess({
            amount: {
              value: amount,
              currency: currency,
              baseValue: amount,
            },
            status: response.data.deposit_status,
          })
        );
        callback(response, true);
      } else {
        callback(response, false);
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      callback(error, false);
    }
  };
}

export function createNewBookingGuest(
  bookingId: number,
  firstName: string,
  lastName: string,
  mobileNumber: string,
  email: string,
  isChild: boolean = false,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      await BookingApi.createNewBookingGuest(
        bookingId,
        firstName,
        lastName,
        mobileNumber,
        email,
        isChild
      );
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function createExistingBookingGuest(
  bookingId: number,
  guestId: number,
  isChild: boolean,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      await BookingApi.createExistingBookingGuest(bookingId, guestId, isChild);
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateGuest(
  guest: BWGuest,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      await BookingApi.updateGuest(guest);
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateBookingGuest(
  bookingId: number,
  bookingGuestId: number,
  guestId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      await BookingApi.updateBookingGuest(bookingId, bookingGuestId, guestId);
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function includeSdInInvoiceAction(
  bookingId: number,
  bookingNo: string,
  isIncluded: boolean,
  callback: (response: any, succeed: boolean) => void
) {
  return async () => {
    try {
      const response = await BookingApi.includeSDInInvoice(bookingId, isIncluded);
      if (response.data) {
        dispatch(getBookingGuestInvoices(bookingNo));
        callback(response, true);
      } else {
        callback(response, false);
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      callback(error, false);
    }
  };
}

export function showPayNowBtnOnInvoice(
  bookingNo: string,
  invoiceId: number,
  bankAccountInfo: boolean,
  paymentInfo: boolean,
  includeSecurityDeposit: boolean,
  callback: (response: any, succeed: boolean) => void
) {
  return async () => {
    try {
      const response = await BookingApi.updateInvoiceSettings(
        invoiceId,
        bankAccountInfo,
        paymentInfo,
        includeSecurityDeposit
      );
      if (response.data) {
        dispatch(getBookingGuestInvoices(bookingNo));
        callback(response, true);
      } else {
        callback(response, false);
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      callback(error, false);
    }
  };
}

export function getPriceRecalculation(
  bookingId: number,
  checkinDate: string,
  checkoutDate: string,
  unitTypeID: number,
  setLoading: (succeed: boolean) => void,
  callback: (response: any, succeed: boolean) => void,
  accommodationPrice?: number,
  unitId?: number | null
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.fetchPriceRecalculation(
        bookingId,
        checkinDate,
        checkoutDate,
        unitTypeID,
        accommodationPrice,
        unitId
      );
      callback(response, true);
    } catch (error) {
      callback(error, false);
    } finally {
      setLoading(false);
    }
  };
}

export function AddGuest(
  bookingId: number,
  body: any,
  callback: (succeed: boolean) => void,
  setLoading: (succeed: boolean) => void,
  isChannelBooking: boolean = false,
  isChannelCollect: boolean = false,
  channelName: string = '',
  paymentLink: string = ''
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.addExtraGuest(body);
      dispatch(updateBooking(bookingId));
      dispatch(
        slice.actions.addBookingGuest(response.data.map((guest) => bookingGuestNormalizer(guest)))
      );
      if (
        isChannelBooking &&
        isChannelCollect &&
        Boolean(body.chargeExtraGuestFee) &&
        body.extraGuestFeePriceValue &&
        body.extraGuestFeePriceValue > 0
      ) {
        dispatch(
          slice.actions.setChannelBookingUpdatedDialogOpen({
            open: true,
            values: {
              bookingId: bookingId,
              action: 'guest',
              paymentLink: paymentLink,
              channelName: channelName,
            },
          })
        );
      } else {
        dispatch(slice.actions.setDetailsUpdatedDialogOpen({open: true, bookingId: bookingId}));
      }
      callback(true);
    } catch (error) {
      callback(false);
    } finally {
      setLoading(false);
    }
  };
}

export function finalizeBooking(
  bookingId: number,
  callback: (succeed: boolean, response: any) => void,
  setLoading: (succeed: boolean) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.finalizeBooking(bookingId);
      dispatch(updateBooking(bookingId));
      callback(true, response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error(error);
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}

export function undoFinalizeBooking(
  bookingId: number,
  callback: (succeed: boolean, response: any) => void,
  setLoading: (succeed: boolean) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.undoFinalizeBooking(bookingId);
      dispatch(updateBooking(bookingId));
      callback(true, response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error(error);
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}
