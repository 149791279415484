import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body2,
          fontWeight: 600,
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginRight: 0,
          marginLeft: theme.spacing(1),
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.disabled
        }
      }
    }
  };
}
