import React, { FC } from "react";
// @mui
import { Box, MenuItem, MenuItemProps } from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import { DotIcon } from "src/components/nav-section/NavItem";


const MenuItemStyle = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<{ selected: boolean }>(({ theme, selected }) => ({
  ...theme.typography.body2,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 22,
  borderRadius: 8,
  ...(selected && { paddingLeft: 0 }),
}));

interface BulletedMenuItemProps extends MenuItemProps {
  selected: boolean;
}

const BulletedMenuItem: FC<BulletedMenuItemProps> = ({
  selected,
  children,
  ...other
}) => (
  <MenuItemStyle {...other} selected={selected}>
    {selected && (
      <Box display="flex" justifyContent="center" alignItems="center">
        <DotIcon active={true} sx={{ mr: 0, minWidth: 'auto !important' }} />
      </Box>
    )}
    <Box flexGrow={1} sx={{ color: selected ? 'primary.main' : 'inherit' }}>
      {children}
    </Box>
  </MenuItemStyle>
);

export default BulletedMenuItem;