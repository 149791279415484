import { addDays, subDays } from 'date-fns';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
// @mui
import { DatePicker, TimePicker } from '@mui/x-date-pickers-pro';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
// api
import PropertyApi from 'src/api/property';
import CalendarApi from 'src/api/calendar';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fDateToUTC } from 'src/utils/formatTime';
import { extractProperties, extractUnits, extractUnitTypes } from 'src/utils/calendar';
// components
import Iconify from 'src/components/Iconify';
import SvgIconStyle from 'src/components/SvgIconStyle';
// sections
import CustomComponentTemplate from 'src/sections/wizard/listing/components/CustomComponentTemplate';
import BulletedMenuItem from '../../../../components/BulletedMenuItem';

const BookingSplitForm = forwardRef(
  (
    props: {
      bookingId: number;
      arrivalDate: Date | string | number;
      departureDate: Date | string | number;
      onError;
      onFinish;
    },
    ref
  ) => {
    const { translate, shortDateFormat, timeFormat } = useLocales([
      'common',
      'calendar',
      'booking',
    ]);

    const [timePickerOpen, setTimePickerOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState<Date | null>(null);
    const [time, setTime] = useState<Date | null>(null);
    const [openProperties, setOpenProperties] = useState(false);
    const [properties, setProperties] = useState<any[]>([]);
    const [openUnitTypes, setOpenUnitTypes] = useState(false);
    const [unitTypes, setUnitTypes] = useState<[]>([]);
    const [openUnits, setOpenUnits] = useState(false);
    const [units, setUnits] = useState<any[]>([]);

    const [property, setProperty] = useState<any>(null);
    const [unitType, setUnitType] = useState<any>(null);
    const [unit, setUnit] = useState<any>(null);
    const [propertyError, setPropertyError] = useState(false);
    const [unitTypeError, setUnitTypeError] = useState(false);
    const [splitDateError, setSplitDateError] = useState(false);
    const [unitError, setUnitError] = useState(false);

    function setPropertyField(propertyId) {
      properties.map((property: any) => {
        if (propertyId * 1 === property?.id) {
          setProperty(property);
          setPropertyError(false);
        }
      });
    }

    useEffect(() => {
      setLoading(true);
      CalendarApi.fetchCalendarConfig('0', false).then((response) => {
        setProperties(extractProperties(response.data.data));
        setUnitTypes(extractUnitTypes(response.data.data));
        setUnits([
          {
            id: -1,
            name: translate('unallocated'),
            unitType: null,
            property: null,
          },
          ...extractUnits(response.data.data),
        ]);
        setLoading(false);
      });
    }, []);

    useImperativeHandle(ref, () => ({
      submit() {
        const propertyError = property === null;
        const unitTypeError = unitType === null;
        const splitDateError = date === null;
        const unitError = unit === null;

        setPropertyError(propertyError);
        setUnitTypeError(unitTypeError);
        setSplitDateError(splitDateError);
        setUnitError(unitError);

        if (propertyError || unitTypeError || splitDateError || unitError) {
          props.onError();
          return;
        }

        PropertyApi.splitBooking({
          booking: props.bookingId,
          splitDate: !!date && fDateToUTC(date),
          splitTime: !!time && fDateToUTC(time),
          property: property?.id,
          unitType: unitType?.id,
          unit: unit?.id === -1 ? null : unit?.id,
        })
          .then(async (response) => {
            if (response.status >= 200 && response.status < 300) {
              props.onFinish(translate('split.success_message'), response.data);
            }
          })
          .catch((err) => {
            props.onError(err.data, 'split', () => {
              PropertyApi.splitBooking({
                booking: props.bookingId,
                splitDate: !!date && fDateToUTC(date),
                splitTime: !!time && fDateToUTC(time),
                property: property?.id,
                unitType: unitType?.id,
                unit: null,
              })
                .then(async (response) => {
                  if (response.status >= 200 && response.status < 300) {
                    props.onFinish(translate('split.success_message'), response.data);
                  }
                })
                .catch((err) => {
                  props.onError(err.data);
                });
            });
          });
      },
    }));

    return (
      <Box>
        <CustomComponentTemplate
          label={translate('split.split_date') as string}
          sx={{ marginTop: '15px' }}
        >
          <DatePicker
            value={date}
            onChange={(newValue) => {
              setSplitDateError(false);
              setDate(newValue);
            }}
            minDate={addDays(new Date(props.arrivalDate), 1)}
            maxDate={subDays(new Date(props.departureDate), 1)}
            referenceDate={new Date(props.arrivalDate)}
            format={shortDateFormat.format}
            slotProps={{
              textField: {
                fullWidth: true,
                placeholder: '',
                inputProps: { readOnly: true },
                error: splitDateError,
                helperText: splitDateError && translate('split.select_date'),
              },
            }}
          />
        </CustomComponentTemplate>
        <CustomComponentTemplate
          label={translate('split.split_time') as string}
          sx={{ marginTop: '15px' }}
          optional
        >
          <TimePicker
            open={timePickerOpen}
            value={time}
            onChange={(newValue) => setTime(newValue)}
            onClose={() => setTimePickerOpen(false)}
            slots={{
              digitalClockSectionItem: (props) => <BulletedMenuItem {...props} />,
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                placeholder: 'hh:mm',
                onClick: () => setTimePickerOpen(true),
              },
              layout: {
                sx: {
                  '& .MuiMultiSectionDigitalClockSection-root': {
                    width: 155,
                    flexGrow: 1,
                    scrollbarGutter: 'stable',
                  },
                  '& .MuiMultiSectionDigitalClockSection-root:after': {
                    display: 'none',
                  },
                },
              },
            }}
            localeText={{ okButtonLabel: translate('select') }}
            ampm={false}
            ampmInClock={false}
          />
          <FormHelperText sx={{ marginLeft: '2px' }}>
            {translate('split.property_timezone')}
          </FormHelperText>
        </CustomComponentTemplate>
        <Divider sx={{ marginTop: '20px' }} />
        <CustomComponentTemplate label={translate('property') as string} sx={{ marginTop: '15px' }}>
          <Autocomplete
            open={openProperties}
            onOpen={() => {
              setOpenProperties(true);
            }}
            onClose={() => {
              setOpenProperties(false);
            }}
            value={property}
            fullWidth
            isOptionEqualToValue={(option: any, value: any) => option?.name === value?.name}
            getOptionLabel={(option: any) => option?.name}
            options={properties}
            loading={loading}
            onChange={(e, value) => {
              setProperty(value);
              setUnitType(null);
              setUnit(null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={propertyError}
                helperText={propertyError && translate('split.select_property')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Iconify
                  icon={selected ? 'mdi:circle' : ''}
                  color={'primary.main'}
                  width={8}
                  height={8}
                />
                <Typography
                  variant="body2"
                  fontWeight={selected ? 'fontWeightSemiBold' : 'fontWeightRegular'}
                  color={selected ? 'primary.main' : 'text.primary'}
                  ml={selected ? 1 : 2}
                >
                  {option.name}
                </Typography>
              </li>
            )}
          />
        </CustomComponentTemplate>
        <CustomComponentTemplate
          label={translate('unit_type') as string}
          sx={{ marginTop: '15px' }}
        >
          <Autocomplete
            open={openUnitTypes}
            onOpen={() => {
              setOpenUnitTypes(true);
            }}
            onClose={() => {
              setOpenUnitTypes(false);
            }}
            value={unitType}
            fullWidth
            isOptionEqualToValue={(option: any, value: any) => option?.name === value?.name}
            getOptionLabel={(option: any) => option?.name}
            options={
              !!property
                ? unitTypes.filter((ut: any) => ut?.property?.id === property?.id)
                : unitTypes
            }
            loading={loading}
            onChange={(e, value) => {
              setUnitType(value);
              setUnitTypeError(false);
              setUnit(null);
              setPropertyField(value?.property.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={unitTypeError}
                helperText={unitTypeError && translate('split.select_unit_type')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Iconify
                  icon={selected ? 'mdi:circle' : ''}
                  color={'primary.main'}
                  width={8}
                  height={8}
                />
                <Typography
                  variant="body2"
                  fontWeight={selected ? 'fontWeightSemiBold' : 'fontWeightRegular'}
                  color={selected ? 'primary.main' : 'text.primary'}
                  ml={selected ? 1 : 2}
                >
                  {option.name}
                </Typography>
              </li>
            )}
          />
        </CustomComponentTemplate>
        <CustomComponentTemplate label={translate('unit') as string} sx={{ marginTop: '15px' }}>
          <Autocomplete
            open={openUnits}
            onOpen={() => {
              setOpenUnits(true);
            }}
            onClose={() => {
              setOpenUnits(false);
            }}
            fullWidth
            isOptionEqualToValue={(option: any, value: any) => option?.name === value?.name}
            getOptionLabel={(option: any) => option?.name}
            options={
              !!unitType
                ? units.filter((unit: any) => unit?.unitType?.id === unitType.id || unit.id === -1)
                : !!property
                ? units.filter((unit: any) => unit?.property?.id === property?.id || unit.id === -1)
                : units
            }
            loading={loading}
            onChange={(e, value) => {
              setUnit(value);
              setUnitError(false);
              const unitType = value?.unitType;
              if (unitType !== null) {
                setUnitType(unitType);
                setUnitTypeError(false);
                setPropertyField(value?.property.id);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={unitError}
                helperText={unitError && translate('split.select_unit')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) =>
              option.id === -1 ? (
                <>
                  <li {...props}>
                    <SvgIconStyle
                      src={'/icons/calendar/ic_unallocated.svg'}
                      sx={{ width: 24, height: 24, ml: 2, bgcolor: 'grey.600' }}
                    />
                    <Typography
                      variant="body2"
                      fontWeight={selected ? 'fontWeightSemiBold' : 'fontWeightRegular'}
                      color={selected ? 'primary.main' : 'text.primary'}
                      ml={1}
                    >
                      {option.name}
                    </Typography>
                  </li>
                  <Divider />
                </>
              ) : (
                <li {...props}>
                  <Iconify
                    icon={selected ? 'mdi:circle' : ''}
                    color={'primary.main'}
                    width={8}
                    height={8}
                  />
                  <Typography
                    variant="body2"
                    fontWeight={selected ? 'fontWeightSemiBold' : 'fontWeightRegular'}
                    color={selected ? 'primary.main' : 'text.primary'}
                    ml={selected ? 1 : 2}
                  >
                    {option.name}
                  </Typography>
                </li>
              )
            }
          />
        </CustomComponentTemplate>
      </Box>
    );
  }
);

export default BookingSplitForm;
