import React, { FC, useEffect, useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormGroup, InputLabel } from '@mui/material';
// api
import commonAPI from 'src/api/api';
// hooks
import useLocales from 'src/hooks/useLocales';
// @types
import { Tag, TagOption } from 'src/@types/booking';
// components
import DrawerWithAction from 'src/components/DrawerWithAction';
import TagAutocompleteInput from './components/TagAutocompleteInput';

type AddTagDrawerProps = {
  open: boolean;
  bookingId: number | null;
  bookingTags: Tag[];
  onClose: () => void;
  onAction?: (data: any) => void;
};

const BookingTagDrawer: FC<AddTagDrawerProps> = ({
  open,
  bookingId,
  bookingTags,
  onClose,
  onAction,
}) => {
  const { translate } = useLocales(['booking', 'common']);
  const [tagConfig, setTagConfig] = useState<{
    value: TagOption[];
    isEditing: boolean;
    isLoading: boolean;
  }>({
    value: [],
    isEditing: false,
    isLoading: false,
  });

  useEffect(() => {
    resetTagConfig();
  }, [open]);

  const onAdd = async () => {
    setTagConfig((prev) => ({ ...prev, isLoading: true }));
    if (bookingId) {
      await commonAPI
        .createAndUpdateTags(
          bookingId,
          'booking',
          tagConfig.value.map((tag) => tag.content)
        )
        .then((response) => {
          if (onAction) onAction(response.data);
          setTagConfig((prev) => ({ ...prev, isLoading: false }));
          handleClose();
        })
        .catch((error) => {
          setTagConfig((prev) => ({ ...prev, isLoading: false }));
          console.error(error);
        });
    }
  };

  const resetTagConfig = () => {
    setTagConfig({
      value: bookingTags.map((tag) => ({ type: 'created', content: tag.name, id: tag.id })),
      isEditing: false,
      isLoading: false,
    });
  };

  const handleClose = () => {
    resetTagConfig();
    onClose();
  };

  return (
    <DrawerWithAction
      open={open}
      title={translate('booking.bookingActions.addBookingTag.addBookingTag')}
      onClose={handleClose}
      actions={
        <Box display="flex" justifyContent="space-between" gap={1}>
          <Button variant="underline" color="inherit" onClick={handleClose}>
            {translate('cancel')}
          </Button>
          <LoadingButton variant="contained" onClick={onAdd} loading={tagConfig.isLoading}>
            {translate('add')}
          </LoadingButton>
        </Box>
      }
    >
      <Box sx={{ px: 3, py: 2.5 }}>
        <FormGroup>
          <InputLabel
            htmlFor="booking-tag"
            sx={{ fontWeight: 'fontWeightSemiBold', color: 'text.primary', ml: 1, mb: 0.5 }}
          >
            {translate('booking.bookingActions.addBookingTag.tag')}
          </InputLabel>
          <TagAutocompleteInput
            tags={tagConfig.value}
            setTags={(value) => setTagConfig((prev) => ({ ...prev, value }))}
          />
        </FormGroup>
      </Box>
    </DrawerWithAction>
  );
};

export default BookingTagDrawer;
