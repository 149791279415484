import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { utcToZonedTime } from 'date-fns-tz';
// @mui
import { styled } from '@mui/material/styles';
import { MenuProps } from '@mui/material/Menu';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRow,
  GridRowProps,
  GridRowSelectionModel,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import {
  Link,
  Avatar,
  Stack,
  Tooltip,
  Typography,
  alpha,
  Menu,
  MenuItem,
  IconButton,
  Box,
  tooltipClasses,
  TooltipProps,
  TextField,
  Autocomplete,
} from '@mui/material';
// routes
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
// api
import PropertyApi from 'src/api/property';
import BookingApi from 'src/api/booking';
// hooks
import useAsync from 'src/hooks/useAsync';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// @types
import { ExportFileType } from 'src/@types/common';
import { CheckInReadyStatus } from 'src/@types/booking';
import { DateFilterSelectValue } from 'src/@types/filter';
import {
  BookingGridRow,
  Booking,
  BookingGridColumn,
  ColumnField,
  BookingListView,
  SortDirection,
  ALL_BOOKINGS_COLUMN_FIELDS,
  BookingListViewValue,
} from 'src/@types/booking-list';
// utils
import { fDT } from 'src/utils/formatTime';
import { downloadFile } from 'src/utils/file';
import { sortColumnsByReference } from 'src/utils/dataGrid';
import { fCurrencyWithSymbol } from 'src/utils/formatNumber';
import {
  checkInOutConfigNormalizer,
  dateToIndex,
  fCurrencySymbol,
  getCheckInReadyConfig,
} from 'src/utils/booking';
// components
import Tag from 'src/components/Tag';
import Iconify from 'src/components/Iconify';
import { CardAction } from 'src/components/card';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { ActionType } from 'src/components/card/CardAction';
import ExportButton from 'src/components/data-grid/ExportButton';
import CustomRenderCell from 'src/components/data-grid/CustomRenderCell';
import SnackbarContent from 'src/components/data-grid/ExportLoadingSnackbarContent';
import CustomEditCell from 'src/components/data-grid/CustomEditCell';
// sections
import BookingActionDrawer from './BookingActionDrawer';
import { NumberCondition } from '../guest/data-grid/NumberCustomAccordionFilter';
import BookingTagDrawer from '../booking/BookingTagDrawer';
import useAcl from 'src/hooks/useAcl';
import useAuth from 'src/hooks/useAuth';

const DEFAULT_COLUMN_CONFIG = {
  editable: false,
  sortable: true,
  disableColumnMenu: true,
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
  },
}));

export const getArrivalDateSelectOptions = (
  bookingListView: BookingListView
): DateFilterSelectValue[] => {
  switch (bookingListView.value) {
    case 'arrivals':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'this_year',
        'next_year',
        'custom',
      ];
    case 'departures':
    case 'cancellations':
    case 'all_bookings':
    case 'unallocated_bookings':
    case 'unmapped_bookings':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'past_7_days',
        'last_month',
        'this_year',
        'next_year',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    case 'stay-overs':
    case 'awaiting_finalisation':
      return [
        'all',
        'today',
        'past_7_days',
        'last_month',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    default:
      return [];
  }
};

export const getDepartureDateSelectOptions = (
  bookingListView: BookingListView
): DateFilterSelectValue[] => {
  switch (bookingListView.value) {
    case 'arrivals':
    case 'departures':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'this_year',
        'next_year',
        'custom',
      ];
    case 'stay-overs':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'this_year',
        'next_year',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    case 'cancellations':
    case 'all_bookings':
    case 'unallocated_bookings':
    case 'unmapped_bookings':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'past_7_days',
        'last_month',
        'this_year',
        'next_year',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    case 'awaiting_finalisation':
      return [
        'all',
        'past_7_days',
        'last_month',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    default:
      return [];
  }
};

export const getNumberAccordionFilterParams = (
  stateKey: string,
  value: {
    condition: NumberCondition;
    start: number;
    end: number | null;
  }
): { [key: string]: number | null } => {
  const { condition, start, end } = value;
  switch (condition) {
    case 'between':
      return {
        [`${stateKey}[gte]`]: start,
        [`${stateKey}[lse]`]: end,
      };
    case 'above':
      return { [`${stateKey}[gt]`]: start };
    case 'below':
      return { [`${stateKey}[ls]`]: start };
    case 'equal':
      return { [`${stateKey}`]: start };
    default:
      return {};
  }
};

const PrePaidChannelActionLabel = ({ label, tooltip, isDisabled }) => (
  <Box
    display="flex"
    width="100%"
    alignItems="center"
    gap={1.5}
    sx={{ cursor: isDisabled ? 'default' : 'pointer' }}
  >
    <Typography variant="body1" color={isDisabled ? 'text.disabled' : 'text.primary'}>
      {label}
    </Typography>
    {isDisabled && (
      <Tooltip arrow title={tooltip as string}>
        <span
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}
        >
          <Iconify icon="mdi:information-outline" color="text.secondary" width={20} height={20} />
        </span>
      </Tooltip>
    )}
    {!isDisabled && (
      <StyledTooltip arrow title={tooltip as string}>
        <IconButton sx={{ p: 0 }}>
          <SvgIconStyle
            src="/icons/ic_lamp.svg"
            sx={{ color: 'info.main', width: 20, height: 20 }}
          />
        </IconButton>
      </StyledTooltip>
    )}
  </Box>
);

const BookingActionComponents = ({
  activeRowData,
  onPrepaidChangeCallback,
  gridType,
  onMarkFinaliseAction,
  onFinaliseAction,
}: {
  activeRowData: BookingGridRow;
  onPrepaidChangeCallback: (bookingIds: number[]) => void;
  gridType: BookingListViewValue;
  onMarkFinaliseAction: (bookingId: number) => void;
  onFinaliseAction: (bookingId: number) => void;
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales(['bookingList', 'common']);

  const isChannelCollect = activeRowData.payment_collection_type === 'Channel Collect';
  const { EDIT: hasBookingEditAccess } = useAcl('PeymansBookingBundleBooking');

  const onChangeToChannelCollect = async () => {
    try {
      const response = await BookingApi.changeToChannelCollect(activeRowData.id);
      enqueueSnackbar(response.data.message as string);
      onPrepaidChangeCallback([activeRowData.id]);
    } catch (error) {
      console.error(error);
    }
  };

  const ACTIONS: ActionType[] = [
    {
      type: 'more',
      label: translate('view'),
      onAction: () => {
        navigate(`${PATH_DASHBOARD.booking.booking}/${activeRowData.id}/overview`);
      },
    },
    ...(hasBookingEditAccess && gridType !== ('awaiting_finalisation' as BookingListViewValue)
      ? ([
          {
            type: 'more',
            label: (
              <PrePaidChannelActionLabel
                label={translate('mark_as_channel_collect')}
                isDisabled={isChannelCollect}
                tooltip={
                  isChannelCollect
                    ? translate('already_channel_collect')
                    : translate('mark_booking_as_channel_collect_hint')
                }
              />
            ),
            onAction: !isChannelCollect ? onChangeToChannelCollect : undefined,
          },
        ] as ActionType[])
      : []),
    ...(hasBookingEditAccess && gridType === ('awaiting_finalisation' as BookingListViewValue)
      ? ([
          {
            type: 'more',
            label: (
              <PrePaidChannelActionLabel
                label={translate('finalise')}
                isDisabled={false}
                tooltip={translate('finalise_hint')}
              />
            ),
            onAction: () => {
              onFinaliseAction(activeRowData.id);
            },
          },
          {
            type: 'more',
            label: (
              <PrePaidChannelActionLabel
                label={translate('mark_as_finalise')}
                isDisabled={false}
                tooltip={translate('mark_finalise_hint')}
              />
            ),
            onAction: () => {
              onMarkFinaliseAction(activeRowData.id);
            },
          },
        ] as ActionType[])
      : []),
  ];

  return (
    <CardAction
      mode="collapsed"
      actions={ACTIONS}
      MenuProps={{ sx: { '& .MuiPaper-root': { width: 'max-content !important' } } }}
    />
  );
};

const PrePaidChannelHeaderActionComponents = ({
  prePaidChannelDataSelectedRows,
  onPrepaidChangeCallback,
}: {
  prePaidChannelDataSelectedRows: GridRowSelectionModel;
  onPrepaidChangeCallback: (bookingIds: number[]) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales(['bookingList', 'common']);
  const [actionOpen, setActionOpen] = useState<boolean>(false);
  const [actionAnchorEl, setActionAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(
    null
  );

  const openActionMenu = (event: {
    preventDefault: () => void;
    currentTarget: React.SetStateAction<(EventTarget & HTMLButtonElement) | null>;
  }) => {
    event.preventDefault();
    setActionAnchorEl(event.currentTarget);
    setActionOpen(true);
  };

  return (
    <>
      <IconButton onClick={openActionMenu} color={actionOpen ? 'primary' : undefined}>
        <Iconify icon={'ic:round-more-vert'} width={20} height={20} />
      </IconButton>
      <Menu
        anchorEl={actionAnchorEl}
        open={actionOpen}
        onClose={() => setActionOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            BookingApi.changeToChannelCollect(
              prePaidChannelDataSelectedRows.map((id) => id).join(',')
            )
              .then((response) => {
                enqueueSnackbar(response.data.message as string);
                onPrepaidChangeCallback(prePaidChannelDataSelectedRows.map((id) => +id));
              })
              .catch((error) => console.error(error));
            setActionOpen(false);
          }}
        >
          <PrePaidChannelActionLabel
            isDisabled={false}
            label={translate('mark_all_as_channel_collect')}
            tooltip={translate('mark_bookings_as_channel_collect_hint')}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

const AwaitingFinalisationHeaderActionComponents = ({
  selectedRows,
  onFinaliseBulkAction,
  onMarkAsFinaliseBulkAction,
}: {
  selectedRows: GridRowSelectionModel;
  onFinaliseBulkAction: (bookingIds: number[]) => void;
  onMarkAsFinaliseBulkAction: (bookingIds: number[]) => void;
}) => {
  const { translate } = useLocales(['bookingList', 'common']);
  const [actionOpen, setActionOpen] = useState<boolean>(false);
  const [actionAnchorEl, setActionAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(
    null
  );

  const openActionMenu = (event: {
    preventDefault: () => void;
    currentTarget: React.SetStateAction<(EventTarget & HTMLButtonElement) | null>;
  }) => {
    event.preventDefault();
    setActionAnchorEl(event.currentTarget);
    setActionOpen(true);
  };

  const ACTION_MENUS = [
    {
      title: translate('finalise'),
      tooltip: translate('finalise_hint'),
      action: () => {
        onFinaliseBulkAction(selectedRows.map((id) => +id));
        setActionOpen(false);
      },
    },
    {
      title: translate('mark_as_finalise'),
      tooltip: translate('mark_finalise_hint'),
      action: () => {
        onMarkAsFinaliseBulkAction(selectedRows.map((id) => +id));
        setActionOpen(false);
      },
    },
  ];
  return (
    <>
      <IconButton onClick={openActionMenu} color={actionOpen ? 'primary' : undefined}>
        <Iconify icon={'ic:round-more-vert'} width={20} height={20} />
      </IconButton>
      <Menu
        anchorEl={actionAnchorEl}
        open={actionOpen}
        onClose={() => setActionOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {ACTION_MENUS.map((action, index) => (
          <MenuItem key={index} onClick={action.action}>
            <PrePaidChannelActionLabel
              isDisabled={false}
              label={action.title}
              tooltip={action.tooltip}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
  },
}));

export const CustomGridRow = (props: GridRowProps) => (
  <Link
    href={`${PATH_DASHBOARD.booking.booking}/${props.row?.id}/overview`}
    underline="none"
    color="text.primary"
  >
    <GridRow {...props} />
  </Link>
);

export const CustomExportButton = ({
  bookingListView,
  defaultFilters,
  gridConfigs,
  columnVisibility,
}) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'sm');
  const { translate } = useLocales(['bookingList', 'common']);
  const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenu = () => {
    setActionAnchorEl(null);
  };

  const download = async (fileType: ExportFileType) => {
    closeMenu();
    enqueueSnackbar(<SnackbarContent />, { variant: 'info' });

    const RenderExportList = (AllGridList: any[], gridConfigVisibility: any[]) =>
      AllGridList.map(
        (item) =>
          gridConfigVisibility[item] !== false && {
            key: item,
            label: translate(item),
          }
      ).filter((item) => item !== false);

    const advanceFilterParam =
      gridConfigs?.advanceFilterState && gridConfigs?.advanceFilterState.value
        ? {
            key: gridConfigs?.advanceFilterState.key
              ? gridConfigs.advanceFilterState.key
              : 'searchKeyword',
            value: gridConfigs?.advanceFilterState.value || '',
          }
        : undefined;
    try {
      const res = await BookingApi.fetchBookingList({
        ...(gridConfigs?.sortModel?.[0] && {
          order: gridConfigs?.sortModel[0] as {
            field: string;
            sort: SortDirection;
          },
        }),
        defaultFilters,
        filtersState: gridConfigs?.filtersState,
        advanceFilterParams: advanceFilterParam,
        headers: { Accept: fileType },
        responseType: 'blob',
        fields: RenderExportList(
          ALL_BOOKINGS_COLUMN_FIELDS(bookingListView, user),
          columnVisibility
        ),
      });
      const blob = new Blob([res.data], { type: fileType });
      const url = URL.createObjectURL(blob);
      downloadFile(url, `booking_list_${bookingListView}_${new Date().getTime()}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ExportButton
        onClick={(event) => setActionAnchorEl(event.currentTarget)}
        isSelected={Boolean(actionAnchorEl)}
      />
      <StyledMenu
        onClose={closeMenu}
        anchorEl={actionAnchorEl}
        open={Boolean(actionAnchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() =>
            download('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          }
        >
          <Typography variant={isMobile ? 'body2' : 'body1'}>{translate('xlsx_file')}</Typography>
        </MenuItem>

        <MenuItem onClick={() => download('text/csv')}>
          <Typography variant={isMobile ? 'body2' : 'body1'}>{translate('csv_file')}</Typography>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export const CheckInReadyComponent = ({
  checkInReadyStatuses,
  hideSomeCheckInReadyItems,
  checkForCheckInReadySettings,
}: {
  checkInReadyStatuses: CheckInReadyStatus[];
  hideSomeCheckInReadyItems: boolean;
  checkForCheckInReadySettings: boolean;
}) => {
  const { translate } = useLocales(['booking', 'bookingList', 'common']);
  const iconsToExclude = ['GUEST_INFO', 'TERMS_AND_CONDITIONS', 'GUEST_VERIFICATION'];

  const getTooltipText = (name: string) => {
    switch (name) {
      case 'GUEST_INFO':
        return 'guest_info_status';
      case 'TERMS_AND_CONDITIONS':
        return 'terms_and_conditions_status';
      case 'GUEST_VERIFICATION':
        return 'verification_status';
      case 'PAYMENT':
        return 'payment_status';
      case 'SECURITY_DEPOSIT':
        return 'security_deposit_status';
      default:
        return '';
    }
  };

  const iconConfigs: any[] = [];
  checkInReadyStatuses.forEach((item) => {
    iconConfigs.push({
      ...getCheckInReadyConfig(item),
      hide: checkForCheckInReadySettings
        ? !item.isChecked
        : hideSomeCheckInReadyItems && iconsToExclude.includes(item.name),
      tooltip: getTooltipText(item.name),
    });
  });

  return (
    <Stack direction="row" spacing={1} width={'100%'} justifyContent={'space-between'}>
      {iconConfigs.map((config) =>
        !config.hide ? (
          <Tooltip
            key={config.icon}
            title={
              <Stack>
                <Typography variant="caption" fontWeight="fontWeightBold">
                  {translate(config.tooltip)}
                </Typography>
                <Typography variant="caption">
                  {translate(`booking.check_in_ready.${config.status}`)}
                </Typography>
              </Stack>
            }
            arrow
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={(theme) => ({
                width: 24,
                height: 24,
                backgroundColor: alpha(theme.palette[config.color].main, 0.08),
                borderRadius: '50%',
              })}
            >
              <SvgIconStyle
                src={config.icon}
                sx={{ backgroundColor: `${config.color}.dark`, width: 16, height: 16 }}
              />
            </Stack>
          </Tooltip>
        ) : null
      )}
    </Stack>
  );
};

const CustomEditBookingStatusCell = ({ rowParams, bookingStatusOptions }) => {
  const apiRef = useGridApiContext();
  const [actionDrawer, setActionDrawer] = useState<{ open: boolean; action: string }>({
    open: false,
    action: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [bookingCheckInOutConfig, setBookingCheckInOutConfig] = useState<any>({});
  const [state, setState] = useState<{ label: string; value: string } | null>({
    label: rowParams.row.booking_status,
    value: rowParams.row.booking_status,
  });

  const { loading: typeLoading } = useAsync(
    BookingApi.fetchBookingConfigs,
    [rowParams.row.id],
    [rowParams.row.id],
    false,
    rowParams.row.id,
    undefined,
    (succeed, response) => {
      setBookingCheckInOutConfig(
        checkInOutConfigNormalizer(response.data[rowParams.row.id.toString()])
      );
    }
  );

  const onClose = (ignoreModifications?: boolean) => {
    apiRef.current.stopCellEditMode({
      id: rowParams.row.id,
      field: rowParams.field,
      ...(ignoreModifications !== undefined && { ignoreModifications }),
    });
  };

  const handleAction = async (onEnd: VoidFunction) => {
    if (!state?.value || state?.value === rowParams.row.booking_status) onClose(true);
    else {
      setLoading(true);
      switch (state?.value) {
        case 'Checked in':
        case 'Checked out':
          onEnd();
          setActionDrawer({
            open: true,
            action: state.value === 'Checked in' ? 'checkedin' : 'checkedout',
          });
          break;
        case 'Cancelled':
        case 'Scheduled for Cancellation':
          onEnd();
          setActionDrawer({
            open: true,
            action: state.value === 'Cancelled' ? 'cancelled' : 'scheduledForCancellation',
          });
          break;
        case 'Booked':
        case 'No Show':
          await PropertyApi.changeBookingStatus({
            booking_id: rowParams.row.id,
            booking_status: state.value,
          })
            .then((response) => {
              if (response.status >= 200 && response.status < 300) {
                apiRef.current.setEditCellValue({
                  id: rowParams.row.id,
                  field: rowParams.field,
                  value: state.value,
                });
                onClose();
                setActionDrawer({
                  open: false,
                  action: rowParams.value === 'Booked' ? 'booked' : 'noShow',
                });
                setLoading(false);
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
            });
          break;
        default:
          setLoading(false);
          onClose(true);
          break;
      }
    }
  };

  return (
    <>
      <CustomEditCell
        id={rowParams.row.id}
        field={rowParams.field}
        onSave={handleAction}
        stackProps={{
          minWidth: 265,
          maxWidth: 265,
        }}
      >
        <Autocomplete
          fullWidth
          value={state}
          disabled={loading}
          onChange={(_, value) => {
            setState(value);
          }}
          options={bookingStatusOptions}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              sx={{ width: '140px', 'div:first-of-type': { flexWrap: 'nowrap' } }}
            />
          )}
        />
      </CustomEditCell>

      <BookingActionDrawer
        open={actionDrawer.open}
        action={actionDrawer.action}
        bookingId={rowParams.row.id}
        bookingStatus={rowParams.row.booking_status}
        bookingSource={rowParams.row.creation_source}
        arrivalDate={rowParams.row.arrival_date.date}
        arrivalTime={rowParams.row.arrival_date.time ?? ''}
        departureDate={rowParams.row.departure_date.date}
        departureTime={rowParams.row.departure_date.time ?? ''}
        numOfAdultGuests={rowParams.row.number_of_adult_guests}
        numOfChildGuests={rowParams.row.number_of_child_guests}
        channel={{
          id: rowParams.row.channel.id,
          name: rowParams.row.channel.name,
          canConnectToChannel: false,
        }}
        property={rowParams.row.property}
        unitType={rowParams.row.unit_type}
        unit={null}
        currency={rowParams.row.total_sale.currencySymbol}
        currencySymbol={rowParams.row.total_sale.currencySymbol}
        bookingCheckInOutConfig={bookingCheckInOutConfig}
        onClose={() => {
          setLoading(false);
          setActionDrawer({ open: false, action: '' });
        }}
        onAction={() => {
          apiRef.current.setEditCellValue({
            id: rowParams.row.id,
            field: rowParams.field,
            value: state?.value,
          });
          onClose();
          setActionDrawer({ open: false, action: '' });
          setLoading(false);
        }}
      />
    </>
  );
};

const CustomEditBookingTagsCell = ({ params }) => {
  const apiRef = useGridApiContext();

  return (
    <BookingTagDrawer
      open={Boolean(params.row.id)}
      bookingId={params.row.id}
      bookingTags={params.row.booking_tags || []}
      onClose={() => apiRef.current.stopCellEditMode({ id: params.row.id, field: params.field })}
      onAction={(data) =>
        apiRef.current.setEditCellValue({ id: params.row.id, field: params.field, value: data })
      }
    />
  );
};

export const BOOKING_LIST_COLUMNS = (
  columnOrders: string[],
  hideColumns: ColumnField[],
  hideSomeCheckInReadyItems: boolean,
  bookingStatusOptions: any[],
  checkForCheckInReadySettings: boolean,
  hasCheckOutReadyColumn: boolean,
  selectionRows: GridRowSelectionModel,
  onPrepaidChangeCallback: (bookingIds: number[]) => void,
  gridType: BookingListViewValue,
  onMarkFinaliseAction: (bookingId: number) => void,
  onFinaliseAction: (bookingId: number) => void,
  onFinaliseBulkAction: (bookingIds: number[]) => void,
  onMarkAsFinaliseBulkAction: (bookingIds: number[]) => void
): BookingGridColumn[] => {
  const { translate, timeFormat, shortDateFormat, currentLang } = useLocales([
    'bookingList',
    'common',
  ]);
  const { VIEW: canViewGuest } = useAcl('PeymansBookingBundleGuest');
  const { EDIT: hasBookingEditAccess } = useAcl('PeymansBookingBundleBooking');
  const { EXECUTE: hasFinanceFieldAccess } = useAcl('peymans_finanace_fields_show');
  const { VIEW: hasCheckInReadyConfigViewAccess } = useAcl(
    'PeymansBookingBundleCheckInReadyConfig'
  );

  const shouldBeSorted = columnOrders.length > 0;

  const formatDate = (date: Date, pattern: any) =>
    fDT({
      date: utcToZonedTime(date, 'UTC'),
      pattern,
      options: { locale: currentLang.dateLocale },
    });

  const printGuestInfo = (guest: any, contact_info: any) => {
    const { firstName, lastName } = guest;
    const { email, mobile } = contact_info;

    if (firstName || lastName) {
      return `${firstName || ''} ${lastName || ''}`;
    } else if (email) {
      return email;
    } else {
      return mobile;
    }
  };

  const COLUMNS: BookingGridColumn[] = [
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'id',
      headerName: translate('id'),
      type: 'number',
      hideable: false,
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'guest',
      headerName: translate('guest'),
      minWidth: 160,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['guest']>) => {
        if (params.row) {
          const { adults, children } = params.row.guest;

          return canViewGuest ? (
            <CustomRenderCell
              title={printGuestInfo(params.row.guest, params.row.contact_info)}
              subTitle={`${translate('adult', { count: adults })}${
                children ? `, ${translate('child', { count: children })}` : ''
              }`}
            />
          ) : (
            <CustomRenderCell
              title={`${translate('adult', { count: adults })}${
                children ? `, ${translate('child', { count: children })}` : ''
              }`}
            />
          );
        }
      },
    },
    ...(!hideColumns.includes('id_verified')
      ? [
          {
            ...DEFAULT_COLUMN_CONFIG,
            field: 'id_verified',
            headerName: translate('id_verified'),
            sortable: false,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, BookingGridRow['id_verified']>) => (
              <CustomRenderCell
                disabledTooltip
                title={translate(Boolean(params.value) ? 'yes' : 'no')}
              />
            ),
          } as BookingGridColumn,
        ]
      : []),
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'arrival_date',
      headerName: translate('arrival'),
      minWidth: 130,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['arrival_date']>) => {
        if (params.value) {
          const { date, time } = params.value;
          return (
            <CustomRenderCell
              title={formatDate(new Date(date), shortDateFormat)}
              subTitle={time ? formatDate(new Date(time), timeFormat) : null}
            />
          );
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'departure_date',
      headerName: translate('departure'),
      minWidth: 130,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['departure_date']>) => {
        if (params.value) {
          const { date, time } = params.value;
          return (
            <CustomRenderCell
              title={formatDate(new Date(date), shortDateFormat)}
              subTitle={time ? formatDate(new Date(time), timeFormat) : null}
            />
          );
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'city',
      headerName: translate('city'),
      width: 160,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['city']>) => {
        if (params.value) {
          return (
            <CustomRenderCell
              disabledTooltip
              title={params.value ?? undefined}
              titleProps={{ width: 'inherit' }}
            />
          );
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'location',
      headerName: translate('location'),
      width: 206,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['location']>) => {
        if (params.value) {
          const { property, unit } = params.value;
          return (
            <CustomRenderCell
              title={property.name}
              subTitle={
                unit || (
                  <Typography variant="caption" color="warning.dark">
                    {translate('unallocated')}
                  </Typography>
                )
              }
              tooltipTitle={
                <Stack>
                  <Typography variant="caption" fontWeight="fontWeightBold">
                    {property.name}
                  </Typography>
                  {unit && <Typography variant="caption">{unit}</Typography>}
                </Stack>
              }
            />
          );
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'unit_type',
      headerName: translate('unit_type'),
      width: 216,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['unit_type']>) => (
        <CustomRenderCell
          title={params.value?.name ?? undefined}
          titleProps={{ width: 'inherit' }}
        />
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'rate_plan',
      headerName: translate('rate_plan'),
      width: 160,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['rate_plan']>) => (
        <CustomRenderCell
          title={params.value?.name ?? undefined}
          subTitle={params.value?.type}
          titleProps={{ width: 'inherit' }}
        />
      ),
    },
    ...(canViewGuest
      ? [
          {
            ...DEFAULT_COLUMN_CONFIG,
            field: 'contact_info',
            headerName: translate('contact_info'),
            minWidth: 150,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, BookingGridRow['contact_info']>) => {
              if (params.value) {
                const { mobile, email } = params.value;
                return <CustomRenderCell title={mobile} subTitle={email} />;
              }
            },
          } as BookingGridColumn,
        ]
      : []),
    ...(hasFinanceFieldAccess
      ? [
          {
            ...DEFAULT_COLUMN_CONFIG,
            field: 'sd_charged',
            headerName: translate('sd_charged'),
            width: 120,
            align: 'right',
            headerAlign: 'right',
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, BookingGridRow['sd_charged']>) => {
              if (params.value) {
                const { price, currencySymbol } = params.value;
                return (
                  <CustomRenderCell
                    cellAlignment={params.colDef.align}
                    title={fCurrencyWithSymbol(price, currencySymbol)}
                  />
                );
              }
            },
          } as BookingGridColumn,
        ]
      : []),
    ...(hasFinanceFieldAccess
      ? [
          {
            ...DEFAULT_COLUMN_CONFIG,
            field: 'total_due',
            headerName: translate('total_due'),
            align: 'right',
            headerAlign: 'right',
            width: 120,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, BookingGridRow['total_due']>) => {
              if (params.value) {
                const { price, currencySymbol } = params.value;
                return (
                  <CustomRenderCell
                    cellAlignment={params.colDef.align}
                    title={fCurrencyWithSymbol(price, currencySymbol)}
                  />
                );
              }
            },
          } as BookingGridColumn,
        ]
      : []),
    ...(hasFinanceFieldAccess
      ? [
          {
            ...DEFAULT_COLUMN_CONFIG,
            field: 'total_sale',
            headerName: translate('total_sale'),
            align: 'right',
            headerAlign: 'right',
            width: 120,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, BookingGridRow['total_sale']>) => {
              if (params.value) {
                const { price, currencySymbol } = params.value;
                return (
                  <CustomRenderCell
                    cellAlignment={params.colDef.align}
                    title={fCurrencyWithSymbol(price, currencySymbol)}
                  />
                );
              }
            },
          } as BookingGridColumn,
        ]
      : []),
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'channel',
      headerName: translate('channel'),
      width: 180,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['channel']>) => {
        if (params.value) {
          const { logo, name } = params.value;
          return (
            <Stack width="100%" height="100%" justifyContent="center">
              <Tooltip title={name} placement="top" arrow>
                <Stack flexDirection={'row'}>
                  <Avatar
                    src={`${process.env.REACT_APP_HOST_API_KEY}${logo}`}
                    sx={{ width: 24, height: 24, backgroundColor: 'transparent' }}
                  >
                    <img
                      width={24}
                      height={24}
                      src="/icons/calendar/ic_default_channel_24dp.svg"
                      alt={'ic_default_channel_24dp'}
                    />
                  </Avatar>
                  <Typography variant={'body2'} sx={{ ml: 1 }}>
                    {name}
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
          );
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'payment_collection_type',
      headerName: translate('collection_type'),
      width: 140,
      renderCell: (
        params: GridRenderCellParams<any, BookingGridRow['payment_collection_type']>
      ) => <CustomRenderCell title={params.value ?? undefined} titleProps={{ width: 'inherit' }} />,
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'booking_no',
      headerName: translate('booking_no'),
      width: 135,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['booking_no']>) => {
        const { value } = params;
        return <CustomRenderCell title={value} />;
      },
    },
    ...(gridType === ('awaiting_finalisation' as BookingListViewValue)
      ? [
          {
            ...DEFAULT_COLUMN_CONFIG,
            field: 'ota_commission',
            headerName: translate('channel_commission'),
            width: 180,
            align: 'right',
            headerAlign: 'right',
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, BookingGridRow['ota_commission']>) => {
              if (params.value) {
                const { price, currencySymbol } = params.value;
                return (
                  <CustomRenderCell
                    cellAlignment={params.colDef.align}
                    title={fCurrencyWithSymbol(price, currencySymbol)}
                  />
                );
              } else {
                return (
                  <Typography variant="body" color={'text.disabled'} noWrap>
                    {translate('not_set')}
                  </Typography>
                );
              }
            },
          } as BookingGridColumn,
        ]
      : []),

    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'booking_status',
      headerName: translate('booking_status'),
      editable: gridType !== ('awaiting_finalisation' as BookingListViewValue),
      width: 220,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['booking_status']>) => {
        if (params.value) {
          const { value } = params;
          return (
            <CustomRenderCell
              {...params}
              title={translate(value.toLowerCase().replace(/ /g, '_'))}
            />
          );
        }
      },
      renderEditCell: (params: any) => (
        <CustomEditBookingStatusCell
          rowParams={params}
          bookingStatusOptions={bookingStatusOptions}
        />
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'deposit_status',
      headerName: translate('security_deposit_status'),
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['deposit_status']>) => {
        if (params.value) {
          const { value } = params;
          return <CustomRenderCell title={translate(value)} />;
        }
      },
    },
    ...(!hideColumns.includes('check_in_ready') && hasCheckInReadyConfigViewAccess
      ? [
          {
            ...DEFAULT_COLUMN_CONFIG,
            field: 'check_in_ready',
            headerName: translate(hasCheckOutReadyColumn ? 'check_out_ready' : 'check_in_ready'),
            width: hideSomeCheckInReadyItems ? 76 : 172,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, BookingGridRow['check_in_ready']>) => {
              if (params.value) {
                const { value } = params;
                const areCheckInReadySettingsDisabled = value.every((item) => !item.isChecked);
                return checkForCheckInReadySettings && areCheckInReadySettingsDisabled ? (
                  <CustomRenderCell title={undefined} />
                ) : (
                  <CheckInReadyComponent
                    checkInReadyStatuses={value}
                    hideSomeCheckInReadyItems={hideSomeCheckInReadyItems}
                    checkForCheckInReadySettings={checkForCheckInReadySettings}
                  />
                );
              }
            },
          } as BookingGridColumn,
        ]
      : []),
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'creation_source',
      headerName: translate('creation_source'),
      minWidth: 140,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['creation_source']>) => (
        <CustomRenderCell title={params.value ?? undefined} />
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'booking_tags',
      headerName: translate('booking_tags'),
      editable: gridType !== ('awaiting_finalisation' as BookingListViewValue),
      disableColumnMenu: true,
      sortable: false,
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['booking_tags']>) => (
        <CustomRenderCell
          {...params}
          disabledTooltip
          title={
            params.row.booking_tags.length > 0 ? (
              <Tooltip title={params.row.booking_tags.map((tag: any) => tag.name).join(', ')}>
                <Stack width="100%">
                  <Tag
                    options={params.row.booking_tags || []}
                    limitTags={1}
                    canEdit={false}
                    canDelete={false}
                    onAdd={() => {}}
                    onClick={() => {}}
                    onDelete={() => {}}
                  />
                </Stack>
              </Tooltip>
            ) : undefined
          }
        />
      ),
      renderEditCell: (params: GridRenderCellParams<any, BookingGridRow['booking_tags']>) => (
        <CustomEditBookingTagsCell params={params} />
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'number_of_nights',
      headerName: translate('number_of_nights'),
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['number_of_nights']>) => {
        if (params.value) {
          return <CustomRenderCell disabledTooltip title={params.value} />;
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'number_of_adult_guests',
      headerName: translate('number_of_adult_guests'),
      minWidth: 140,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['number_of_adult_guests']>) => {
        if (params.value) {
          return <CustomRenderCell disabledTooltip title={params.value} />;
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'number_of_child_guests',
      headerName: translate('number_of_child_guests'),
      minWidth: 140,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['number_of_child_guests']>) => {
        if (params.value) {
          return <CustomRenderCell disabledTooltip title={params.value} />;
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'created_at',
      headerName: translate('booking_date'),
      width: 120,
      renderCell: (params: GridRenderCellParams<any, BookingGridRow['created_at']>) => (
        <CustomRenderCell
          disabledTooltip
          title={params.value ? formatDate(new Date(params.value), shortDateFormat) : undefined}
        />
      ),
    },
    ...(hasBookingEditAccess
      ? [
          {
            ...DEFAULT_COLUMN_CONFIG,
            field: 'action',
            headerName: translate('action'),
            width: 80,
            align: 'center',
            headerAlign: 'center',
            hideable: false,
            disableReorder: true,
            ...(!selectionRows.length && {
              renderCell: (params) => {
                const selectedRow = params.row;
                return (
                  <BookingActionComponents
                    activeRowData={selectedRow}
                    onPrepaidChangeCallback={onPrepaidChangeCallback}
                    gridType={gridType}
                    onMarkFinaliseAction={(bookingId: number) => onMarkFinaliseAction(bookingId)}
                    onFinaliseAction={(bookingId: number) => onFinaliseAction(bookingId)}
                  />
                );
              },
            }),
            ...(selectionRows.length && {
              renderHeader: () =>
                gridType !== ('awaiting_finalisation' as BookingListViewValue) ? (
                  <PrePaidChannelHeaderActionComponents
                    prePaidChannelDataSelectedRows={selectionRows}
                    onPrepaidChangeCallback={onPrepaidChangeCallback}
                  />
                ) : (
                  <AwaitingFinalisationHeaderActionComponents
                    selectedRows={selectionRows}
                    onFinaliseBulkAction={(bookingIds: number[]) => {
                      onFinaliseBulkAction(bookingIds);
                    }}
                    onMarkAsFinaliseBulkAction={(bookingIds: number[]) => {
                      onMarkAsFinaliseBulkAction(bookingIds);
                    }}
                  />
                ),
            }),
          } as BookingGridColumn,
        ]
      : []),
  ];

  return shouldBeSorted ? sortColumnsByReference(COLUMNS, columnOrders) : COLUMNS;
};

export const BOOKING_LIST_ROWS = (bookings: Booking[]): BookingGridRow[] =>
  bookings.map((booking) => ({
    id: booking.id,
    guest: {
      id: booking.guest?.id || null,
      firstName: booking.guest?.first_name || '',
      lastName: booking.guest?.last_name || '',
      adults: booking.number_of_adult_guests,
      children: booking.number_of_child_guests,
    },
    arrival_date: {
      date: booking.arrival_date,
      time: booking.arrival_time,
    },
    departure_date: {
      date: booking.departure_date,
      time: booking.departure_time,
    },
    city: booking.property?.area?.city?.name || '',
    location: {
      property: {
        id: booking.property?.id || 0,
        name: booking.property?.short_name || '',
      },
      unit: booking.unit?.name || '',
    },
    contact_info: {
      mobile: booking.guest?.mobile_number || '',
      email: booking.guest?.email || '',
    },
    sd_charged: {
      price: booking?.charged_amount?.value || 0,
      currencySymbol: fCurrencySymbol(booking?.charged_amount?.currency || ''),
    },
    total_due: {
      price: booking.price_breakdown.total_due,
      currencySymbol: booking.price_breakdown.total_price_currency_symbol,
    },
    total_sale: {
      price: booking.price_breakdown.total_sale,
      currencySymbol: booking.price_breakdown.total_price_currency_symbol,
    },
    channel: booking.channel,
    booking_no: booking.booking_no,
    ota_commission: booking?.ota_commission
      ? {
          price: booking?.ota_commission.value,
          currencySymbol: fCurrencySymbol(booking?.ota_commission.currency),
        }
      : null,
    booking_status: booking.booking_status,
    deposit_status: booking.deposit_status,
    check_in_ready: booking.checkInReadyStatus,
    payment_collection_type: booking.payment_collection_type,
    created_at: booking.created_date,
    number_of_nights: dateToIndex(
      utcToZonedTime(new Date(booking.departure_date), 'UTC'),
      utcToZonedTime(new Date(booking.arrival_date), 'UTC')
    ),
    number_of_adult_guests: booking.number_of_adult_guests,
    number_of_child_guests: booking.number_of_child_guests,
    unit_type: {
      id: booking.unit_type?.id || 0,
      name: booking.unit_type?.name || '',
    },
    rate_plan: {
      name: booking.rate_plan?.name || '',
      type: booking.rate_plan?.type || '',
    },
    creation_source: booking.creation_source || '',
    booking_tags:
      booking.tags && booking.tags.length > 0
        ? booking.tags.map((tag) => ({ id: tag.id, name: tag.name }))
        : [],
    id_verified: booking.guest?.id_verified,
  }));

export const BOOKING_LIST_COLUMNS_VISIBILITY = (value: string): GridColumnVisibilityModel => {
  switch (value) {
    case 'arrivals': {
      return {
        id: false,
        departure_date: false,
        city: false,
        sd_charged: false,
        total_due: false,
        channel: false,
        booking_no: false,
        booking_status: false,
        deposit_status: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        created_at: false,
      };
    }
    case 'departures': {
      return {
        id: false,
        arrival_date: false,
        city: false,
        contact_info: false,
        booking_no: false,
        booking_status: false,
        deposit_status: false,
        check_in_ready: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        id_verified: false,
        created_at: false,
      };
    }
    case 'stay-overs': {
      return {
        id: false,
        arrival_date: false,
        city: false,
        contact_info: false,
        sd_charged: false,
        check_in_ready: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        id_verified: false,
        created_at: false,
      };
    }
    case 'cancellations': {
      return {
        id: false,
        arrival_date: false,
        departure_date: false,
        city: false,
        sd_charged: false,
        check_in_ready: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        id_verified: false,
        created_at: false,
      };
    }
    case 'unallocated_bookings': {
      return {
        id: false,
        city: false,
        contact_info: false,
        sd_charged: false,
        total_due: false,
        deposit_status: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        id_verified: false,
        created_at: false,
      };
    }
    case 'pending_finalisation': {
      return {
        id: false,
        arrival_date: false,
        city: false,
        contact_info: false,
        booking_no: false,
        booking_status: false,
        deposit_status: false,
        check_in_ready: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        id_verified: false,
        created_at: false,
      };
    }
    case 'unmapped_bookings': {
      return {
        id: false,
        city: false,
        contact_info: false,
        sd_charged: false,
        total_due: false,
        deposit_status: false,
        check_in_ready: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        id_verified: false,
        created_at: false,
      };
    }
    case 'all_bookings': {
      return {
        id: false,
        city: false,
        contact_info: false,
        sd_charged: false,
        total_due: false,
        deposit_status: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        id_verified: false,
        created_at: false,
      };
    }
    case 'awaiting_finalisation': {
      return {
        id: false,
        ota_commission: false,
        city: false,
        contact_info: false,
        sd_charged: false,
        total_due: false,
        deposit_status: false,
        check_in_ready: false,
        unit_type: false,
        rate_plan: false,
        total_sale: false,
        payment_collection_type: false,
        creation_source: false,
        booking_tags: false,
        number_of_nights: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        id_verified: false,
        created_at: false,
      };
    }
    default: {
      return {};
    }
  }
};
