import React, { FC } from 'react'
import { Typography } from '@mui/material';
import commonAPI from 'src/api/api';
import PaginatedAutocomplete from 'src/components/PaginatedAutocomplete';
import useLocale from 'src/hooks/useLocales';
import { TagOption } from 'src/@types/booking';
import { renderOption } from 'src/utils/autocomplete';

type Props = {
  tags: TagOption[];
  setTags: (value: TagOption[]) => void;
};

const TagAutocompleteInput: FC<Props> = ({ tags, setTags }) => {
  const { translate } = useLocale('common');

  const getOptionLabel = (option) => option.content;

  const onChange = (event, value) => {
    if (value) {
      const lastItem = value.at(-1);
      if (value.length < tags.length || (lastItem && !tags.find((tag) => tag.content === lastItem.content))) {
        setTags(value);
      }
    }
  };

  return (
    <PaginatedAutocomplete
      freeSolo
      multiple
      fullWidth
      inputSize={'small'}
      disableClearable
      value={tags}
      onChange={onChange}
      getOptionsRequest={async ({ key }) => commonAPI.fetchSuggestTags(key)}
      getOptionTitle={getOptionLabel}
      optionIsSelected={(option) => tags.some(
        (tag) => option.type === 'created' ? tag.id === option.id : tag.content.toLowerCase() === option.content.toLowerCase()
      )}
      isOptionEqualToValue={(option, value) => option.type === 'created'
        ? option.id === value.id
        : option.content === value.content
      }
      getChipLabel={getOptionLabel}
      getChipColor={() => 'default'}
      overrideOptions={(options, res) => [
        ...options,
        ...res.data.map((tag) => ({ type: 'created', content: tag.name, id: tag.id })),
      ]}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue.toLowerCase() === option.content.toLowerCase());
        if (inputValue !== '' && !isExisting) {
          options.unshift({ type: 'custom', content: inputValue, inputValue });
        }
        return options;
      }}
      renderOption={renderOption({
        getOptionLabel,
        getOptionDetail: (option) => option.inputValue
          ? <Typography variant="overline" color="text.disabled">{translate('new_tag')}</Typography>
          : <></>,
      })}
    />
  );
}

export default TagAutocompleteInput;