import { addDays, differenceInCalendarDays, format, isSameDay, startOfMonth } from 'date-fns';
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { DateRange, DayCalendarSkeleton } from '@mui/x-date-pickers-pro';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// api
import BookingApi from 'src/api/booking';
import PropertyApi from 'src/api/property';
// theme
import { ColorSchema } from 'src/theme/palette';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { dateToIndex } from 'src/utils/booking';
import { fCurrencyWithSymbol } from 'src/utils/formatNumber';
import CustomDateRangePickerDay from 'src/utils/CustomDateRangePickerDay';
import { fDate, fDateToUTC, fDT, getYearDynamicPattern } from 'src/utils/formatTime';
// components
import Iconify from 'src/components/Iconify';
import SvgIconStyle from 'src/components/SvgIconStyle';
import PriceInput from 'src/components/Input/PriceInput';
import CustomStaticDateRangePicker from 'src/components/CustomStaticDateRangePicker';
// sections
import CustomComponentTemplate from 'src/sections/wizard/listing/components/CustomComponentTemplate';

const PopoverStyle = styled(Popover)(() => ({
  '& .MuiPaper-root': { borderRadius: 16 },
  '& .MuiPickerStaticWrapper-root': {
    '& .PrivatePickersSlideTransition-root': {
      minHeight: 264,
    },
  },
}));

type CustomDateOption = {
  date: Date;
  isStart: boolean;
  isEnd: boolean;
  isHighlight: boolean;
  status: 'default' | ColorSchema;
  isSingle: boolean;
  tooltip: ReactNode | string;
};

interface UnavailableDateOption extends CustomDateOption {
  id?: number;
}

const BookingExtendForm = forwardRef(
  (
    props: {
      bookingId: number;
      arrivalDate: Date | string | number;
      departureDate: Date | string | number;
      numberOfAdultGuests: number;
      numberOfChildGuests: number;
      bookingCurrency: string;
      bookingCurrencySymbol: string;
      property: { id: number; name: string };
      unitType: { id: number; name: string };
      unit: { id: number; name: string } | null;
      onError;
      onFinish;
    },
    ref
  ) => {
    const theme = useTheme();
    const { shortDateFormat } = useLocales();
    const { translate, currentLang } = useLocales(['common', 'calendar', 'booking']);

    const arrivalDate = new Date(props.arrivalDate);
    const departureDate = new Date(props.departureDate);
    const numberOfGuests = props.numberOfAdultGuests + props.numberOfChildGuests;
    const minExtendDate = addDays(new Date(departureDate), 1);
    const maxExtendDate = addDays(new Date(), 729);

    const [extendDate, setExtendDate] = useState<Date | null>(null);
    const [extendDateError, setExtendDateError] = useState<boolean>(false);

    const [totalPrice, setTotalPrice] = useState<any>(null);
    const [totalPriceError, setTotalPriceError] = useState<boolean>(false);
    const [sendPaymentReminder, setSendPaymentReminder] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const datePickerOpen = Boolean(anchorEl);
    const [datePickerLoading, setDatePickerLoading] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<DateRange<Date>>([null, null]);
    const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

    const [customDates, setCustomDates] = useState<Array<CustomDateOption>>([]);
    const [unavailableDates, setUnavailableDates] = useState<Array<UnavailableDateOption>>([]);
    const [isIncluded, setIsIncluded] = useState<boolean>(false);
    const [channel, setChannel] = useState<any>({
      id: 1661,
      code: 'Direct-Extension',
      name: 'Direct-Extension',
      description: 'Direct-Extension',
      hotel_collect: true,
      channel_collect: false,
      commission_type: 'Post',
    });
    const [channelError, setChannelError] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [allChannel, setAllChannels] = useState<any[]>([]);

    const fetchAllChannels = () => {
      PropertyApi.fetchAllChannels()
        .then((response) => {
          setAllChannels(response.data);
        })
        .catch((error) => console.error(error));
    };

    const onChannelChange = (event, value) => {
      setChannel(value);
      setChannelError(false);
    };

    useImperativeHandle(ref, () => ({
      submit() {
        const channelError = channel === null;
        const extraPriceError = totalPrice === null || totalPrice === '' || +totalPrice < 0;
        const extendDateError = extendDate === null;

        if (channelError || extraPriceError || extendDateError) {
          setTotalPriceError(extraPriceError);
          setChannelError(channelError);
          setExtendDateError(extendDateError);
          props.onError();
          return;
        }

        PropertyApi.extendBooking({
          booking: props.bookingId,
          extendDate: fDateToUTC(extendDate).toISOString(),
          total_price: {
            currency: props.bookingCurrency,
            value: +totalPrice,
            base_value: +totalPrice,
            additionalProp1: {},
          },
          priceOverride: true,
          channel: channel.id,
          sendPaymentReminder: sendPaymentReminder,
          unallocated: isIncluded,
        })
          .then(async (response) => {
            if (response.status >= 200 && response.status < 300) {
              props.onFinish(translate('extend.success_message'), response.data);
            }
          })
          .catch((err) => {
            props.onError(err.data, 'extend', () => {
              props.onError(err.data);
            });
          });
      },
    }));

    useEffect(() => {
      checkAvailabilities(departureDate);
      fetchAllChannels();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (extendDate) {
        calculateExtendTotalPrice(extendDate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extendDate]);

    useEffect(() => {
      if (extendDate || hoveredDate) {
        const newCustomDates: CustomDateOption[] = [];
        const endDate =
          extendDate && hoveredDate && extendDate > hoveredDate
            ? extendDate
            : extendDate && hoveredDate && hoveredDate > extendDate
            ? hoveredDate
            : extendDate && !hoveredDate
            ? extendDate
            : hoveredDate ?? new Date();

        const selectedRange = Array.from(
          Array(
            dateToIndex(
              endDate > new Date(unavailableDates[unavailableDates.length - 1].date)
                ? endDate
                : new Date(unavailableDates[unavailableDates.length - 1].date),
              arrivalDate
            ) + 2
          ).keys()
        ).map((i) => addDays(arrivalDate, i));

        // eslint-disable-next-line array-callback-return
        selectedRange.map((date) => {
          newCustomDates.push({
            date: date,
            isStart: unavailableDates.find((item) => isSameDay(item.date, date))?.isStart || false,
            isEnd:
              unavailableDates.find((item) => isSameDay(item.date, date))?.isEnd ||
              (extendDate && isSameDay(date, extendDate)) ||
              false,
            isHighlight:
              unavailableDates.find((item) => isSameDay(item.date, date))?.isHighlight ||
              (extendDate &&
                ignoreTime(date) >= ignoreTime(departureDate) &&
                ignoreTime(date) <= ignoreTime(extendDate)) ||
              (hoveredDate &&
                ignoreTime(date) >= ignoreTime(departureDate) &&
                ignoreTime(date) <= ignoreTime(hoveredDate)) ||
              false,
            isSingle:
              unavailableDates.find((item) => isSameDay(item.date, date))?.isSingle || false,
            status:
              (isIncluded || (hoveredDate && checkIsIncluded(hoveredDate))) &&
              ignoreTime(date) > ignoreTime(departureDate) &&
              ignoreTime(date) <= ignoreTime(endDate)
                ? 'warning'
                : unavailableDates.find((item) => isSameDay(item.date, date))?.status || 'primary',
            tooltip: unavailableDates.find((item) => isSameDay(item.date, date))?.tooltip || '',
          });
        });
        setCustomDates(newCustomDates);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extendDate, hoveredDate]);

    const calculateExtendTotalPrice = (extendDate) => {
      BookingApi.fetchRateAndAvailability(
        props.property.id,
        format(fDateToUTC(departureDate), 'yyyy-MM-dd'),
        format(fDateToUTC(extendDate), 'yyyy-MM-dd'),
        numberOfGuests
      )
        .then(async (response) => {
          if (response.data && response.data.length > 0) {
            const ratePlans = response.data.filter(
              (ratePlan) => +ratePlan.unit_type.id === +props.property.id
            );
            const ratePlan = ratePlans?.find((ratePlan) => ratePlan.is_default);

            if (ratePlan) {
              const subTotalPrice = calculateAccommodationPrice(ratePlan);
              setTotalPrice(subTotalPrice.toFixed(2));
              setTotalPriceError(false);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setTotalPrice('');
        });
    };

    const calculateAccommodationPrice = (ratePlan): number => {
      let totalPrice = 0;
      if (extendDate) {
        ratePlan.rates
          .filter((item) => item.date !== fDate(extendDate, 'yyyy-MM-dd'))
          .forEach((rate) => {
            totalPrice += calculateRatePrice(ratePlan, rate);
          });
      }
      return totalPrice;
    };

    const calculateRatePrice = (ratePlan, rate): number => {
      let totalPrice = rate.price.value;
      if (ratePlan.min_guests < numberOfGuests) {
        totalPrice += (numberOfGuests - ratePlan.min_guests) * rate.extra_guest_price;
      }
      return totalPrice;
    };

    const ignoreTime = (date: Date): Date => new Date(date.setHours(0, 0, 0));

    const checkIsIncluded = (date: Date): boolean => {
      const filteredDates = unavailableDates
        .filter(
          (item) =>
            ignoreTime(item.date) >= ignoreTime(departureDate) &&
            ignoreTime(item.date) <= ignoreTime(date)
        )
        .map((item) => item.id);
      return filteredDates.some((element, index) => filteredDates.indexOf(element) !== index);
    };

    const handleChangeDate = (newDateRange) => {
      const to = newDateRange[0] || newDateRange[1];
      setSelectedDate([null, to]);
      setExtendDate(to);
      setExtendDateError(false);

      const filteredDates = unavailableDates
        .filter(
          (item) =>
            item.date.setHours(0, 0, 0) >= departureDate.setHours(0, 0, 0) &&
            item.date.setHours(0, 0, 0) <= to.setHours(0, 0, 0)
        )
        .map((item) => item.id);

      const isIncluded = filteredDates.some(
        (element, index) => filteredDates.indexOf(element) !== index
      );

      setIsIncluded(isIncluded);

      if (!isIncluded) setAnchorEl(null);
    };

    const handleClearDates = () => {
      setCustomDates(unavailableDates);
      setIsIncluded(false);
      setSelectedDate([null, null]);
      setExtendDate(null);
    };

    const checkAvailabilities = (startDate: Date | null) => {
      const currentBookingRange = Array.from(
        Array(dateToIndex(departureDate, arrivalDate) + 1).keys()
      ).map((i) => addDays(arrivalDate, i));

      // eslint-disable-next-line array-callback-return
      currentBookingRange.map((date) => {
        setCustomDates((customDates) => [
          ...customDates,
          {
            date: date,
            isStart: format(date, 'yyy-MM-dd') === format(arrivalDate, 'yyy-MM-dd'),
            isEnd: format(date, 'yyy-MM-dd') === format(departureDate, 'yyy-MM-dd'),
            isHighlight: date >= arrivalDate && date <= departureDate,
            isSingle: date > arrivalDate && date < departureDate,
            status: 'default',
            tooltip: translate('booking.extend_drawer.current_booking'),
          },
        ]);
        setUnavailableDates((unavailableDates) => [
          ...unavailableDates,
          {
            id: props.bookingId,
            date: date,
            isStart: format(date, 'yyy-MM-dd') === format(arrivalDate, 'yyy-MM-dd'),
            isEnd: format(date, 'yyy-MM-dd') === format(departureDate, 'yyy-MM-dd'),
            isHighlight: date >= arrivalDate && date <= departureDate,
            isSingle: date > arrivalDate && date < departureDate,
            status: 'default',
            tooltip: translate('booking.extend_drawer.current_booking'),
          },
        ]);
      });

      if (props.unit) {
        setDatePickerLoading(true);
        BookingApi.fetchAvailabilities(
          +props.unitType.id,
          props.property.id,
          format(startOfMonth(startDate ?? new Date()), 'yyyy-MM-dd'),
          format(addDays(startDate ?? new Date(), 31), 'yyyy-MM-dd')
        )
          .then((response) => {
            if (response.data.length !== 0) {
              // eslint-disable-next-line array-callback-return
              const unavailableItems = response.data
                // eslint-disable-next-line array-callback-return
                .filter((item) => {
                  if (props.unit)
                    return (
                      new Date(item.date) >= departureDate &&
                      (item.details_v2.units[props.unit.id].bookings.length > 0 ||
                        item.details_v2.units[props.unit.id].blocks.length > 0)
                    );
                })
                .reverse();

              // eslint-disable-next-line array-callback-return
              unavailableItems.map((item, index) => {
                if (props.unit) {
                  const { bookings } = item.details_v2.units[props.unit.id];
                  const { blocks } = item.details_v2.units[props.unit.id];
                  const currentBooking = item.details_v2.units[props.unit.id].bookings[0];
                  const nextBooking =
                    unavailableItems[index + 1]?.details_v2.units[props.unit.id].bookings[0];
                  const currentBlock = item.details_v2.units[props.unit.id].blocks[0];
                  const nextBlock =
                    unavailableItems[index + 1]?.details_v2.units[props.unit.id].blocks[0];
                  if (bookings.length > 0 || blocks.length > 0) {
                    setCustomDates((customDates) => [
                      ...customDates,
                      {
                        date: new Date(item.date),
                        isStart: false,
                        isEnd: false,
                        isHighlight: false,
                        isSingle: true,
                        status: 'warning',
                        tooltip: generateTooltip([
                          bookings.length > 0 ? translate('booking.extend_drawer.sold_out') : '',
                          blocks.length > 0 ? translate('booking.extend_drawer.blocked_date') : '',
                        ]),
                      },
                    ]);
                    setUnavailableDates((unavailableDates) => [
                      ...unavailableDates,
                      {
                        id: bookings[0] || blocks[0],
                        date: new Date(item.date),
                        isStart: false,
                        isEnd: false,
                        isHighlight: false,
                        isSingle: true,
                        status: 'warning',
                        tooltip: generateTooltip([
                          bookings.length > 0 ? translate('booking.extend_drawer.sold_out') : '',
                          blocks.length > 0 ? translate('booking.extend_drawer.blocked_date') : '',
                        ]),
                      },
                    ]);

                    if (
                      (!nextBooking && !nextBlock) ||
                      (nextBooking && nextBooking !== currentBooking) ||
                      (nextBlock && nextBlock !== currentBlock)
                    ) {
                      setCustomDates((customDates) => [
                        ...customDates,
                        {
                          date: addDays(new Date(item.date), 1),
                          isStart: false,
                          isEnd: false,
                          isHighlight: false,
                          isSingle: true,
                          status: 'warning',
                          tooltip: generateTooltip([
                            bookings.length > 0 ? translate('booking.extend_drawer.sold_out') : '',
                            blocks.length > 0
                              ? translate('booking.extend_drawer.blocked_date')
                              : '',
                          ]),
                        },
                      ]);
                      setUnavailableDates((unavailableDates) => [
                        ...unavailableDates,
                        {
                          id: bookings[0] || blocks[0],
                          date: addDays(new Date(item.date), 1),
                          isStart: false,
                          isEnd: false,
                          isHighlight: false,
                          isSingle: true,
                          status: 'warning',
                          tooltip: generateTooltip([
                            bookings.length > 0 ? translate('booking.extend_drawer.sold_out') : '',
                            blocks.length > 0
                              ? translate('booking.extend_drawer.blocked_date')
                              : '',
                          ]),
                        },
                      ]);
                    }
                  }
                }
              });
            }
            setDatePickerLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setAnchorEl(null);
            setDatePickerLoading(false);
          });
      }
    };

    const generateTooltip = (tooltips: Array<string>): ReactNode | string => {
      const tooltipMessages = tooltips.filter((tooltip) => tooltip !== '');
      if (tooltipMessages.length > 1) {
        return tooltipMessages.map((tooltip, index) => (
          <Typography key={index} variant="caption" component="p">
            •&nbsp;&nbsp;{tooltip}
          </Typography>
        ));
      }
      return tooltipMessages[0];
    };

    const CustomActions = (
      <React.Fragment>
        <Divider />
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ padding: theme.spacing(2, 3) }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={1}
            sx={{ color: theme.palette.error.main }}
          >
            <SvgIconStyle
              src={'/icons/calendar/ic_unallocated.svg'}
              color="inherit"
              sx={{ width: 16, height: 16, mb: 0.5 }}
            />
            <Typography variant="caption" color="inherit">
              {translate('booking.extend_drawer.unallocated_warning_msg')}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={2.5}>
            <Button size="small" onClick={handleClearDates} color="inherit">
              {translate('booking.extend_drawer.clear_dates')}
            </Button>
            <Button
              size="small"
              onClick={() => setAnchorEl(null)}
              sx={{
                fontWeight: 600,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.warning.dark,
                '&:hover': { backgroundColor: theme.palette.warning.dark },
              }}
            >
              {translate('confirm')}
            </Button>
          </Box>
        </Stack>
      </React.Fragment>
    );

    const CustomFooter = (
      <Stack direction={'row'} justifyContent={'center'} p={2}>
        {extendDate ? (
          <Typography variant="caption" color="grey.700">
            {differenceInCalendarDays(extendDate, departureDate) > 1
              ? translate('booking.extend_drawer.extending_nights', {
                  nights: differenceInCalendarDays(extendDate, departureDate),
                })
              : translate('booking.extend_drawer.extending_night', {
                  night: differenceInCalendarDays(extendDate, departureDate),
                })}
          </Typography>
        ) : (
          <Typography variant="caption" color="grey.700">
            {format(arrivalDate, getYearDynamicPattern(arrivalDate))}
            &nbsp;-&nbsp;
            {format(departureDate, getYearDynamicPattern(departureDate))}
            &nbsp;
            {dateToIndex(departureDate, arrivalDate) > 1
              ? `(${dateToIndex(departureDate, arrivalDate)} ${translate('nights')})`
              : `(${dateToIndex(departureDate, arrivalDate)} ${translate('night')})`}
          </Typography>
        )}
      </Stack>
    );

    return (
      <Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="body2" color="text.secondary">
            {translate('booking.extend_drawer.current_booking')}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {fDT({
              date: new Date(arrivalDate),
              pattern: shortDateFormat,
              options: { locale: currentLang.dateLocale },
            })}
            &nbsp;-&nbsp;
            {fDT({
              date: new Date(departureDate),
              pattern: shortDateFormat,
              options: { locale: currentLang.dateLocale },
            })}
          </Typography>
        </Box>
        <CustomComponentTemplate label={translate('extend.channel')} sx={{ mt: 3.5 }}>
          <Autocomplete
            loading={true}
            fullWidth
            options={allChannel}
            value={channel}
            onChange={onChannelChange}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderOption={(props, option: any) => (
              <Box
                {...props}
                component="li"
                display="flex"
                gap={theme.spacing(2)}
                alignItems={'center'}
              >
                <Avatar
                  variant="circular"
                  src={process.env.REACT_APP_HOST_API_KEY + option?.logo}
                  style={{ width: 24, height: 24, backgroundColor: 'transparent' }}
                >
                  <img
                    alt={'default channel'}
                    width={24}
                    height={24}
                    src={`/icons/calendar/ic_default_channel_24dp.svg`}
                  />
                </Avatar>
                <Box
                  component={Typography}
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: option.name }}
                />
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={channelError}
                onChange={() => setChannelError(Boolean(!channel))}
              />
            )}
          />
        </CustomComponentTemplate>
        <CustomComponentTemplate label={translate('extend.extend_to_date')} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            autoComplete="off"
            value={
              extendDate
                ? fDT({
                    date: extendDate,
                    pattern: shortDateFormat,
                    options: { locale: currentLang.dateLocale },
                  })
                : ''
            }
            onClick={(event) => setAnchorEl(event.currentTarget)}
            error={extendDateError}
            helperText={
              !anchorEl &&
              (extendDateError
                ? translate('extend.pick_date')
                : extendDate
                ? differenceInCalendarDays(extendDate, departureDate) > 1
                  ? translate('booking.extend_drawer.extending_nights', {
                      nights: differenceInCalendarDays(extendDate, departureDate),
                    })
                  : translate('booking.extend_drawer.extending_night', {
                      night: differenceInCalendarDays(extendDate, departureDate),
                    })
                : translate('booking.extend_drawer.date_helper_text', {
                    date: fDT({
                      date: departureDate,
                      pattern: shortDateFormat,
                      options: { locale: currentLang.dateLocale },
                    }),
                  }))
            }
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="start">
                  {extendDate && (
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClearDates();
                      }}
                    >
                      <Iconify icon={'mdi-close'} width={20} height={20} />
                    </IconButton>
                  )}
                  <IconButton>
                    <Iconify icon={'mdi-calendar-blank'} width={20} height={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ '& .MuiInputBase-root': { pr: 0 } }}
          />
          <PopoverStyle
            open={datePickerOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <CustomStaticDateRangePicker
              loading={datePickerLoading}
              value={selectedDate}
              onChange={handleChangeDate}
              minDate={minExtendDate}
              maxDate={maxExtendDate}
              referenceDate={departureDate}
              onMonthChange={checkAvailabilities}
              renderLoading={() => (
                <Stack sx={{ px: 2 }}>
                  <DayCalendarSkeleton />
                </Stack>
              )}
              slots={{ day: CustomDateRangePickerDay }}
              slotProps={{
                day: ({ day }) =>
                  ({
                    customDates,
                    ButtonProps: {
                      onMouseEnter: () => setHoveredDate(day),
                      onMouseLeave: () => {
                        setHoveredDate(null);
                        setCustomDates(unavailableDates);
                      },
                    },
                  } as any),
              }}
              customFooter={CustomFooter}
            />
            {isIncluded && CustomActions}
          </PopoverStyle>
        </CustomComponentTemplate>
        <Divider sx={{ mt: 3 }} />
        <InputLabel sx={{ ml: '10px', mt: 3.5, display: 'flex', gap: 1 }}>
          <Typography
            variant="body2"
            color="text.primary"
            fontWeight="fontWeightSemiBold"
            fontSize={16}
          >
            {translate('booking.extend_drawer.price')}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {extendDate
              ? `(${differenceInCalendarDays(extendDate, departureDate)} 
            ${
              differenceInCalendarDays(extendDate, departureDate) > 1
                ? translate('nights')
                : translate('night')
            })`
              : ''}
          </Typography>
        </InputLabel>
        <FormControl fullWidth>
          <PriceInput
            value={totalPrice}
            symbol={props.bookingCurrencySymbol}
            onValueChange={(values) => {
              setTotalPrice(values.value);
              setTotalPriceError(false);
            }}
            error={totalPriceError}
          />
          {totalPrice && extendDate && (
            <FormHelperText sx={{ color: theme.palette.text.secondary }}>
              {translate('booking.extend_drawer.price_helper_text', {
                value: fCurrencyWithSymbol(
                  +totalPrice / differenceInCalendarDays(extendDate, departureDate),
                  props.bookingCurrencySymbol
                ),
              })}
            </FormHelperText>
          )}
          {totalPriceError && (
            <FormHelperText sx={{ color: '#ef0168' }}>
              {translate('booking.extend_drawer.price_error')}
            </FormHelperText>
          )}
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              checked={sendPaymentReminder}
              onChange={() => setSendPaymentReminder(!sendPaymentReminder)}
            />
          }
          label={translate('extend.send_payment_reminder') as string}
          sx={{ ml: 0.5, mt: 3.5 }}
        />
      </Box>
    );
  }
);

export default BookingExtendForm;
