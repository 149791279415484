import { GridColDef, GridColumnOrderChangeParams, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { GridColumn } from 'src/@types/common';

export const columnVisibility = (
  columnsId: string[],
  visibility = false
): GridColumnVisibilityModel => {
  const model: GridColumnVisibilityModel = {};
  columnsId.forEach((columnId) => {
    model[columnId] = visibility;
  });
  return model;
};

export const getTogglableColumns = (columns: GridColDef[], hiddenFields: string[]) =>
  columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);

export const reOrderColumns = (
  columns: GridColumn[],
  newOrder: GridColumnOrderChangeParams
) => {
  const orderedColumns = [...columns];
  const { oldIndex, targetIndex } = newOrder;
  const column = orderedColumns[oldIndex];
  orderedColumns.splice(oldIndex, 1);
  orderedColumns.splice(targetIndex, 0, column);
  const referenceColumnOrders: string[] = orderedColumns.map((column) => column.field);
  return { orderedColumns, referenceColumnOrders };
};

export const sortColumnsByReference = (
  columnsToBeSorted: GridColumn[],
  referenceColumnOrders: string[]
) => referenceColumnOrders.reduce(
  (previousValues: GridColumn[], currentValue, currentIndex, { [currentIndex + 1]: nextValue }) => {
    const foundColumn = columnsToBeSorted.find((column) => column.field === currentValue);
    if (foundColumn) previousValues.push(foundColumn);
    if (nextValue) return previousValues;
    else {
      return previousValues.concat(columnsToBeSorted.filter((column) => !previousValues.some((value) => value.field === column.field)));
    }
  }, []
);