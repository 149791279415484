import { Meta, Review, UpdateReviewBody, Nationality, ReviewsInfo } from 'src/@types/common';
import axios from '../utils/axios';
import qs from 'qs';
import { GridConfig } from 'src/components/data-grid/useDataGridConfig';
import { SavedFilterSetting } from 'src/contexts/ViewsContext';

function api() {
  return {
    mock: (body) => {
      const url = `apis/mock`;
      return axios.post(url, body);
    },
    fetchTotalBooking: () => {
      const url = `/apis/rest/api/report/latest/total_booking`;
      return axios.get(url);
    },
    fetchBrands: () => axios.get('/apis/brands'),
    fetchListingBrands: () => axios.get('/apis/brands_listing'),
    fetchXeroAccounts: () => axios.get('/apis/xero_accounts'),
    getXeroConnectionStatus: () => axios.get('/apis/xero/get_xero_connection_status'),
    fetchTotalBookingPeymentStatus: (from: string, to: string) => {
      const url = `/apis/rest/api/report/latest/total_booking_base_payment_status`;
      return axios.get(url, {
        params: {
          from,
          to,
        },
      });
    },

    fetchTotalSalePerDay: (monthYearNumber?: string) => {
      const url = `/apis/rest/api/report/latest/total_sale_per_day`;
      return axios.get(url, {
        params: {
          month_year_number: monthYearNumber,
        },
      });
    },
    fetchTotalSalePerMonth: () => {
      const url = `/apis/rest/api/report/latest/total_sale_per_month`;
      return axios.get(url);
    },

    fetchCustomerReviews: (limit: number) => {
      const url = `/apis/reviews`;
      return axios.get(url, {
        params: {
          'exists[approved]': false,
          limit: limit,
          action: 'review_published',
        },
      });
    },

    fetchUserLicenses: () => {
      const url = `/apis/crm/show-my-licenses`;
      return axios.get(url);
    },

    fetchLicenseCost: (license) => {
      const url = `/apis/crm/get_upgrade_buyLicence_table_data`;
      return axios.post(url, license);
    },

    buyMoreLicenses: (contract_id: string | number, apartment: number, hotel_room: number) => {
      const url = `/apis/crm/buy_license`;
      return axios.post(url, {
        contract_id,
        apartment,
        hotel_room,
      });
    },

    fetchActiveUnits: (contract_id: string | number) => {
      const url = `/apis/crm/get_my_active_unit_count`;
      return axios.post(url, {
        contract_id,
      });
    },

    upgradePlan: (plan) => {
      const url = `/apis/crm/upgrade_subscription`;
      return axios.post(url, {
        ...plan,
      });
    },

    fetchUserInvoices: () => {
      const url = `/apis/zeevou_invoices`;
      return axios.get(url, {
        params: {
          'order[fromDate]': 'desc',
        },
      });
    },

    fetchInvoice: (id: string) => {
      const url = `/apis/zeevou_invoices/${id}`;
      return axios.get(url);
    },

    fetchPayment: (id: string) => {
      const url = `/apis/zeevou_payments/${id}`;
      return axios.get(url);
    },

    newCardPayment: (
      card_number: string,
      name_on_card: string,
      expire_date: string | number,
      cvv: string | number,
      redirect_url: string,
      region: string,
      country: string,
      first_line_address: string,
      city: string,
      post_code: string,
      invoice_id: string | number,
      save_card: boolean
    ) => {
      const url = `/apis/crm/new_zeevou_payment`;
      return axios.post(url, {
        card_number,
        name_on_card,
        expire_date,
        cvv,
        redirect_url,
        region,
        country,
        first_line_address,
        city,
        post_code,
        invoice_id,
        save_card
      });
    },

    savedCardPayment: (
      card_id: string | number,
      cvv: string | number,
      invoice_id: string | number
    ) => {
      const url = `/apis/crm/new_zeevou_payment_with_saved_card`;
      return axios.post(url, {
        card_id,
        cvv,
        invoice_id,
      });
    },

    fetchUserPayments: () => {
      const url = `/apis/zeevou_payments`;
      return axios.get(url, {
        params: {
          'order[paymentDate]': 'desc',
        },
      });
    },

    setUserLocale: (languageCode: string, userId?: string) => {
      const url = `/apis/webapp/user/locale`;
      return axios.post(url, {
        languageCode,
        userId,
      });
    },

    setUserDateTimeFormat: (body: {
      shortDateFormatKey?: string;
      longDateFormatKey?: string;
      timeFormatKey?: string;
      timeZone?: string;
      userId?: string;
    }) => {
      const url = `/apis/webapp/user/dateTimeFormatKey`;
      return axios.post(url, body);
    },

    fetchUserCards: (page, limit) => {
      const url = `/apis/customer_cards`;
      return axios.get(url, {
        params: {
          exists: {
            customer: true,
          },
          page: page,
          limit: limit,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
    },
    deleteCustomerCard: (id: number) => {
      const url = `/apis/zeevou_customer_cards/${id}`;
      return axios.delete(url);
    },
    changeCardActivation: (cardId?: number) => {
      const url = `/apis/zeevou/change_customer_card_activate`;
      return axios.get(url, {
        params: {
          id: cardId,
        },
      });
    },
    updateProfile: (
      userId: number,
      body: {
        first_name?: string;
        middle_name?: string;
        last_name?: string;
        phone?: string | number;
        date_of_birth?: Date | string;
      }
    ) => {
      const url = `/apis/users/${userId}`;
      return axios.put(url, body);
    },
    resetPassword: (
      user_id: string | number,
      current_password: string,
      new_password: string,
      confirm_password: string
    ) => {
      const url = `/apis/user/change_password`;
      return axios.post(url, {
        user_id,
        current_password,
        new_password,
        confirm_password,
      });
    },
    sendVerificationCode: (user_id: string | number, email: string, current_password: string) => {
      const url = `/apis/verify_user/send_verification_code`;
      return axios.post(url, {
        user_id,
        email,
        current_password,
      });
    },
    changeEmailAddress: (
      user_id: string | number,
      current_password: string,
      email: string,
      code: string | number
    ) => {
      const url = `/apis/verify_user/change_email_address`;
      return axios.post(url, {
        user_id,
        current_password,
        email,
        code,
      });
    },
    fetchWebsocketTicket: () => axios.get('/oauth/rest/api/mobile_api/latest/get_ticket'),
    validateSignup: (
      first_name: string,
      last_name: string,
      email: string,
      company_name: string
    ) => {
      const url = `/apis/user/sign_up/validate_form`;
      return axios.post(url, {
        first_name,
        last_name,
        email,
        company_name,
      });
    },
    createUser: (
      first_name: string,
      last_name: string,
      email: string,
      company_name: string,
      password: string
    ) => {
      const url = `/apis/user/sign_up/create_user`;
      return axios.post(url, {
        first_name,
        last_name,
        email,
        company_name,
        password,
      });
    },
    sendForgotPasswordLink: (email: string) => {
      const url = `/apis/forgot-password/send-link`;
      return axios.post(url, { email });
    },
    getUserByToken: (token: string) => axios.get(`/apis/forgot-password/get-user/${token}`),
    forgotPasswordReset: (token: string, password: string) => {
      const url = `/apis/forgot-password/set-password`;
      return axios.post(url, { token, password });
    },
    verifyUser: (
      user_id: string,
      verification_code: string,
      company_name: string,
      wizard_id: string
    ) => {
      const url = `/apis/user/sign_up/verify_user`;
      return axios.post(url, {
        user_id,
        verification_code,
        company_name,
        wizard_id,
      });
    },
    resendVerificationCode: (user_id: string) => {
      const url = `/apis/user/sign_up/resend_verify_code`;
      return axios.post(url, {
        user_id,
      });
    },
    isOrganizationReady: (user_id: number) => {
      const url = `/apis/user/sign_up/is_organization_ready`;
      return axios.post(url, {
        user_id,
      });
    },
    fetchStaff: () => axios.get('/apis/staff'),
    fetchTeams: () => axios.get('/apis/teams'),
    fetchCompanies: () => axios.get('/apis/companies'),
    fetchStaffTeamsCompanies: () => axios.get('/apis/staff_teams_companies'),
    fetchNationalities: () => axios.get<Nationality[]>('apis/nationality-data-grid'),
    fetchCountryRegions: (code: string) => axios.get(`/apis/countries/${code}/regions`),
    fetchRentalsUnitedSrcScript: () => axios.get('/apis/cm/wl'),
    fetchSuIFrameSrcScript: (propertyId: number) => axios.get(`/apis/cm/su_iframe/${propertyId}`),
    updateBlockDate: (id: number, body) => axios.put(`/apis/block_dates/${id}`, body),
    fetchSuggestTags: (key: string) => axios.get(`/apis/search_tag?search=${key}`),
    deleteTag: (taggingId: number) => axios.delete(`/apis/tagging/delete?tagging_id=${taggingId}`),
    createAndUpdateTags: (
      entityId: number,
      entity: 'guest' | 'corporation' | 'booking',
      tags: string[]
    ) =>
      axios.post(
        `/apis/group_tag?entity_id=${entityId}&entity=${entity}&tags=[${tags.map(
          (tag) => `"${tag}"`
        )}]`
      ),
    createGuest: (guest: {
      first_name: string | null;
      last_name: string | null;
      email: string | null;
      mobile_number: string | null;
      archive?: boolean;
    }) =>
      axios.post('/apis/guests', {
        ...guest,
      }),
    fetchReviewsInfo: () => axios.get<ReviewsInfo>('/apis/reviews/info'),
    fetchReviews: (params: {
      page: number;
      signal?: AbortSignal;
      limit?: number;
      organizationId?: number;
      propertyId?: number | number[];
      unitIds?: number | number[];
      approved?: (boolean | null)[];
      guestName?: string;
      bookingCode?: string;
      rate?: { key: 'rate[gt]' | 'rate[gte]' | 'rate[lt]' | 'rate[lte]' | 'rate'; rate: number }[];
      date?: { key: 'after' | 'before' | 'strictly_after' | 'strictly_before'; date: string }[];
      origin?: Review['origin'][];
      channel?: string[];
    }) => {
      const {
        page,
        limit = 30,
        organizationId,
        propertyId,
        unitIds,
        approved,
        guestName,
        bookingCode,
        rate,
        date,
        origin,
        channel,
        signal,
      } = params;
      const getParams = () => {
        const returnValue = {
          page,
          limit,
          organization: organizationId || '',
          property: propertyId || '',
          unit: unitIds || '',
          ...(approved !== undefined && { approved }),
          ...(guestName !== undefined && { 'guest.name': guestName }),
          ...(bookingCode !== undefined && { 'booking.booking_n_code': bookingCode }),
          ...(origin && { origin }),
          ...(channel && { channel }),
        };
        if (rate?.[0]) {
          rate.forEach((r) => {
            returnValue[r.key] = r.rate;
          });
        }
        if (date?.[0]) {
          date.forEach((d) => {
            returnValue[`review_date[${d.key}]`] = d.date;
          });
        }
        return returnValue;
      };

      return axios.get<{ data: Review[]; meta: Meta }>('/apis/v2/review?order[review_date]=desc', {
        signal,
        params: getParams(),
      });
    },
    fetchAllBookings: (params: { page: number; limit: number } & Record<any, any>) =>
      axios.get('/apis/list/bookings', {
        params,
      }),
    createAReview: (body: UpdateReviewBody) => axios.post(`/apis/reviews`, body),
    deleteAReview: (id: number) => axios.delete(`/apis/reviews/${id}`),
    updateAReview: (id: number, body: UpdateReviewBody) => axios.put(`/apis/reviews/${id}`, body),
    fetchDataGridConfig: (gridName: string) =>
      axios.post('/apis/grid_settings', {
        grid_name: gridName,
      }),
    updateDataGridConfig: (gridId: number, setting: Partial<GridConfig>) => {
      const url = `/apis/grid_settings/${gridId}`;
      return axios.put(url, {
        setting,
      });
    },
    updateDataGridColumnOrders: (gridId: number, columnOrders: string[]) => {
      const url = `/apis/grid_settings/${gridId}`;
      return axios.put(url, {
        column_orders: columnOrders,
      });
    },
    createDataGridView: (
      gridName: string,
      savedFilterName: string,
      savedFilterSetting: SavedFilterSetting,
      savedFilterOrder: number
    ) => {
      const url = '/apis/grid_settings';
      return axios.post(url, {
        grid_name: gridName,
        saved_filter_name: savedFilterName,
        saved_filter_setting: savedFilterSetting,
        order: savedFilterOrder,
      });
    },
    updateDataGridView: (
      gridId: number,
      savedFilterName: string,
      savedFilterSetting: SavedFilterSetting,
      savedFilterOrder: number
    ) => {
      const url = `/apis/grid_settings/${gridId}`;
      return axios.put(url, {
        saved_filter_name: savedFilterName,
        saved_filter_setting: savedFilterSetting,
        order: savedFilterOrder,
      });
    },
    deleteDataGridView: (gridId: number) => {
      const url = `/apis/grid_settings/delete_saved_filter/${gridId}`;
      return axios.delete(url);
    },
    pinDataGridView: (gridId: number, pinned: boolean, replaceId: number | null = null) => {
      const url = `/apis/grid_settings/set_pin_saved_filter/${gridId}`;
      return axios.put(url, { pinned, replace_id: replaceId });
    },
    renameDataGridView: (gridId: number, savedFilterName: string) => {
      const url = `/apis/grid_settings/rename_saved_filter/${gridId}`;
      return axios.put(url, { saved_filter_name: savedFilterName });
    },
    duplicateDataGridView: (gridId: number, savedFilterName: string) =>{
      const url = `/apis/grid_settings/duplicate_saved_filter/${gridId}`;
      return axios.post(url, { saved_filter_name: savedFilterName })
    },
    getGroupedResults: ({
      search,
      from,
      signal,
    }: {
      search: string;
      from: string;
      signal?: AbortSignal;
    }) =>
      axios.get(`/apis/search/get_grouped_results`, {
        signal,
        params: { search, from },
      }),
    getSearchResults: ({
      search,
      from,
      signal,
    }: {
      search: string;
      from: string;
      signal?: AbortSignal;
    }) =>
      axios.get(`/apis/search/get_search_results`, {
        signal,
        params: {
          search,
          from,
        },
      }),
      exchangePriceAction: (initial_currency: string, target_currency: string, value: number) => 
        axios.get('/apis/price_exchange', {
          params: {
            initial_currency, target_currency, value
          }
        }),
}
}
export default api();
