import { File } from './common';
import { CheckInReadyStatus } from './booking';
// @mui
import { GridColTypeDef, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid-pro';

export type BookingStatus =
  | 'Booked'
  | 'Cancelled'
  | 'Checked in'
  | 'Checked out'
  | 'No Show'
  | 'Scheduled for Cancellation';
export type FinalizationStatus =
  | 'Not Finalisable'
  | 'Awaiting Finalisation'
  | 'Marked as Finalised'
  | 'Finalised';
export type PaymentStatus =
  | 'Paid'
  | 'Over paid'
  | 'Unpaid'
  | 'Partial'
  | 'Credit'
  | 'Not applicable';
export type SecurityDepositStatus =
  | 'Not Authorised'
  | 'Pre-authorised'
  | 'Authorised'
  | 'Charged'
  | 'Partially Charged'
  | 'Released'
  | 'Charged & Released'
  | 'Partially Authorised'
  | 'Guaranteed by Channel'
  | 'Not Applicable'
  | 'Awaiting Authorisation'
  | 'Expired';
export type BookingListViewValue =
  | 'arrivals'
  | 'departures'
  | 'stay-overs'
  | 'cancellations'
  | 'all_bookings'
  | 'unallocated_bookings'
  | 'awaiting_finalisation'
  | 'unmapped_bookings';
export type ColumnField =
  | 'id'
  | 'guest'
  | 'arrival_date'
  | 'departure_date'
  | 'city'
  | 'location'
  | 'unit_type'
  | 'rate_plan'
  | 'contact_info'
  | 'sd_charged'
  | 'total_due'
  | 'total_sale'
  | 'channel'
  | 'payment_collection_type'
  | 'booking_no'
  | 'ota_commission'
  | 'booking_status'
  | 'deposit_status'
  | 'check_in_ready'
  | 'booking_tags'
  | 'creation_source'
  | 'number_of_nights'
  | 'number_of_adult_guests'
  | 'number_of_child_guests'
  | 'id_verified'
  | 'created_at'
  | 'action';

export type SortDirection = 'asc' | 'desc';

export const orderField = {
  guest: 'guest.first_name',
  arrival_date: 'arrival_date',
  departure_date: 'departure_date',
  city: 'property.area.city.name',
  location: 'property.short_name',
  unit: 'unit.name',
  unit_type: 'unit_type.name',
  rate_plan: 'staah_rate_plan.name',
  contact_info: 'guest.email',
  channel: 'channel.name',
  payment_collection_type: 'collection_type',
  booking_no: 'booking_n_code',
  booking_status: 'booking_status',
  deposit_status: 'deposit_status',
  creation_source: 'creation_source',
  number_of_nights: 'number_of_nights',
  number_of_adult_guests: 'number_of_adult_guests',
  number_of_child_guests: 'number_of_child_guests',
  created_at: 'created_at',
};

export type PaymentCollectionType = 'Channel Collect' | 'Hotel Collect';

export type Filters = {
  arrival_date?: {
    from?: string;
    to?: string;
  };
  departure_date?: {
    from?: string;
    to?: string;
  };
  booking_status?: BookingStatus[];
  finalization_status?: FinalizationStatus[];
  unallocated?: boolean;
  unmapped?: boolean;
};

export type BookingListView = {
  value: BookingListViewValue;
  defaultFilters: Filters;
  defaultSortModel: GridSortModel;
  defaultHideColumns: ColumnField[];
  defaultColumnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (newModel: GridColumnVisibilityModel) => void;
  columnOrders?: string[];
  onColumnOrderChange?: (newOrders: string[]) => void;
};

export interface BookingGridColumn extends GridColTypeDef {
  field: ColumnField | string;
}

export type Guest = {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  id_verified: boolean;
  details_confirmed: boolean;
};

export type Booking = {
  id: number;
  booking_code: string;
  booking_no: string;
  guest: Guest;
  arrival_date: string;
  arrival_time: string | null;
  departure_date: string;
  departure_time: string | null;
  booking_status: BookingStatus;
  finalization_status: FinalizationStatus;
  channel: {
    id: number;
    name: string;
    logo: string;
  };
  number_of_adult_guests: number;
  number_of_child_guests: number;
  payment_status: PaymentStatus;
  property: {
    id: number;
    name: string;
    short_name: string;
    is_active: boolean;
    area?: {
      city?: { name: string };
    };
  } | null;
  unit_type: {
    id: number;
    name: string;
  } | null;
  unit: {
    name: string;
  } | null;
  booking_warnings: {}[];
  charged_amount: {
    value: number;
    currency: string;
    base_value: number;
  } | null;
  deposit_amount: {
    value: number;
    currency: string;
    base_value: number;
  } | null;
  deposit_status: SecurityDepositStatus;
  agreement: {
    id: number;
    agreement_template: {
      id: number;
      title: string;
      is_archived: boolean;
      body: {
        id: number;
        content: string;
      };
      is_editable: boolean;
      brand: {
        id: number;
        name: string;
      };
    };
    approved: boolean;
    signature: File;
    text: string;
    guest: Guest;
  } | null;
  price_breakdown: {
    accommodation_price: number;
    channel_addons: number;
    channel_taxes: number;
    channel_promotions: number;
    zeevou_addons: number;
    zeevou_taxes: number;
    discount: number;
    total_price: number;
    total_due: number;
    channel_total: number;
    managment_fee: number;
    total_sale: number;
    total_price_currency_symbol: string;
    total_price_currency: string;
  };
  tags?: {
    name: string;
    id: number;
    tagging_id: number;
  }[];
  booking_purpose: string;
  checkInReadyStatus: CheckInReadyStatus[];
  payment_collection_type: PaymentCollectionType;
  created_date: string;
  rate_plan: {
    name: string;
    type: string;
  } | null;
  creation_source: string | null;
  has_integrations_with_Xero: boolean;
  ota_commission: {
    value: number;
    currency: string;
  } | null;
};

export type BookingGridRow = {
  id: number;
  guest: {
    id: number | null;
    firstName: string;
    lastName: string;
    adults: number;
    children: number;
  };
  arrival_date: {
    date: string;
    time: string | null;
  };
  departure_date: {
    date: string;
    time: string | null;
  };
  city: string;
  location: {
    property: {
      id: number;
      name: string;
    };
    unit: string | null;
  };
  contact_info: {
    mobile: string;
    email: string;
  };
  sd_charged: {
    price: number;
    currencySymbol: string;
  };
  total_due: {
    price: number;
    currencySymbol: string;
  };
  total_sale: {
    price: number;
    currencySymbol: string;
  };
  channel: {
    id: number;
    name: string;
    logo: string;
  };
  booking_no: string;
  booking_status: string;
  deposit_status: string;
  check_in_ready: CheckInReadyStatus[];
  payment_collection_type: PaymentCollectionType;
  created_at: string;
  number_of_nights: number;
  number_of_adult_guests: number;
  number_of_child_guests: number;
  unit_type: {
    id: number;
    name: string;
  };
  rate_plan: {
    name: string;
    type: string;
  };
  creation_source: string;
  booking_tags?: { id: number; name: string }[];
  id_verified: boolean;
  ota_commission: {
    price: number;
    currencySymbol: string;
  } | null;
};

export const ALL_BOOKINGS_COLUMN_FIELDS = (bookingListViewValue: string, user: any) => {
  const hasCheckInReadyConfigViewAccess = user?.acls.PeymansBookingBundleCheckInReadyConfig.VIEW;
  switch (bookingListViewValue) {
    case 'arrivals':
      return [
        'id',
        'guest',
        'id_verified',
        'arrival_date',
        'departure_date',
        'city',
        'location',
        'unit_type',
        'rate_plan',
        'contact_info',
        'sd_charged',
        'total_due',
        'total_sale',
        'channel',
        'payment_collection_type',
        'booking_no',
        'booking_status',
        'deposit_status',
        ...(hasCheckInReadyConfigViewAccess ? ['check_in_ready'] : []),
        'creation_source',
        'booking_tags',
        'number_of_nights',
        'number_of_adult_guests',
        'number_of_child_guests',
        'created_at',
      ];
    case 'all_bookings':
    case 'unallocated_bookings':
      return [
        'id',
        'guest',
        'arrival_date',
        'departure_date',
        'city',
        'location',
        'unit_type',
        'rate_plan',
        'contact_info',
        'sd_charged',
        'total_due',
        'total_sale',
        'channel',
        'payment_collection_type',
        'booking_no',
        'booking_status',
        'deposit_status',
        ...(hasCheckInReadyConfigViewAccess ? ['check_in_ready'] : []),
        'creation_source',
        'booking_tags',
        'number_of_nights',
        'number_of_adult_guests',
        'number_of_child_guests',
        'created_at',
      ];
    case 'pending_finalisation':
    case 'unmapped_bookings':
    case 'departures':
    case 'cancellations':
    case 'stay-overs':
    default:
      return [
        'id',
        'guest',
        'arrival_date',
        'departure_date',
        'city',
        'location',
        'unit_type',
        'rate_plan',
        'contact_info',
        'sd_charged',
        'total_due',
        'total_sale',
        'channel',
        'payment_collection_type',
        'booking_no',
        'booking_status',
        'deposit_status',
        'creation_source',
        'booking_tags',
        'number_of_nights',
        'number_of_adult_guests',
        'number_of_child_guests',
        'created_at',
      ];
  }
};
