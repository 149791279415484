import { useEffect, useState } from 'react';
// @mui
import Chip from '@mui/material/Chip';
import {
  Stack,
  InputAdornment,
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
  IconButton,
} from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
// @types
import { SearchOption } from 'src/@types/calendar';
import { QuickSearch as QuickSearchType } from 'src/@types/filter';
// components
import Iconify from '../Iconify';
import { useDebounce } from 'use-debounce';

type Interface = QuickSearchType &
  Partial<
    Omit<
      AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined, 'div'>,
      'onChange'
    >
  >;

export interface QuickSearchProps extends Interface {
  hideSearchIcon?: boolean;
  filterValue?: string;
  searchValue: string;
  TextFieldProps?: TextFieldProps;
  onChange: (filterValue: string, searchValue: string) => void;
  isDebounced?: boolean;
  onBlurSearch?: boolean;
}

export default function QuickSearch({
  hideSearchIcon,
  categories = [],
  filterValue = '',
  searchValue,
  onChange,
  placeholderLabel,
  TextFieldProps,
  isDebounced = false,
  onBlurSearch = true,
  ...other
}: QuickSearchProps) {
  const { translate } = useLocales(['common', 'booking', 'automatedMessage']);

  const options: SearchOption[] = categories.map(
    (item, index) => ({ id: index, type: item.key, label: item.title } as SearchOption)
  );

  const optionsMap = new Map<string, string>();
  categories.map((item) => optionsMap.set(item.title, item.key));

  const optionKeyMap = new Map<string, string>();
  categories.map((item) => optionKeyMap.set(item.key, item.title));

  const [filter, setFilter] = useState<string | null>(filterValue);
  const [result] = useState<SearchOption[] | null>();

  const [value, setValue] = useState<string | null>(
    filterValue ? (optionKeyMap.get(filterValue) as keyof SearchOption) : null
  );
  const [inputValue, setInputValue] = useState(searchValue);
  const [debouncedSearchQuery] = useDebounce(inputValue, 200);

  useEffect(() => {
    if (isDebounced) {
      onChange(filter || '', debouncedSearchQuery.trim());
    }
  }, [debouncedSearchQuery, isDebounced, filter]);

  const handleOnBlurChange = () => {
    if (onBlurSearch) {
      handleOnChange();
    }
  };
  const handleOnChange = () => onChange(filter || '', inputValue.trim());

  const onClear = () => {
    if (inputValue) {
      setInputValue('');
      onChange(filter ?? '', '');
    } else {
      setFilter(null);
      setValue(null);
      onChange('', '');
    }
  };

  return (
    <Autocomplete
      freeSolo
      fullWidth
      value={value ?? ''}
      inputValue={inputValue}
      onBlur={handleOnBlurChange}
      onChange={(event, newValue: SearchOption | string | null, reason) => {
        if (inputValue === '') {
          // @ts-ignore
          setValue(typeof newValue === 'string' ? newValue : newValue?.label);
          if (newValue) {
            setFilter(
              optionsMap.get(
                typeof newValue === 'string' ? newValue : newValue?.label
              ) as keyof SearchOption
            );
          } else {
            setFilter(null);
          }
        } else {
          setInputValue(typeof newValue === 'string' ? newValue : newValue?.label ?? '');
        }
        if (reason === 'clear') onClear();
      }}
      onInputChange={(event, newInputValue) => {
        if (event?.type === 'change') setInputValue(newInputValue);
      }}
      options={inputValue ? result || [] : options}
      getOptionLabel={(option) => (typeof option !== 'string' ? option.label || '' : option || '')}
      renderOption={(props, option) => (
        <li {...props} key={typeof option !== 'string' ? option.id : option}>
          {typeof option !== 'string' ? option.label : option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholderLabel && !filter ? placeholderLabel : translate('search')}
          size="small"
          variant="standard"
          onKeyDown={(e) => {
            if ((e.code === 'Enter' || e.code === 'NumpadEnter') && !isDebounced) handleOnChange();
            if ((e.key === 'Backspace' || e.key === 'Delete') && !inputValue) {
              setValue(null);
              setFilter(null);
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Stack direction={'row'} alignItems={'center'}>
                {hideSearchIcon ? null : (
                  <IconButton color="inherit" size="small" onClick={handleOnChange}>
                    <Iconify color="action.active" icon="eva:search-fill" />
                  </IconButton>
                )}
                {filter && (
                  <InputAdornment position="start">
                    <Chip label={value} />
                  </InputAdornment>
                )}
              </Stack>
            ),
          }}
          {...TextFieldProps}
        />
      )}
      {...other}
    />
  );
}
