import { TFunction } from "react-i18next";
import automatedMessageEnLocale from 'src/locales/en/automatedMessage.json';

const today = new Date().toLocaleDateString()
const range = (min, max) => Array.from({ length: max - min + 1 }, (_, i) => min + i);
const MOCK_CONDITIONS = (translate?: TFunction<any[], undefined>) => {
  const getContentByTranslateKeys = (key1: string, key2: string): string => translate ? translate(`conditions.${key1}.${key2}`) : automatedMessageEnLocale.conditions[key1][key2];
  return ({
    'Check-in Time Approved': {
      title: 'Check-in Time Approved',
      groupName: 'booking',
      example: getContentByTranslateKeys('check-in_time_approved', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('check-in_time_approved', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('check-in_time_approved', 'text_2'),
        },
      ],
    },
    'Booking Status': {
      title: 'Booking Status',
      groupName: 'booking',
      example: getContentByTranslateKeys('booking_status', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('booking_status', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'multi-select',
          options: 'booking_status',
        },
      ],
    },
    'Channel': {
      title: 'Channel',
      groupName: 'booking',
      example: getContentByTranslateKeys('channel', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('channel', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'multi-select',
          options: 'channels',
        },
      ],
    },
    'Creation Source': {
      title: 'Creation Source',
      groupName: 'booking',
      example: getContentByTranslateKeys('creation_source', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('creation_source', 'text_1'),
        },
        {
          type: 'select',
          options:'operator_1',
        },
        {
          type: 'multi-select',
          options: 'creation_sources',
        },
        
      ],
    },
    'Security Deposit': {
      title: 'Security Deposit',
      groupName: 'booking',
      example: getContentByTranslateKeys('security_deposit', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('security_deposit', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('security_deposit', 'text_2'),
        },
      ],
    },
    'Payment': {
      title: 'Payment',
      groupName: 'booking',
      example: getContentByTranslateKeys('payment', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('payment', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('payment', 'text_2'),
        },
      ],
    },
    'Has been Extended': {
      title: 'Has been Extended',
      groupName: 'booking',
      example: getContentByTranslateKeys('has_been_extended', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('has_been_extended', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('has_been_extended', 'text_2'),
        },
      ],
    },
    'Is an Extension': {
      title: 'Is an Extension',
      groupName: 'booking',
      example: getContentByTranslateKeys('is_an_extension', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('is_an_extension', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('is_an_extension', 'text_2'),
        },
      ],
    },
    'Split Booking (First Part)': {
      title: 'Split Booking (First Part)',
      groupName: 'booking',
      example: getContentByTranslateKeys('split_booking_(first_part)', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('split_booking_(first_part)', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('split_booking_(first_part)', 'text_2'),
        },
        {
          type: 'hint',
          options: getContentByTranslateKeys('split_booking_(first_part)', 'hint'),
        },
      ],
    },
    'Split Booking (Second Part)': {
      title: 'Split Booking (Second Part)',
      groupName: 'booking',
      example: getContentByTranslateKeys('split_booking_(second_part)', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('split_booking_(second_part)', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('split_booking_(second_part)', 'text_2'),
        },
        {
          type: 'hint',
          options: getContentByTranslateKeys('split_booking_(second_part)', 'hint'),
        },
      ],
    },
    'No. of Nights': {
      title: 'No. of Nights',
      groupName: 'booking',
      example: getContentByTranslateKeys('no._of_nights', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('no._of_nights', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'select',
          options: 'operator_2',
        },
        {
          type: 'select-range',
          options: range(1,999),
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('no._of_nights', 'text_2'),
        },
      ],
    },
    'Payment Collection Type': {
      title: 'Payment Collection Type',
      groupName: 'booking',
      example: getContentByTranslateKeys('payment_collection_type', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('payment_collection_type', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'select',
          options: 'payment_collection_types',
        },
      ],
    },
    'Risk Free': {
      title: 'Risk Free',
      groupName: 'booking',
      example: getContentByTranslateKeys('risk_free', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('risk_free', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('risk_free', 'text_2'),
        },
      ],
    },
    'Arrival Date': {
      title: 'Arrival Date',
      groupName: 'booking',
      example: getContentByTranslateKeys('arrival_date', 'example').replace('{}', today),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('arrival_date', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'select',
          options: 'operator_2',
        },
        {
          type: 'date'
        },
      ],
    },
    'Departure Date': {
      title: 'Departure Date',
      groupName: 'booking',
      example: getContentByTranslateKeys('departure_date', 'example').replace('{}', today),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('departure_date', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'select',
          options: 'operator_2',
        },
        {
          type: 'date'
        },
      ],
    },
    'Booking Date': {
      title: 'Booking Date',
      groupName: 'booking',
      example: getContentByTranslateKeys('booking_date', 'example').replace('{}', today),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('booking_date', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'select',
          options: 'operator_2',
        },
        {
          type: 'date'
        },
      ],
    },
    'Purpose': {
      title: 'Purpose',
      groupName: 'booking',
      example: getContentByTranslateKeys('purpose', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('purpose', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'select',
          options: 'booking_purpose',
        },
      ],
    },
    'Booking Warning': {
      title: 'Booking Warning',
      groupName: 'booking',
      example: getContentByTranslateKeys('booking_warning', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('booking_warning', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'multi-select',
          options: 'booking_warnings',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('booking_warning', 'text_2')
        }
      ],
    },
    'Confirmation Link': {
      title: 'Confirmation Link',
      groupName: 'booking',
      example: getContentByTranslateKeys('confirmation_link', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('confirmation_link', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('confirmation_link', 'text_2'),
        },
      ],
    },
    'Booking Tag': {
      title: 'Booking Tag',
      groupName: 'booking',
      example: getContentByTranslateKeys('booking_tag', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('booking_tag', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('booking_tag', 'text_2'),
        },
        {
          type: 'multi-select',
          options: 'booking_tags',
        },
      ],
    },
    'Unallocated': {
      title: 'Unallocated',
      groupName: 'booking',
      example: getContentByTranslateKeys('unallocated', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('unallocated', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('unallocated', 'text_2'),
        },
        {
          type: 'hint',
          options: getContentByTranslateKeys('unallocated', 'hint'),
        },
      ],
    },
    'No. of Adult Guests': {
      title: 'No. of Adult Guests',
      groupName: 'booking',
      example: getContentByTranslateKeys('no._of_adult_guests', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('no._of_adult_guests', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'select',
          options: 'operator_2',
        },
        {
          type: 'select-range',
          options: range(1,99),
        },
      ],
    },
    'No. of Child Guests': {
      title: 'No. of Child Guests',
      groupName: 'booking',
      example: getContentByTranslateKeys('no._of_child_guests', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('no._of_child_guests', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('no._of_child_guests', 'text_2'),
        },
      
      ],
    },
    'Security Deposite Charged': {
      title: 'Security Deposite Charged',
      groupName: 'booking',
      example: getContentByTranslateKeys('security_deposite_charged', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('security_deposite_charged', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('security_deposite_charged', 'text_2'),
        },
      
      ],
    },
    'Days of the Week': {
      title: 'Days of the Week',
      groupName: 'booking',
      example: getContentByTranslateKeys('days_of_the_week', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('days_of_the_week', 'text_1'),
        },
        {
          type: 'select',
          options: 'variables'
        },
        {
          type: 'select',
          options: 'operator_1'
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('days_of_the_week', 'text_2'),
        },
        {
          type: 'multi-button',
          options: ['monday', 'tuesday',  'wednesday',  'thursday',  'friday',  'saturday',  'sunday']
        },
      ]
    },
    'Lead Time': {
      title: 'Lead Time',
      groupName: 'booking',
      example: getContentByTranslateKeys('lead_time', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('lead_time', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'select',
          options: 'operator_2',
        },
        {
          type: 'select-range',
          options: range(1,999),
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('lead_time', 'text_2'),
        },
      ],
    },
    'Rental Agreement': {
      title: 'Rental Agreement',
      groupName: 'booking',
      example: getContentByTranslateKeys('rental_agreement', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('rental_agreement', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('rental_agreement', 'text_2'),
        },
      ],
    },
    'Area': {
      title: 'Area',
      groupName: 'property',
      example: getContentByTranslateKeys('area', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('area', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('area', 'text_2'),
        },
        {
          type: 'multi-select',
          options: 'property_areas',
        },
      ],
    },
    'City': {
      title: 'City',
      groupName: 'property',
      example: getContentByTranslateKeys('city', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('city', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('city', 'text_2')
        },
        {
          type: 'multi-select',
          options: 'property_city',
        },
      ],
    },
    'Property Country': {
      title: 'Property Country',
      groupName: 'property',
      example: getContentByTranslateKeys('property_country', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('property_country', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('property_country', 'text_2'),
        },
        {
          type: 'multi-select',
          options: 'country',
        },
      ],
    },
    'Property': {
      title: 'Property',
      groupName: 'property',
      example: getContentByTranslateKeys('property', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('property', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('property', 'text_2')
        },
        {
          type: 'multi-select',
          options: 'property_property',
        },
      ],
    },
    'Unit Type': {
      title: 'Unit Type',
      groupName: 'property',
      example: getContentByTranslateKeys('unit_type', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('unit_type', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('unit_type', 'text_2')
        },
        {
          type: 'multi-select',
          options: 'property_unit_type',
        },
      ],
    },
    'Unit': {
      title: 'Unit',
      groupName: 'property',
      example: getContentByTranslateKeys('unit', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('unit', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('unit', 'text_2')
        },
        {
          type: 'multi-select',
          options: 'property_unit',
        },
      ],
    },
    'Check-in Type': {
      title: 'Check-in Type',
      groupName: 'property',
      example: getContentByTranslateKeys('check-in_type', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('check-in_type', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('check-in_type', 'text_2')
        },
        {
          type: 'multi-select',
          options: 'property_checkin_type',
        },
      ],
    },
    'ID Verified': {
      title: 'ID Verified',
      groupName: 'guest',
      example: getContentByTranslateKeys('id_verified', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('id_verified', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('id_verified', 'text_2'),
        }
      ]
    },
    'Signable': {
      title: 'Signable',
      groupName: 'guest',
      example: getContentByTranslateKeys('signable', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('signable', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('signable', 'text_2'),
        },
      ],
    },
    'Guest Level': {
      title: 'Guest Level',
      groupName: 'guest',
      example: getContentByTranslateKeys('guest_level', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('guest_level', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'button',
          options: ['Gold', 'Silver', 'Bronze']
        }
      ],
    },
    'Guest Tag': {
      title: 'Guest Tag',
      groupName: 'guest',
      example: getContentByTranslateKeys('guest_tag', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('guest_tag', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('guest_tag', 'text_2'),
        },
        {
          type: 'multi-select',
          options: 'guest_tags'
        }
      ],
    },
    'Opted-in': {
      title: 'Opted-in',
      groupName: 'guest',
      example: getContentByTranslateKeys('opted-in', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('opted-in', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('opted-in', 'text_2'),
        },
        {
          type: 'multi-select',
          options: 'agreement_methods'
        }
      ],
    },
    'Date of Birth': {
      title: 'Date of Birth',
      groupName: 'guest',
      example: getContentByTranslateKeys('date_of_birth', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('date_of_birth', 'text_1'),
        },
      ],
    },
    'Guest Country': {
      title: 'Guest Country',
      groupName: 'guest',
      example: getContentByTranslateKeys('guest_country', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('guest_country', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'multi-select',
          options: 'country'
        }
      ],
    },
    'Corporation Level': {
      title: 'Corporation Level',
      groupName: 'guest',
      example: getContentByTranslateKeys('corporation_level', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('corporation_level', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'button',
          options: ['Gold', 'Silver', 'Bronze']
        }
      ],
    },
    'Corporation Tag': {
      title: 'Corporation Tag',
      groupName: 'guest',
      example: getContentByTranslateKeys('corporation_tag', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('corporation_tag', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('corporation_tag', 'text_2')
        },
        {
          type: 'multi-select',
          options: 'corporation_tags'
        }
      ],
    },
    'Corporation Country': {
      title: 'Corporation Country',
      groupName: 'guest',
      example: getContentByTranslateKeys('corporation_country', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('corporation_country', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'multi-select',
          options: 'country'
        }
      ],
    },
    'Check-in Ready': {
      title: 'Check-in Ready',
      groupName: 'booking',
      example: getContentByTranslateKeys('check-in_ready', 'example'),
      content: [
        {
          type: 'text',
          options: getContentByTranslateKeys('check-in_ready', 'text_1'),
        },
        {
          type: 'select',
          options: 'operator_1',
        },
        {
          type: 'text',
          options: getContentByTranslateKeys('check-in_ready', 'text_2'),
        },
      ],
    },
})};

export default MOCK_CONDITIONS;