import React, { useEffect } from 'react';
// @mui
import { Box, FormHelperText } from '@mui/material';
import { renderTimeViewClock } from '@mui/x-date-pickers-pro';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fDate } from 'src/utils/formatTime';
// apis
import PropertyApi from 'src/api/property';
// sections
import CustomComponentTemplate from 'src/sections/wizard/listing/components/CustomComponentTemplate';
import BulletedMenuItem from '../../../../components/BulletedMenuItem';

const BookingCheckoutFrom = React.forwardRef(
  (props: { bookingId: number; defaultTime: Date | null; onError; onFinish }, ref) => {
    const { timeFormat } = useLocales();
    const { translate } = useLocales(['calendar', 'common']);
    const [checkoutTime, setCheckoutTime] = React.useState<Date | null>(null);
    const [checkoutTimeError, setCheckoutTimeError] = React.useState<boolean>(false);

    useEffect(() => {
      setCheckoutTime(props.defaultTime);
    }, [props.defaultTime]);

    React.useImperativeHandle(ref, () => ({
      submit() {
        if (checkoutTimeError) {
          props.onError(null);
          return;
        }

        PropertyApi.changeBookingStatus({
          booking_id: props.bookingId,
          booking_status: 'Checked out',
          checkout_time: checkoutTime ? fDate(checkoutTime, 'HH:mm') : null,
        })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              props.onFinish(translate('checkedout.notification'));
            }
          })
          .catch((err) => {
            props.onError(err.data.errors[0].message);
          });
      },
    }));

    return (
      <Box>
        <FormHelperText>{translate('checkedout.title')}</FormHelperText>
        <CustomComponentTemplate
          label={translate('checkedout.checkedout_time')}
          sx={{ mt: 3 }}
          optional
        >
          <TimePicker
            value={checkoutTime}
            onChange={(newValue) => setCheckoutTime(newValue)}
            slots={{
              digitalClockSectionItem: (props) => <BulletedMenuItem {...props} />,
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                autoComplete: 'off',
                inputProps: {
                  placeholder: 'hh:mm',
                },
                error: checkoutTimeError,
              },
              popper: {
                placement: 'bottom-start',
              },
              layout: {
                sx: {
                  '& .MuiMultiSectionDigitalClockSection-root': {
                    width: 155,
                    flexGrow: 1,
                    scrollbarGutter: 'stable',
                  },
                  '& .MuiMultiSectionDigitalClockSection-root:after': {
                    display: 'none',
                  },
                },
              },
            }}
            localeText={{ okButtonLabel: translate('select') }}
            ampm={false}
            ampmInClock={false}
            onError={(reason) => setCheckoutTimeError(Boolean(reason))}
          />
          <FormHelperText sx={{ ml: 0.5 }}>
            {translate('checkedout.time_helper_text')}
          </FormHelperText>
        </CustomComponentTemplate>
      </Box>
    );
  }
);

export default BookingCheckoutFrom;
