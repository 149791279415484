import { useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import {
  Button,
  ButtonProps,
  Popover,
  Stack,
  styled,
  backdropClasses,
  alpha,
  StackProps,
  paperClasses,
  SxProps,
} from '@mui/material';
// components
import Iconify from '../Iconify';

const StyledButton = styled((props: ButtonProps) => (
  <Button {...props} variant="contained" color="primary" />
))(({ theme }) => ({
  minWidth: '40px',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  boxShadow: 'none',
}));

export default function CustomEditCell({
  id,
  field,
  onSave,
  children,
  stackProps,
  popoverSx,
}: {
  id: number;
  field: string;
  onSave: (onEnd: VoidFunction) => void;
  children?: JSX.Element;
  stackProps?: StackProps;
  popoverSx?: SxProps;
}) {
  const theme = useTheme();
  const apiRef = useGridApiContext();

  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    apiRef.current.stopCellEditMode({
      id,
      field,
      ignoreModifications: true,
    });
  };

  return (
    <Popover
      id={String(id)}
      open
      anchorEl={apiRef.current.getCellElement(id, field)}
      onClose={() => {
        if (!isLoading) onClose();
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      disableScrollLock
      elevation={100}
      sx={{
        zIndex: 1200,
        [` .${backdropClasses.root}`]: {
          background: `${alpha(theme.palette.common.black, 0.16)} !important`,
          zIndex: 1200,
        },
        [` .${paperClasses.root}`]: {
          zIndex: 1201,
        },
        ...popoverSx,
      }}
    >
      <Stack direction="row" gap={0.5} p={2} {...stackProps}>
        {children || null}
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            onSave(() => {
              setIsLoading(false);
            });
          }}
          sx={(theme) => ({
            minWidth: '40px',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            boxShadow: 'none',
            background: theme.palette.primary.main,
            ml: 0.5,
            color: theme.palette.common.white,
          })}
        >
          <Iconify icon={'mdi:check'} width={16} height={16} />
        </LoadingButton>
        <StyledButton
          disabled={isLoading}
          onClick={() => onClose()}
          sx={(theme) => ({
            background: theme.palette.grey[300],
            color: theme.palette.text.primary,
            '&:hover': { background: theme.palette.grey[400] },
          })}
        >
          <Iconify icon={'ic:round-close'} width={16} height={16} />
        </StyledButton>
      </Stack>
    </Popover>
  );
}
