import { FC } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
// @mui
import { TextField, InputAdornment, TextFieldProps, Collapse } from '@mui/material';

interface Props extends NumberFormatProps<TextFieldProps> {
  symbol?: string;
}

export const convertStringPriceToNumber = (value: string | null) =>
  value ? Number(value.replace(/,/g, '')) : null;

const PriceInput: FC<Props> = ({ symbol = '', ...other }) => (
  <NumberFormat
    decimalScale={2}
    allowNegative={false}
    thousandSeparator={','}
    customInput={TextField}
    sx={{ width: '100%' }}
    autoComplete="off"
    InputProps={{
      startAdornment: symbol && symbol !== '%' && (
        <InputAdornment position="start">{symbol}</InputAdornment>
      ),
      endAdornment: symbol === '%' && (
        <InputAdornment position="end">{'%'}</InputAdornment>
      ),
    }}
    {...(symbol === '%' && {
      suffix: '',
      isAllowed: (values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || Boolean(floatValue && floatValue >= 0 && floatValue <= 100);
      },
    })}
    {...other}
  />
);

export default PriceInput;
